/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

import Long from 'long';

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

// long dep loosing in protobuf fix (webpack somehow doesnt bundle it normally)
$util.Long = Long;
$protobuf.configure();

export const object_msg = $root.object_msg = (() => {

    const object_msg = {};

    object_msg.OrganizationOwner = (function() {

        function OrganizationOwner(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrganizationOwner.prototype.organization_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrganizationOwner.prototype.role = "";
        OrganizationOwner.prototype.user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        OrganizationOwner.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                writer.uint32(8).uint64(message.user_id);
            if (message.organization_id != null && Object.hasOwnProperty.call(message, "organization_id"))
                writer.uint32(40).uint64(message.organization_id);
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(50).string(message.role);
            return writer;
        };

        OrganizationOwner.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationOwner();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 5: {
                        message.organization_id = reader.uint64();
                        break;
                    }
                case 6: {
                        message.role = reader.string();
                        break;
                    }
                case 1: {
                        message.user_id = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrganizationOwner.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrganizationOwner)
                return object;
            let message = new $root.object_msg.OrganizationOwner();
            if (object.organization_id != null)
                if ($util.Long)
                    (message.organization_id = $util.Long.fromValue(object.organization_id)).unsigned = true;
                else if (typeof object.organization_id === "string")
                    message.organization_id = parseInt(object.organization_id, 10);
                else if (typeof object.organization_id === "number")
                    message.organization_id = object.organization_id;
                else if (typeof object.organization_id === "object")
                    message.organization_id = new $util.LongBits(object.organization_id.low >>> 0, object.organization_id.high >>> 0).toNumber(true);
            if (object.role != null)
                message.role = String(object.role);
            if (object.user_id != null)
                if ($util.Long)
                    (message.user_id = $util.Long.fromValue(object.user_id)).unsigned = true;
                else if (typeof object.user_id === "string")
                    message.user_id = parseInt(object.user_id, 10);
                else if (typeof object.user_id === "number")
                    message.user_id = object.user_id;
                else if (typeof object.user_id === "object")
                    message.user_id = new $util.LongBits(object.user_id.low >>> 0, object.user_id.high >>> 0).toNumber(true);
            return message;
        };

        OrganizationOwner.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.user_id = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.organization_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.organization_id = options.longs === String ? "0" : 0;
                object.role = "";
            }
            if (message.user_id != null && message.hasOwnProperty("user_id"))
                if (typeof message.user_id === "number")
                    object.user_id = options.longs === String ? String(message.user_id) : message.user_id;
                else
                    object.user_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_id) : options.longs === Number ? new $util.LongBits(message.user_id.low >>> 0, message.user_id.high >>> 0).toNumber(true) : message.user_id;
            if (message.organization_id != null && message.hasOwnProperty("organization_id"))
                if (typeof message.organization_id === "number")
                    object.organization_id = options.longs === String ? String(message.organization_id) : message.organization_id;
                else
                    object.organization_id = options.longs === String ? $util.Long.prototype.toString.call(message.organization_id) : options.longs === Number ? new $util.LongBits(message.organization_id.low >>> 0, message.organization_id.high >>> 0).toNumber(true) : message.organization_id;
            if (message.role != null && message.hasOwnProperty("role"))
                object.role = message.role;
            return object;
        };

        OrganizationOwner.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrganizationOwner.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrganizationOwner";
        };

        return OrganizationOwner;
    })();

    object_msg.OrganizationOwnerArray = (function() {

        function OrganizationOwnerArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrganizationOwnerArray.prototype.array = $util.emptyArray;

        OrganizationOwnerArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.OrganizationOwner.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrganizationOwnerArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationOwnerArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.OrganizationOwner.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrganizationOwnerArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrganizationOwnerArray)
                return object;
            let message = new $root.object_msg.OrganizationOwnerArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrganizationOwnerArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrganizationOwnerArray.array: object expected");
                    message.array[i] = $root.object_msg.OrganizationOwner.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrganizationOwnerArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.OrganizationOwner.toObject(message.array[j], options);
            }
            return object;
        };

        OrganizationOwnerArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrganizationOwnerArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrganizationOwnerArray";
        };

        return OrganizationOwnerArray;
    })();

    object_msg.OrganizationRu = (function() {

        function OrganizationRu(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrganizationRu.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrganizationRu.prototype.name = "";
        OrganizationRu.prototype.inn = "";
        OrganizationRu.prototype.kpp = "";
        OrganizationRu.prototype.ogrn = "";
        OrganizationRu.prototype.legal_address = "";
        OrganizationRu.prototype.with_vat = false;
        OrganizationRu.prototype.vat_checked = false;
        OrganizationRu.prototype.representative_name = "";
        OrganizationRu.prototype.representative_position = "";
        OrganizationRu.prototype.bank_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrganizationRu.prototype.payment_account = "";
        OrganizationRu.prototype.organization_type = "";
        OrganizationRu.prototype.mailing_address = "";
        OrganizationRu.prototype.mailing_address_quality = 0;
        OrganizationRu.prototype.archive = false;
        OrganizationRu.prototype.legal_address_quality = 0;
        OrganizationRu.prototype.phone = "";
        OrganizationRu.prototype.verified = false;
        OrganizationRu.prototype.creator_user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrganizationRu.prototype.creation_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        OrganizationRu.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(8).uint64(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(18).string(message.name);
            if (message.inn != null && Object.hasOwnProperty.call(message, "inn"))
                writer.uint32(26).string(message.inn);
            if (message.kpp != null && Object.hasOwnProperty.call(message, "kpp"))
                writer.uint32(34).string(message.kpp);
            if (message.ogrn != null && Object.hasOwnProperty.call(message, "ogrn"))
                writer.uint32(42).string(message.ogrn);
            if (message.legal_address != null && Object.hasOwnProperty.call(message, "legal_address"))
                writer.uint32(50).string(message.legal_address);
            if (message.with_vat != null && Object.hasOwnProperty.call(message, "with_vat"))
                writer.uint32(56).bool(message.with_vat);
            if (message.vat_checked != null && Object.hasOwnProperty.call(message, "vat_checked"))
                writer.uint32(64).bool(message.vat_checked);
            if (message.representative_name != null && Object.hasOwnProperty.call(message, "representative_name"))
                writer.uint32(74).string(message.representative_name);
            if (message.representative_position != null && Object.hasOwnProperty.call(message, "representative_position"))
                writer.uint32(82).string(message.representative_position);
            if (message.bank_id != null && Object.hasOwnProperty.call(message, "bank_id"))
                writer.uint32(88).uint64(message.bank_id);
            if (message.payment_account != null && Object.hasOwnProperty.call(message, "payment_account"))
                writer.uint32(98).string(message.payment_account);
            if (message.organization_type != null && Object.hasOwnProperty.call(message, "organization_type"))
                writer.uint32(106).string(message.organization_type);
            if (message.mailing_address != null && Object.hasOwnProperty.call(message, "mailing_address"))
                writer.uint32(114).string(message.mailing_address);
            if (message.mailing_address_quality != null && Object.hasOwnProperty.call(message, "mailing_address_quality"))
                writer.uint32(120).int32(message.mailing_address_quality);
            if (message.archive != null && Object.hasOwnProperty.call(message, "archive"))
                writer.uint32(128).bool(message.archive);
            if (message.legal_address_quality != null && Object.hasOwnProperty.call(message, "legal_address_quality"))
                writer.uint32(136).int32(message.legal_address_quality);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(146).string(message.phone);
            if (message.verified != null && Object.hasOwnProperty.call(message, "verified"))
                writer.uint32(152).bool(message.verified);
            if (message.creator_user_id != null && Object.hasOwnProperty.call(message, "creator_user_id"))
                writer.uint32(160).uint64(message.creator_user_id);
            if (message.creation_time != null && Object.hasOwnProperty.call(message, "creation_time"))
                writer.uint32(168).int64(message.creation_time);
            return writer;
        };

        OrganizationRu.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationRu();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.inn = reader.string();
                        break;
                    }
                case 4: {
                        message.kpp = reader.string();
                        break;
                    }
                case 5: {
                        message.ogrn = reader.string();
                        break;
                    }
                case 6: {
                        message.legal_address = reader.string();
                        break;
                    }
                case 7: {
                        message.with_vat = reader.bool();
                        break;
                    }
                case 8: {
                        message.vat_checked = reader.bool();
                        break;
                    }
                case 9: {
                        message.representative_name = reader.string();
                        break;
                    }
                case 10: {
                        message.representative_position = reader.string();
                        break;
                    }
                case 11: {
                        message.bank_id = reader.uint64();
                        break;
                    }
                case 12: {
                        message.payment_account = reader.string();
                        break;
                    }
                case 13: {
                        message.organization_type = reader.string();
                        break;
                    }
                case 14: {
                        message.mailing_address = reader.string();
                        break;
                    }
                case 15: {
                        message.mailing_address_quality = reader.int32();
                        break;
                    }
                case 16: {
                        message.archive = reader.bool();
                        break;
                    }
                case 17: {
                        message.legal_address_quality = reader.int32();
                        break;
                    }
                case 18: {
                        message.phone = reader.string();
                        break;
                    }
                case 19: {
                        message.verified = reader.bool();
                        break;
                    }
                case 20: {
                        message.creator_user_id = reader.uint64();
                        break;
                    }
                case 21: {
                        message.creation_time = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrganizationRu.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrganizationRu)
                return object;
            let message = new $root.object_msg.OrganizationRu();
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.name != null)
                message.name = String(object.name);
            if (object.inn != null)
                message.inn = String(object.inn);
            if (object.kpp != null)
                message.kpp = String(object.kpp);
            if (object.ogrn != null)
                message.ogrn = String(object.ogrn);
            if (object.legal_address != null)
                message.legal_address = String(object.legal_address);
            if (object.with_vat != null)
                message.with_vat = Boolean(object.with_vat);
            if (object.vat_checked != null)
                message.vat_checked = Boolean(object.vat_checked);
            if (object.representative_name != null)
                message.representative_name = String(object.representative_name);
            if (object.representative_position != null)
                message.representative_position = String(object.representative_position);
            if (object.bank_id != null)
                if ($util.Long)
                    (message.bank_id = $util.Long.fromValue(object.bank_id)).unsigned = true;
                else if (typeof object.bank_id === "string")
                    message.bank_id = parseInt(object.bank_id, 10);
                else if (typeof object.bank_id === "number")
                    message.bank_id = object.bank_id;
                else if (typeof object.bank_id === "object")
                    message.bank_id = new $util.LongBits(object.bank_id.low >>> 0, object.bank_id.high >>> 0).toNumber(true);
            if (object.payment_account != null)
                message.payment_account = String(object.payment_account);
            if (object.organization_type != null)
                message.organization_type = String(object.organization_type);
            if (object.mailing_address != null)
                message.mailing_address = String(object.mailing_address);
            if (object.mailing_address_quality != null)
                message.mailing_address_quality = object.mailing_address_quality | 0;
            if (object.archive != null)
                message.archive = Boolean(object.archive);
            if (object.legal_address_quality != null)
                message.legal_address_quality = object.legal_address_quality | 0;
            if (object.phone != null)
                message.phone = String(object.phone);
            if (object.verified != null)
                message.verified = Boolean(object.verified);
            if (object.creator_user_id != null)
                if ($util.Long)
                    (message.creator_user_id = $util.Long.fromValue(object.creator_user_id)).unsigned = true;
                else if (typeof object.creator_user_id === "string")
                    message.creator_user_id = parseInt(object.creator_user_id, 10);
                else if (typeof object.creator_user_id === "number")
                    message.creator_user_id = object.creator_user_id;
                else if (typeof object.creator_user_id === "object")
                    message.creator_user_id = new $util.LongBits(object.creator_user_id.low >>> 0, object.creator_user_id.high >>> 0).toNumber(true);
            if (object.creation_time != null)
                if ($util.Long)
                    (message.creation_time = $util.Long.fromValue(object.creation_time)).unsigned = false;
                else if (typeof object.creation_time === "string")
                    message.creation_time = parseInt(object.creation_time, 10);
                else if (typeof object.creation_time === "number")
                    message.creation_time = object.creation_time;
                else if (typeof object.creation_time === "object")
                    message.creation_time = new $util.LongBits(object.creation_time.low >>> 0, object.creation_time.high >>> 0).toNumber();
            return message;
        };

        OrganizationRu.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.name = "";
                object.inn = "";
                object.kpp = "";
                object.ogrn = "";
                object.legal_address = "";
                object.with_vat = false;
                object.vat_checked = false;
                object.representative_name = "";
                object.representative_position = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.bank_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.bank_id = options.longs === String ? "0" : 0;
                object.payment_account = "";
                object.organization_type = "";
                object.mailing_address = "";
                object.mailing_address_quality = 0;
                object.archive = false;
                object.legal_address_quality = 0;
                object.phone = "";
                object.verified = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.creator_user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.creator_user_id = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.creation_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.creation_time = options.longs === String ? "0" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.inn != null && message.hasOwnProperty("inn"))
                object.inn = message.inn;
            if (message.kpp != null && message.hasOwnProperty("kpp"))
                object.kpp = message.kpp;
            if (message.ogrn != null && message.hasOwnProperty("ogrn"))
                object.ogrn = message.ogrn;
            if (message.legal_address != null && message.hasOwnProperty("legal_address"))
                object.legal_address = message.legal_address;
            if (message.with_vat != null && message.hasOwnProperty("with_vat"))
                object.with_vat = message.with_vat;
            if (message.vat_checked != null && message.hasOwnProperty("vat_checked"))
                object.vat_checked = message.vat_checked;
            if (message.representative_name != null && message.hasOwnProperty("representative_name"))
                object.representative_name = message.representative_name;
            if (message.representative_position != null && message.hasOwnProperty("representative_position"))
                object.representative_position = message.representative_position;
            if (message.bank_id != null && message.hasOwnProperty("bank_id"))
                if (typeof message.bank_id === "number")
                    object.bank_id = options.longs === String ? String(message.bank_id) : message.bank_id;
                else
                    object.bank_id = options.longs === String ? $util.Long.prototype.toString.call(message.bank_id) : options.longs === Number ? new $util.LongBits(message.bank_id.low >>> 0, message.bank_id.high >>> 0).toNumber(true) : message.bank_id;
            if (message.payment_account != null && message.hasOwnProperty("payment_account"))
                object.payment_account = message.payment_account;
            if (message.organization_type != null && message.hasOwnProperty("organization_type"))
                object.organization_type = message.organization_type;
            if (message.mailing_address != null && message.hasOwnProperty("mailing_address"))
                object.mailing_address = message.mailing_address;
            if (message.mailing_address_quality != null && message.hasOwnProperty("mailing_address_quality"))
                object.mailing_address_quality = message.mailing_address_quality;
            if (message.archive != null && message.hasOwnProperty("archive"))
                object.archive = message.archive;
            if (message.legal_address_quality != null && message.hasOwnProperty("legal_address_quality"))
                object.legal_address_quality = message.legal_address_quality;
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            if (message.verified != null && message.hasOwnProperty("verified"))
                object.verified = message.verified;
            if (message.creator_user_id != null && message.hasOwnProperty("creator_user_id"))
                if (typeof message.creator_user_id === "number")
                    object.creator_user_id = options.longs === String ? String(message.creator_user_id) : message.creator_user_id;
                else
                    object.creator_user_id = options.longs === String ? $util.Long.prototype.toString.call(message.creator_user_id) : options.longs === Number ? new $util.LongBits(message.creator_user_id.low >>> 0, message.creator_user_id.high >>> 0).toNumber(true) : message.creator_user_id;
            if (message.creation_time != null && message.hasOwnProperty("creation_time"))
                if (typeof message.creation_time === "number")
                    object.creation_time = options.longs === String ? String(message.creation_time) : message.creation_time;
                else
                    object.creation_time = options.longs === String ? $util.Long.prototype.toString.call(message.creation_time) : options.longs === Number ? new $util.LongBits(message.creation_time.low >>> 0, message.creation_time.high >>> 0).toNumber() : message.creation_time;
            return object;
        };

        OrganizationRu.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrganizationRu.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrganizationRu";
        };

        return OrganizationRu;
    })();

    object_msg.OrganizationRuArray = (function() {

        function OrganizationRuArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrganizationRuArray.prototype.array = $util.emptyArray;

        OrganizationRuArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.OrganizationRu.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrganizationRuArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationRuArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.OrganizationRu.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrganizationRuArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrganizationRuArray)
                return object;
            let message = new $root.object_msg.OrganizationRuArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrganizationRuArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrganizationRuArray.array: object expected");
                    message.array[i] = $root.object_msg.OrganizationRu.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrganizationRuArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.OrganizationRu.toObject(message.array[j], options);
            }
            return object;
        };

        OrganizationRuArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrganizationRuArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrganizationRuArray";
        };

        return OrganizationRuArray;
    })();

    object_msg.OrganizationRuInfo = (function() {

        function OrganizationRuInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrganizationRuInfo.prototype.inn = "";
        OrganizationRuInfo.prototype.kpp = "";
        OrganizationRuInfo.prototype.legal_address = "";
        OrganizationRuInfo.prototype.archive = false;
        OrganizationRuInfo.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrganizationRuInfo.prototype.name = "";

        OrganizationRuInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(8).uint64(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(18).string(message.name);
            if (message.inn != null && Object.hasOwnProperty.call(message, "inn"))
                writer.uint32(26).string(message.inn);
            if (message.kpp != null && Object.hasOwnProperty.call(message, "kpp"))
                writer.uint32(34).string(message.kpp);
            if (message.legal_address != null && Object.hasOwnProperty.call(message, "legal_address"))
                writer.uint32(50).string(message.legal_address);
            if (message.archive != null && Object.hasOwnProperty.call(message, "archive"))
                writer.uint32(128).bool(message.archive);
            return writer;
        };

        OrganizationRuInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationRuInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.inn = reader.string();
                        break;
                    }
                case 4: {
                        message.kpp = reader.string();
                        break;
                    }
                case 6: {
                        message.legal_address = reader.string();
                        break;
                    }
                case 16: {
                        message.archive = reader.bool();
                        break;
                    }
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrganizationRuInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrganizationRuInfo)
                return object;
            let message = new $root.object_msg.OrganizationRuInfo();
            if (object.inn != null)
                message.inn = String(object.inn);
            if (object.kpp != null)
                message.kpp = String(object.kpp);
            if (object.legal_address != null)
                message.legal_address = String(object.legal_address);
            if (object.archive != null)
                message.archive = Boolean(object.archive);
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        OrganizationRuInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.name = "";
                object.inn = "";
                object.kpp = "";
                object.legal_address = "";
                object.archive = false;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.inn != null && message.hasOwnProperty("inn"))
                object.inn = message.inn;
            if (message.kpp != null && message.hasOwnProperty("kpp"))
                object.kpp = message.kpp;
            if (message.legal_address != null && message.hasOwnProperty("legal_address"))
                object.legal_address = message.legal_address;
            if (message.archive != null && message.hasOwnProperty("archive"))
                object.archive = message.archive;
            return object;
        };

        OrganizationRuInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrganizationRuInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrganizationRuInfo";
        };

        return OrganizationRuInfo;
    })();

    object_msg.OrganizationRuInfoArray = (function() {

        function OrganizationRuInfoArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrganizationRuInfoArray.prototype.array = $util.emptyArray;

        OrganizationRuInfoArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.OrganizationRuInfo.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrganizationRuInfoArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationRuInfoArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.OrganizationRuInfo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrganizationRuInfoArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrganizationRuInfoArray)
                return object;
            let message = new $root.object_msg.OrganizationRuInfoArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrganizationRuInfoArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrganizationRuInfoArray.array: object expected");
                    message.array[i] = $root.object_msg.OrganizationRuInfo.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrganizationRuInfoArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.OrganizationRuInfo.toObject(message.array[j], options);
            }
            return object;
        };

        OrganizationRuInfoArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrganizationRuInfoArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrganizationRuInfoArray";
        };

        return OrganizationRuInfoArray;
    })();

    object_msg.Place = (function() {

        function Place(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        Place.prototype.creator_user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        Place.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        Place.prototype.name = "";
        Place.prototype.town = "";
        Place.prototype.address = "";
        Place.prototype.latitude = 0;
        Place.prototype.longitude = 0;
        Place.prototype.archive = false;
        Place.prototype.creation_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Place.prototype.rating = 0;
        Place.prototype.description = "";
        Place.prototype.address_quality = 0;
        Place.prototype.organization_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        Place.prototype.url = "";
        Place.prototype.verified = false;
        Place.prototype.map_url = "";
        Place.prototype.vk_url = "";
        Place.prototype.ok_url = "";
        Place.prototype.youtube_url = "";
        Place.prototype.yandex_rating = 0;
        Place.prototype.yandex_rating_count = 0;
        Place.prototype.telegram_id = "";

        Place.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(8).uint64(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(18).string(message.name);
            if (message.town != null && Object.hasOwnProperty.call(message, "town"))
                writer.uint32(26).string(message.town);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                writer.uint32(34).string(message.address);
            if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
                writer.uint32(45).float(message.latitude);
            if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                writer.uint32(53).float(message.longitude);
            if (message.archive != null && Object.hasOwnProperty.call(message, "archive"))
                writer.uint32(56).bool(message.archive);
            if (message.creation_time != null && Object.hasOwnProperty.call(message, "creation_time"))
                writer.uint32(64).int64(message.creation_time);
            if (message.rating != null && Object.hasOwnProperty.call(message, "rating"))
                writer.uint32(72).int32(message.rating);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(82).string(message.description);
            if (message.address_quality != null && Object.hasOwnProperty.call(message, "address_quality"))
                writer.uint32(88).int32(message.address_quality);
            if (message.organization_id != null && Object.hasOwnProperty.call(message, "organization_id"))
                writer.uint32(96).uint64(message.organization_id);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(106).string(message.url);
            if (message.verified != null && Object.hasOwnProperty.call(message, "verified"))
                writer.uint32(112).bool(message.verified);
            if (message.map_url != null && Object.hasOwnProperty.call(message, "map_url"))
                writer.uint32(122).string(message.map_url);
            if (message.vk_url != null && Object.hasOwnProperty.call(message, "vk_url"))
                writer.uint32(130).string(message.vk_url);
            if (message.ok_url != null && Object.hasOwnProperty.call(message, "ok_url"))
                writer.uint32(138).string(message.ok_url);
            if (message.youtube_url != null && Object.hasOwnProperty.call(message, "youtube_url"))
                writer.uint32(146).string(message.youtube_url);
            if (message.yandex_rating != null && Object.hasOwnProperty.call(message, "yandex_rating"))
                writer.uint32(157).float(message.yandex_rating);
            if (message.yandex_rating_count != null && Object.hasOwnProperty.call(message, "yandex_rating_count"))
                writer.uint32(160).int32(message.yandex_rating_count);
            if (message.telegram_id != null && Object.hasOwnProperty.call(message, "telegram_id"))
                writer.uint32(170).string(message.telegram_id);
            if (message.creator_user_id != null && Object.hasOwnProperty.call(message, "creator_user_id"))
                writer.uint32(176).uint64(message.creator_user_id);
            return writer;
        };

        Place.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.Place();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 22: {
                        message.creator_user_id = reader.uint64();
                        break;
                    }
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.town = reader.string();
                        break;
                    }
                case 4: {
                        message.address = reader.string();
                        break;
                    }
                case 5: {
                        message.latitude = reader.float();
                        break;
                    }
                case 6: {
                        message.longitude = reader.float();
                        break;
                    }
                case 7: {
                        message.archive = reader.bool();
                        break;
                    }
                case 8: {
                        message.creation_time = reader.int64();
                        break;
                    }
                case 9: {
                        message.rating = reader.int32();
                        break;
                    }
                case 10: {
                        message.description = reader.string();
                        break;
                    }
                case 11: {
                        message.address_quality = reader.int32();
                        break;
                    }
                case 12: {
                        message.organization_id = reader.uint64();
                        break;
                    }
                case 13: {
                        message.url = reader.string();
                        break;
                    }
                case 14: {
                        message.verified = reader.bool();
                        break;
                    }
                case 15: {
                        message.map_url = reader.string();
                        break;
                    }
                case 16: {
                        message.vk_url = reader.string();
                        break;
                    }
                case 17: {
                        message.ok_url = reader.string();
                        break;
                    }
                case 18: {
                        message.youtube_url = reader.string();
                        break;
                    }
                case 19: {
                        message.yandex_rating = reader.float();
                        break;
                    }
                case 20: {
                        message.yandex_rating_count = reader.int32();
                        break;
                    }
                case 21: {
                        message.telegram_id = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Place.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.Place)
                return object;
            let message = new $root.object_msg.Place();
            if (object.creator_user_id != null)
                if ($util.Long)
                    (message.creator_user_id = $util.Long.fromValue(object.creator_user_id)).unsigned = true;
                else if (typeof object.creator_user_id === "string")
                    message.creator_user_id = parseInt(object.creator_user_id, 10);
                else if (typeof object.creator_user_id === "number")
                    message.creator_user_id = object.creator_user_id;
                else if (typeof object.creator_user_id === "object")
                    message.creator_user_id = new $util.LongBits(object.creator_user_id.low >>> 0, object.creator_user_id.high >>> 0).toNumber(true);
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.name != null)
                message.name = String(object.name);
            if (object.town != null)
                message.town = String(object.town);
            if (object.address != null)
                message.address = String(object.address);
            if (object.latitude != null)
                message.latitude = Number(object.latitude);
            if (object.longitude != null)
                message.longitude = Number(object.longitude);
            if (object.archive != null)
                message.archive = Boolean(object.archive);
            if (object.creation_time != null)
                if ($util.Long)
                    (message.creation_time = $util.Long.fromValue(object.creation_time)).unsigned = false;
                else if (typeof object.creation_time === "string")
                    message.creation_time = parseInt(object.creation_time, 10);
                else if (typeof object.creation_time === "number")
                    message.creation_time = object.creation_time;
                else if (typeof object.creation_time === "object")
                    message.creation_time = new $util.LongBits(object.creation_time.low >>> 0, object.creation_time.high >>> 0).toNumber();
            if (object.rating != null)
                message.rating = object.rating | 0;
            if (object.description != null)
                message.description = String(object.description);
            if (object.address_quality != null)
                message.address_quality = object.address_quality | 0;
            if (object.organization_id != null)
                if ($util.Long)
                    (message.organization_id = $util.Long.fromValue(object.organization_id)).unsigned = true;
                else if (typeof object.organization_id === "string")
                    message.organization_id = parseInt(object.organization_id, 10);
                else if (typeof object.organization_id === "number")
                    message.organization_id = object.organization_id;
                else if (typeof object.organization_id === "object")
                    message.organization_id = new $util.LongBits(object.organization_id.low >>> 0, object.organization_id.high >>> 0).toNumber(true);
            if (object.url != null)
                message.url = String(object.url);
            if (object.verified != null)
                message.verified = Boolean(object.verified);
            if (object.map_url != null)
                message.map_url = String(object.map_url);
            if (object.vk_url != null)
                message.vk_url = String(object.vk_url);
            if (object.ok_url != null)
                message.ok_url = String(object.ok_url);
            if (object.youtube_url != null)
                message.youtube_url = String(object.youtube_url);
            if (object.yandex_rating != null)
                message.yandex_rating = Number(object.yandex_rating);
            if (object.yandex_rating_count != null)
                message.yandex_rating_count = object.yandex_rating_count | 0;
            if (object.telegram_id != null)
                message.telegram_id = String(object.telegram_id);
            return message;
        };

        Place.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.name = "";
                object.town = "";
                object.address = "";
                object.latitude = 0;
                object.longitude = 0;
                object.archive = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.creation_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.creation_time = options.longs === String ? "0" : 0;
                object.rating = 0;
                object.description = "";
                object.address_quality = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.organization_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.organization_id = options.longs === String ? "0" : 0;
                object.url = "";
                object.verified = false;
                object.map_url = "";
                object.vk_url = "";
                object.ok_url = "";
                object.youtube_url = "";
                object.yandex_rating = 0;
                object.yandex_rating_count = 0;
                object.telegram_id = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.creator_user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.creator_user_id = options.longs === String ? "0" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.town != null && message.hasOwnProperty("town"))
                object.town = message.town;
            if (message.address != null && message.hasOwnProperty("address"))
                object.address = message.address;
            if (message.latitude != null && message.hasOwnProperty("latitude"))
                object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
            if (message.longitude != null && message.hasOwnProperty("longitude"))
                object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
            if (message.archive != null && message.hasOwnProperty("archive"))
                object.archive = message.archive;
            if (message.creation_time != null && message.hasOwnProperty("creation_time"))
                if (typeof message.creation_time === "number")
                    object.creation_time = options.longs === String ? String(message.creation_time) : message.creation_time;
                else
                    object.creation_time = options.longs === String ? $util.Long.prototype.toString.call(message.creation_time) : options.longs === Number ? new $util.LongBits(message.creation_time.low >>> 0, message.creation_time.high >>> 0).toNumber() : message.creation_time;
            if (message.rating != null && message.hasOwnProperty("rating"))
                object.rating = message.rating;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.address_quality != null && message.hasOwnProperty("address_quality"))
                object.address_quality = message.address_quality;
            if (message.organization_id != null && message.hasOwnProperty("organization_id"))
                if (typeof message.organization_id === "number")
                    object.organization_id = options.longs === String ? String(message.organization_id) : message.organization_id;
                else
                    object.organization_id = options.longs === String ? $util.Long.prototype.toString.call(message.organization_id) : options.longs === Number ? new $util.LongBits(message.organization_id.low >>> 0, message.organization_id.high >>> 0).toNumber(true) : message.organization_id;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.verified != null && message.hasOwnProperty("verified"))
                object.verified = message.verified;
            if (message.map_url != null && message.hasOwnProperty("map_url"))
                object.map_url = message.map_url;
            if (message.vk_url != null && message.hasOwnProperty("vk_url"))
                object.vk_url = message.vk_url;
            if (message.ok_url != null && message.hasOwnProperty("ok_url"))
                object.ok_url = message.ok_url;
            if (message.youtube_url != null && message.hasOwnProperty("youtube_url"))
                object.youtube_url = message.youtube_url;
            if (message.yandex_rating != null && message.hasOwnProperty("yandex_rating"))
                object.yandex_rating = options.json && !isFinite(message.yandex_rating) ? String(message.yandex_rating) : message.yandex_rating;
            if (message.yandex_rating_count != null && message.hasOwnProperty("yandex_rating_count"))
                object.yandex_rating_count = message.yandex_rating_count;
            if (message.telegram_id != null && message.hasOwnProperty("telegram_id"))
                object.telegram_id = message.telegram_id;
            if (message.creator_user_id != null && message.hasOwnProperty("creator_user_id"))
                if (typeof message.creator_user_id === "number")
                    object.creator_user_id = options.longs === String ? String(message.creator_user_id) : message.creator_user_id;
                else
                    object.creator_user_id = options.longs === String ? $util.Long.prototype.toString.call(message.creator_user_id) : options.longs === Number ? new $util.LongBits(message.creator_user_id.low >>> 0, message.creator_user_id.high >>> 0).toNumber(true) : message.creator_user_id;
            return object;
        };

        Place.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        Place.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.Place";
        };

        return Place;
    })();

    object_msg.PlaceArray = (function() {

        function PlaceArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlaceArray.prototype.array = $util.emptyArray;

        PlaceArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.Place.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        PlaceArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlaceArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.Place.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlaceArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlaceArray)
                return object;
            let message = new $root.object_msg.PlaceArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.PlaceArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.PlaceArray.array: object expected");
                    message.array[i] = $root.object_msg.Place.fromObject(object.array[i]);
                }
            }
            return message;
        };

        PlaceArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.Place.toObject(message.array[j], options);
            }
            return object;
        };

        PlaceArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlaceArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlaceArray";
        };

        return PlaceArray;
    })();

    object_msg.PlaceAction = (function() {

        function PlaceAction(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlaceAction.prototype.unified = false;
        PlaceAction.prototype.city_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        PlaceAction.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        PlaceAction.prototype.action = "";

        PlaceAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                writer.uint32(8).uint64(message.place_id);
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(18).string(message.action);
            if (message.unified != null && Object.hasOwnProperty.call(message, "unified"))
                writer.uint32(24).bool(message.unified);
            if (message.city_id != null && Object.hasOwnProperty.call(message, "city_id"))
                writer.uint32(40).uint64(message.city_id);
            return writer;
        };

        PlaceAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlaceAction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.unified = reader.bool();
                        break;
                    }
                case 5: {
                        message.city_id = reader.uint64();
                        break;
                    }
                case 1: {
                        message.place_id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.action = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlaceAction.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlaceAction)
                return object;
            let message = new $root.object_msg.PlaceAction();
            if (object.unified != null)
                message.unified = Boolean(object.unified);
            if (object.city_id != null)
                if ($util.Long)
                    (message.city_id = $util.Long.fromValue(object.city_id)).unsigned = true;
                else if (typeof object.city_id === "string")
                    message.city_id = parseInt(object.city_id, 10);
                else if (typeof object.city_id === "number")
                    message.city_id = object.city_id;
                else if (typeof object.city_id === "object")
                    message.city_id = new $util.LongBits(object.city_id.low >>> 0, object.city_id.high >>> 0).toNumber(true);
            if (object.place_id != null)
                if ($util.Long)
                    (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                else if (typeof object.place_id === "string")
                    message.place_id = parseInt(object.place_id, 10);
                else if (typeof object.place_id === "number")
                    message.place_id = object.place_id;
                else if (typeof object.place_id === "object")
                    message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
            if (object.action != null)
                message.action = String(object.action);
            return message;
        };

        PlaceAction.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.place_id = options.longs === String ? "0" : 0;
                object.action = "";
                object.unified = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.city_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.city_id = options.longs === String ? "0" : 0;
            }
            if (message.place_id != null && message.hasOwnProperty("place_id"))
                if (typeof message.place_id === "number")
                    object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                else
                    object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = message.action;
            if (message.unified != null && message.hasOwnProperty("unified"))
                object.unified = message.unified;
            if (message.city_id != null && message.hasOwnProperty("city_id"))
                if (typeof message.city_id === "number")
                    object.city_id = options.longs === String ? String(message.city_id) : message.city_id;
                else
                    object.city_id = options.longs === String ? $util.Long.prototype.toString.call(message.city_id) : options.longs === Number ? new $util.LongBits(message.city_id.low >>> 0, message.city_id.high >>> 0).toNumber(true) : message.city_id;
            return object;
        };

        PlaceAction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlaceAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlaceAction";
        };

        return PlaceAction;
    })();

    object_msg.PlaceActionArray = (function() {

        function PlaceActionArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlaceActionArray.prototype.array = $util.emptyArray;

        PlaceActionArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.PlaceAction.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        PlaceActionArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlaceActionArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.PlaceAction.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlaceActionArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlaceActionArray)
                return object;
            let message = new $root.object_msg.PlaceActionArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.PlaceActionArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.PlaceActionArray.array: object expected");
                    message.array[i] = $root.object_msg.PlaceAction.fromObject(object.array[i]);
                }
            }
            return message;
        };

        PlaceActionArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.PlaceAction.toObject(message.array[j], options);
            }
            return object;
        };

        PlaceActionArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlaceActionArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlaceActionArray";
        };

        return PlaceActionArray;
    })();

    object_msg.PlacePhone = (function() {

        function PlacePhone(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlacePhone.prototype.whatsapp = false;
        PlacePhone.prototype.telegram = false;
        PlacePhone.prototype.main = false;
        PlacePhone.prototype.viber = false;
        PlacePhone.prototype.description = "";
        PlacePhone.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        PlacePhone.prototype.phone = "";

        PlacePhone.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                writer.uint32(8).uint64(message.place_id);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(18).string(message.phone);
            if (message.whatsapp != null && Object.hasOwnProperty.call(message, "whatsapp"))
                writer.uint32(24).bool(message.whatsapp);
            if (message.telegram != null && Object.hasOwnProperty.call(message, "telegram"))
                writer.uint32(32).bool(message.telegram);
            if (message.main != null && Object.hasOwnProperty.call(message, "main"))
                writer.uint32(40).bool(message.main);
            if (message.viber != null && Object.hasOwnProperty.call(message, "viber"))
                writer.uint32(48).bool(message.viber);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(58).string(message.description);
            return writer;
        };

        PlacePhone.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacePhone();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.whatsapp = reader.bool();
                        break;
                    }
                case 4: {
                        message.telegram = reader.bool();
                        break;
                    }
                case 5: {
                        message.main = reader.bool();
                        break;
                    }
                case 6: {
                        message.viber = reader.bool();
                        break;
                    }
                case 7: {
                        message.description = reader.string();
                        break;
                    }
                case 1: {
                        message.place_id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.phone = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlacePhone.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlacePhone)
                return object;
            let message = new $root.object_msg.PlacePhone();
            if (object.whatsapp != null)
                message.whatsapp = Boolean(object.whatsapp);
            if (object.telegram != null)
                message.telegram = Boolean(object.telegram);
            if (object.main != null)
                message.main = Boolean(object.main);
            if (object.viber != null)
                message.viber = Boolean(object.viber);
            if (object.description != null)
                message.description = String(object.description);
            if (object.place_id != null)
                if ($util.Long)
                    (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                else if (typeof object.place_id === "string")
                    message.place_id = parseInt(object.place_id, 10);
                else if (typeof object.place_id === "number")
                    message.place_id = object.place_id;
                else if (typeof object.place_id === "object")
                    message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
            if (object.phone != null)
                message.phone = String(object.phone);
            return message;
        };

        PlacePhone.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.place_id = options.longs === String ? "0" : 0;
                object.phone = "";
                object.whatsapp = false;
                object.telegram = false;
                object.main = false;
                object.viber = false;
                object.description = "";
            }
            if (message.place_id != null && message.hasOwnProperty("place_id"))
                if (typeof message.place_id === "number")
                    object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                else
                    object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            if (message.whatsapp != null && message.hasOwnProperty("whatsapp"))
                object.whatsapp = message.whatsapp;
            if (message.telegram != null && message.hasOwnProperty("telegram"))
                object.telegram = message.telegram;
            if (message.main != null && message.hasOwnProperty("main"))
                object.main = message.main;
            if (message.viber != null && message.hasOwnProperty("viber"))
                object.viber = message.viber;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            return object;
        };

        PlacePhone.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlacePhone.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlacePhone";
        };

        return PlacePhone;
    })();

    object_msg.PlacePhoneArray = (function() {

        function PlacePhoneArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlacePhoneArray.prototype.array = $util.emptyArray;

        PlacePhoneArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.PlacePhone.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        PlacePhoneArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacePhoneArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.PlacePhone.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlacePhoneArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlacePhoneArray)
                return object;
            let message = new $root.object_msg.PlacePhoneArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.PlacePhoneArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.PlacePhoneArray.array: object expected");
                    message.array[i] = $root.object_msg.PlacePhone.fromObject(object.array[i]);
                }
            }
            return message;
        };

        PlacePhoneArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.PlacePhone.toObject(message.array[j], options);
            }
            return object;
        };

        PlacePhoneArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlacePhoneArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlacePhoneArray";
        };

        return PlacePhoneArray;
    })();

    object_msg.PlaceEmail = (function() {

        function PlaceEmail(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlaceEmail.prototype.main = false;
        PlaceEmail.prototype.description = "";
        PlaceEmail.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        PlaceEmail.prototype.email = "";

        PlaceEmail.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                writer.uint32(8).uint64(message.place_id);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(18).string(message.email);
            if (message.main != null && Object.hasOwnProperty.call(message, "main"))
                writer.uint32(24).bool(message.main);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(34).string(message.description);
            return writer;
        };

        PlaceEmail.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlaceEmail();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.main = reader.bool();
                        break;
                    }
                case 4: {
                        message.description = reader.string();
                        break;
                    }
                case 1: {
                        message.place_id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.email = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlaceEmail.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlaceEmail)
                return object;
            let message = new $root.object_msg.PlaceEmail();
            if (object.main != null)
                message.main = Boolean(object.main);
            if (object.description != null)
                message.description = String(object.description);
            if (object.place_id != null)
                if ($util.Long)
                    (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                else if (typeof object.place_id === "string")
                    message.place_id = parseInt(object.place_id, 10);
                else if (typeof object.place_id === "number")
                    message.place_id = object.place_id;
                else if (typeof object.place_id === "object")
                    message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
            if (object.email != null)
                message.email = String(object.email);
            return message;
        };

        PlaceEmail.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.place_id = options.longs === String ? "0" : 0;
                object.email = "";
                object.main = false;
                object.description = "";
            }
            if (message.place_id != null && message.hasOwnProperty("place_id"))
                if (typeof message.place_id === "number")
                    object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                else
                    object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.main != null && message.hasOwnProperty("main"))
                object.main = message.main;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            return object;
        };

        PlaceEmail.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlaceEmail.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlaceEmail";
        };

        return PlaceEmail;
    })();

    object_msg.PlaceEmailArray = (function() {

        function PlaceEmailArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlaceEmailArray.prototype.array = $util.emptyArray;

        PlaceEmailArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.PlaceEmail.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        PlaceEmailArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlaceEmailArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.PlaceEmail.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlaceEmailArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlaceEmailArray)
                return object;
            let message = new $root.object_msg.PlaceEmailArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.PlaceEmailArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.PlaceEmailArray.array: object expected");
                    message.array[i] = $root.object_msg.PlaceEmail.fromObject(object.array[i]);
                }
            }
            return message;
        };

        PlaceEmailArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.PlaceEmail.toObject(message.array[j], options);
            }
            return object;
        };

        PlaceEmailArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlaceEmailArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlaceEmailArray";
        };

        return PlaceEmailArray;
    })();

    object_msg.PlaceMachine = (function() {

        function PlaceMachine(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlaceMachine.prototype.unified = false;
        PlaceMachine.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        PlaceMachine.prototype.model = "";

        PlaceMachine.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                writer.uint32(8).uint64(message.place_id);
            if (message.model != null && Object.hasOwnProperty.call(message, "model"))
                writer.uint32(18).string(message.model);
            if (message.unified != null && Object.hasOwnProperty.call(message, "unified"))
                writer.uint32(24).bool(message.unified);
            return writer;
        };

        PlaceMachine.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlaceMachine();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.unified = reader.bool();
                        break;
                    }
                case 1: {
                        message.place_id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.model = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlaceMachine.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlaceMachine)
                return object;
            let message = new $root.object_msg.PlaceMachine();
            if (object.unified != null)
                message.unified = Boolean(object.unified);
            if (object.place_id != null)
                if ($util.Long)
                    (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                else if (typeof object.place_id === "string")
                    message.place_id = parseInt(object.place_id, 10);
                else if (typeof object.place_id === "number")
                    message.place_id = object.place_id;
                else if (typeof object.place_id === "object")
                    message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
            if (object.model != null)
                message.model = String(object.model);
            return message;
        };

        PlaceMachine.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.place_id = options.longs === String ? "0" : 0;
                object.model = "";
                object.unified = false;
            }
            if (message.place_id != null && message.hasOwnProperty("place_id"))
                if (typeof message.place_id === "number")
                    object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                else
                    object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
            if (message.model != null && message.hasOwnProperty("model"))
                object.model = message.model;
            if (message.unified != null && message.hasOwnProperty("unified"))
                object.unified = message.unified;
            return object;
        };

        PlaceMachine.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlaceMachine.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlaceMachine";
        };

        return PlaceMachine;
    })();

    object_msg.PlaceMachineArray = (function() {

        function PlaceMachineArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlaceMachineArray.prototype.array = $util.emptyArray;

        PlaceMachineArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.PlaceMachine.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        PlaceMachineArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlaceMachineArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.PlaceMachine.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlaceMachineArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlaceMachineArray)
                return object;
            let message = new $root.object_msg.PlaceMachineArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.PlaceMachineArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.PlaceMachineArray.array: object expected");
                    message.array[i] = $root.object_msg.PlaceMachine.fromObject(object.array[i]);
                }
            }
            return message;
        };

        PlaceMachineArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.PlaceMachine.toObject(message.array[j], options);
            }
            return object;
        };

        PlaceMachineArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlaceMachineArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlaceMachineArray";
        };

        return PlaceMachineArray;
    })();

    object_msg.PlaceLabel = (function() {

        function PlaceLabel(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlaceLabel.prototype.value = "";
        PlaceLabel.prototype.unified = false;
        PlaceLabel.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        PlaceLabel.prototype.key = "";

        PlaceLabel.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                writer.uint32(8).uint64(message.place_id);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(18).string(message.key);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(26).string(message.value);
            if (message.unified != null && Object.hasOwnProperty.call(message, "unified"))
                writer.uint32(32).bool(message.unified);
            return writer;
        };

        PlaceLabel.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlaceLabel();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.value = reader.string();
                        break;
                    }
                case 4: {
                        message.unified = reader.bool();
                        break;
                    }
                case 1: {
                        message.place_id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.key = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlaceLabel.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlaceLabel)
                return object;
            let message = new $root.object_msg.PlaceLabel();
            if (object.value != null)
                message.value = String(object.value);
            if (object.unified != null)
                message.unified = Boolean(object.unified);
            if (object.place_id != null)
                if ($util.Long)
                    (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                else if (typeof object.place_id === "string")
                    message.place_id = parseInt(object.place_id, 10);
                else if (typeof object.place_id === "number")
                    message.place_id = object.place_id;
                else if (typeof object.place_id === "object")
                    message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
            if (object.key != null)
                message.key = String(object.key);
            return message;
        };

        PlaceLabel.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.place_id = options.longs === String ? "0" : 0;
                object.key = "";
                object.value = "";
                object.unified = false;
            }
            if (message.place_id != null && message.hasOwnProperty("place_id"))
                if (typeof message.place_id === "number")
                    object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                else
                    object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            if (message.unified != null && message.hasOwnProperty("unified"))
                object.unified = message.unified;
            return object;
        };

        PlaceLabel.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlaceLabel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlaceLabel";
        };

        return PlaceLabel;
    })();

    object_msg.PlaceLabelArray = (function() {

        function PlaceLabelArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlaceLabelArray.prototype.array = $util.emptyArray;

        PlaceLabelArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.PlaceLabel.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        PlaceLabelArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlaceLabelArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.PlaceLabel.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlaceLabelArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlaceLabelArray)
                return object;
            let message = new $root.object_msg.PlaceLabelArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.PlaceLabelArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.PlaceLabelArray.array: object expected");
                    message.array[i] = $root.object_msg.PlaceLabel.fromObject(object.array[i]);
                }
            }
            return message;
        };

        PlaceLabelArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.PlaceLabel.toObject(message.array[j], options);
            }
            return object;
        };

        PlaceLabelArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlaceLabelArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlaceLabelArray";
        };

        return PlaceLabelArray;
    })();

    object_msg.LaserCuttingPrice = (function() {

        function LaserCuttingPrice(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LaserCuttingPrice.prototype.thickness_01mm = 0;
        LaserCuttingPrice.prototype.min_m = 0;
        LaserCuttingPrice.prototype.max_m = 0;
        LaserCuttingPrice.prototype.price = 0;
        LaserCuttingPrice.prototype.currency = "";
        LaserCuttingPrice.prototype.material_unified = false;
        LaserCuttingPrice.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        LaserCuttingPrice.prototype.material = "";

        LaserCuttingPrice.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                writer.uint32(8).uint64(message.place_id);
            if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                writer.uint32(18).string(message.material);
            if (message.thickness_01mm != null && Object.hasOwnProperty.call(message, "thickness_01mm"))
                writer.uint32(24).int32(message.thickness_01mm);
            if (message.min_m != null && Object.hasOwnProperty.call(message, "min_m"))
                writer.uint32(32).int32(message.min_m);
            if (message.max_m != null && Object.hasOwnProperty.call(message, "max_m"))
                writer.uint32(40).int32(message.max_m);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                writer.uint32(53).float(message.price);
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(58).string(message.currency);
            if (message.material_unified != null && Object.hasOwnProperty.call(message, "material_unified"))
                writer.uint32(64).bool(message.material_unified);
            return writer;
        };

        LaserCuttingPrice.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LaserCuttingPrice();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.thickness_01mm = reader.int32();
                        break;
                    }
                case 4: {
                        message.min_m = reader.int32();
                        break;
                    }
                case 5: {
                        message.max_m = reader.int32();
                        break;
                    }
                case 6: {
                        message.price = reader.float();
                        break;
                    }
                case 7: {
                        message.currency = reader.string();
                        break;
                    }
                case 8: {
                        message.material_unified = reader.bool();
                        break;
                    }
                case 1: {
                        message.place_id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.material = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LaserCuttingPrice.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LaserCuttingPrice)
                return object;
            let message = new $root.object_msg.LaserCuttingPrice();
            if (object.thickness_01mm != null)
                message.thickness_01mm = object.thickness_01mm | 0;
            if (object.min_m != null)
                message.min_m = object.min_m | 0;
            if (object.max_m != null)
                message.max_m = object.max_m | 0;
            if (object.price != null)
                message.price = Number(object.price);
            if (object.currency != null)
                message.currency = String(object.currency);
            if (object.material_unified != null)
                message.material_unified = Boolean(object.material_unified);
            if (object.place_id != null)
                if ($util.Long)
                    (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                else if (typeof object.place_id === "string")
                    message.place_id = parseInt(object.place_id, 10);
                else if (typeof object.place_id === "number")
                    message.place_id = object.place_id;
                else if (typeof object.place_id === "object")
                    message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
            if (object.material != null)
                message.material = String(object.material);
            return message;
        };

        LaserCuttingPrice.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.place_id = options.longs === String ? "0" : 0;
                object.material = "";
                object.thickness_01mm = 0;
                object.min_m = 0;
                object.max_m = 0;
                object.price = 0;
                object.currency = "";
                object.material_unified = false;
            }
            if (message.place_id != null && message.hasOwnProperty("place_id"))
                if (typeof message.place_id === "number")
                    object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                else
                    object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
            if (message.material != null && message.hasOwnProperty("material"))
                object.material = message.material;
            if (message.thickness_01mm != null && message.hasOwnProperty("thickness_01mm"))
                object.thickness_01mm = message.thickness_01mm;
            if (message.min_m != null && message.hasOwnProperty("min_m"))
                object.min_m = message.min_m;
            if (message.max_m != null && message.hasOwnProperty("max_m"))
                object.max_m = message.max_m;
            if (message.price != null && message.hasOwnProperty("price"))
                object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            if (message.material_unified != null && message.hasOwnProperty("material_unified"))
                object.material_unified = message.material_unified;
            return object;
        };

        LaserCuttingPrice.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LaserCuttingPrice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LaserCuttingPrice";
        };

        return LaserCuttingPrice;
    })();

    object_msg.LaserCuttingPriceArray = (function() {

        function LaserCuttingPriceArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LaserCuttingPriceArray.prototype.array = $util.emptyArray;

        LaserCuttingPriceArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.LaserCuttingPrice.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        LaserCuttingPriceArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LaserCuttingPriceArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.LaserCuttingPrice.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LaserCuttingPriceArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LaserCuttingPriceArray)
                return object;
            let message = new $root.object_msg.LaserCuttingPriceArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.LaserCuttingPriceArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.LaserCuttingPriceArray.array: object expected");
                    message.array[i] = $root.object_msg.LaserCuttingPrice.fromObject(object.array[i]);
                }
            }
            return message;
        };

        LaserCuttingPriceArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.LaserCuttingPrice.toObject(message.array[j], options);
            }
            return object;
        };

        LaserCuttingPriceArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LaserCuttingPriceArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LaserCuttingPriceArray";
        };

        return LaserCuttingPriceArray;
    })();

    object_msg.LaserEngravingPrice = (function() {

        function LaserEngravingPrice(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LaserEngravingPrice.prototype.min_cm2 = 0;
        LaserEngravingPrice.prototype.max_cm2 = 0;
        LaserEngravingPrice.prototype.price = 0;
        LaserEngravingPrice.prototype.currency = "";
        LaserEngravingPrice.prototype.material_unified = false;
        LaserEngravingPrice.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        LaserEngravingPrice.prototype.material = "";

        LaserEngravingPrice.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                writer.uint32(8).uint64(message.place_id);
            if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                writer.uint32(18).string(message.material);
            if (message.min_cm2 != null && Object.hasOwnProperty.call(message, "min_cm2"))
                writer.uint32(24).int32(message.min_cm2);
            if (message.max_cm2 != null && Object.hasOwnProperty.call(message, "max_cm2"))
                writer.uint32(32).int32(message.max_cm2);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                writer.uint32(45).float(message.price);
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(50).string(message.currency);
            if (message.material_unified != null && Object.hasOwnProperty.call(message, "material_unified"))
                writer.uint32(56).bool(message.material_unified);
            return writer;
        };

        LaserEngravingPrice.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LaserEngravingPrice();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.min_cm2 = reader.int32();
                        break;
                    }
                case 4: {
                        message.max_cm2 = reader.int32();
                        break;
                    }
                case 5: {
                        message.price = reader.float();
                        break;
                    }
                case 6: {
                        message.currency = reader.string();
                        break;
                    }
                case 7: {
                        message.material_unified = reader.bool();
                        break;
                    }
                case 1: {
                        message.place_id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.material = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LaserEngravingPrice.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LaserEngravingPrice)
                return object;
            let message = new $root.object_msg.LaserEngravingPrice();
            if (object.min_cm2 != null)
                message.min_cm2 = object.min_cm2 | 0;
            if (object.max_cm2 != null)
                message.max_cm2 = object.max_cm2 | 0;
            if (object.price != null)
                message.price = Number(object.price);
            if (object.currency != null)
                message.currency = String(object.currency);
            if (object.material_unified != null)
                message.material_unified = Boolean(object.material_unified);
            if (object.place_id != null)
                if ($util.Long)
                    (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                else if (typeof object.place_id === "string")
                    message.place_id = parseInt(object.place_id, 10);
                else if (typeof object.place_id === "number")
                    message.place_id = object.place_id;
                else if (typeof object.place_id === "object")
                    message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
            if (object.material != null)
                message.material = String(object.material);
            return message;
        };

        LaserEngravingPrice.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.place_id = options.longs === String ? "0" : 0;
                object.material = "";
                object.min_cm2 = 0;
                object.max_cm2 = 0;
                object.price = 0;
                object.currency = "";
                object.material_unified = false;
            }
            if (message.place_id != null && message.hasOwnProperty("place_id"))
                if (typeof message.place_id === "number")
                    object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                else
                    object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
            if (message.material != null && message.hasOwnProperty("material"))
                object.material = message.material;
            if (message.min_cm2 != null && message.hasOwnProperty("min_cm2"))
                object.min_cm2 = message.min_cm2;
            if (message.max_cm2 != null && message.hasOwnProperty("max_cm2"))
                object.max_cm2 = message.max_cm2;
            if (message.price != null && message.hasOwnProperty("price"))
                object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            if (message.material_unified != null && message.hasOwnProperty("material_unified"))
                object.material_unified = message.material_unified;
            return object;
        };

        LaserEngravingPrice.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LaserEngravingPrice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LaserEngravingPrice";
        };

        return LaserEngravingPrice;
    })();

    object_msg.LaserEngravingPriceArray = (function() {

        function LaserEngravingPriceArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LaserEngravingPriceArray.prototype.array = $util.emptyArray;

        LaserEngravingPriceArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.LaserEngravingPrice.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        LaserEngravingPriceArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LaserEngravingPriceArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.LaserEngravingPrice.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LaserEngravingPriceArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LaserEngravingPriceArray)
                return object;
            let message = new $root.object_msg.LaserEngravingPriceArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.LaserEngravingPriceArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.LaserEngravingPriceArray.array: object expected");
                    message.array[i] = $root.object_msg.LaserEngravingPrice.fromObject(object.array[i]);
                }
            }
            return message;
        };

        LaserEngravingPriceArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.LaserEngravingPrice.toObject(message.array[j], options);
            }
            return object;
        };

        LaserEngravingPriceArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LaserEngravingPriceArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LaserEngravingPriceArray";
        };

        return LaserEngravingPriceArray;
    })();

    object_msg.Order = (function() {

        function Order(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        Order.prototype.payment_by_individual = false;
        Order.prototype.creation_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Order.prototype.delivery_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Order.prototype.delivery_profile_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        Order.prototype.payment_organization_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        Order.prototype.user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        Order.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        Order.prototype.name = "";

        Order.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(8).uint64(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(18).string(message.name);
            if (message.payment_by_individual != null && Object.hasOwnProperty.call(message, "payment_by_individual"))
                writer.uint32(24).bool(message.payment_by_individual);
            if (message.creation_time != null && Object.hasOwnProperty.call(message, "creation_time"))
                writer.uint32(40).int64(message.creation_time);
            if (message.delivery_time != null && Object.hasOwnProperty.call(message, "delivery_time"))
                writer.uint32(48).int64(message.delivery_time);
            if (message.delivery_profile_id != null && Object.hasOwnProperty.call(message, "delivery_profile_id"))
                writer.uint32(56).uint64(message.delivery_profile_id);
            if (message.payment_organization_id != null && Object.hasOwnProperty.call(message, "payment_organization_id"))
                writer.uint32(64).uint64(message.payment_organization_id);
            if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                writer.uint32(72).uint64(message.user_id);
            return writer;
        };

        Order.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.Order();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.payment_by_individual = reader.bool();
                        break;
                    }
                case 5: {
                        message.creation_time = reader.int64();
                        break;
                    }
                case 6: {
                        message.delivery_time = reader.int64();
                        break;
                    }
                case 7: {
                        message.delivery_profile_id = reader.uint64();
                        break;
                    }
                case 8: {
                        message.payment_organization_id = reader.uint64();
                        break;
                    }
                case 9: {
                        message.user_id = reader.uint64();
                        break;
                    }
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Order.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.Order)
                return object;
            let message = new $root.object_msg.Order();
            if (object.payment_by_individual != null)
                message.payment_by_individual = Boolean(object.payment_by_individual);
            if (object.creation_time != null)
                if ($util.Long)
                    (message.creation_time = $util.Long.fromValue(object.creation_time)).unsigned = false;
                else if (typeof object.creation_time === "string")
                    message.creation_time = parseInt(object.creation_time, 10);
                else if (typeof object.creation_time === "number")
                    message.creation_time = object.creation_time;
                else if (typeof object.creation_time === "object")
                    message.creation_time = new $util.LongBits(object.creation_time.low >>> 0, object.creation_time.high >>> 0).toNumber();
            if (object.delivery_time != null)
                if ($util.Long)
                    (message.delivery_time = $util.Long.fromValue(object.delivery_time)).unsigned = false;
                else if (typeof object.delivery_time === "string")
                    message.delivery_time = parseInt(object.delivery_time, 10);
                else if (typeof object.delivery_time === "number")
                    message.delivery_time = object.delivery_time;
                else if (typeof object.delivery_time === "object")
                    message.delivery_time = new $util.LongBits(object.delivery_time.low >>> 0, object.delivery_time.high >>> 0).toNumber();
            if (object.delivery_profile_id != null)
                if ($util.Long)
                    (message.delivery_profile_id = $util.Long.fromValue(object.delivery_profile_id)).unsigned = true;
                else if (typeof object.delivery_profile_id === "string")
                    message.delivery_profile_id = parseInt(object.delivery_profile_id, 10);
                else if (typeof object.delivery_profile_id === "number")
                    message.delivery_profile_id = object.delivery_profile_id;
                else if (typeof object.delivery_profile_id === "object")
                    message.delivery_profile_id = new $util.LongBits(object.delivery_profile_id.low >>> 0, object.delivery_profile_id.high >>> 0).toNumber(true);
            if (object.payment_organization_id != null)
                if ($util.Long)
                    (message.payment_organization_id = $util.Long.fromValue(object.payment_organization_id)).unsigned = true;
                else if (typeof object.payment_organization_id === "string")
                    message.payment_organization_id = parseInt(object.payment_organization_id, 10);
                else if (typeof object.payment_organization_id === "number")
                    message.payment_organization_id = object.payment_organization_id;
                else if (typeof object.payment_organization_id === "object")
                    message.payment_organization_id = new $util.LongBits(object.payment_organization_id.low >>> 0, object.payment_organization_id.high >>> 0).toNumber(true);
            if (object.user_id != null)
                if ($util.Long)
                    (message.user_id = $util.Long.fromValue(object.user_id)).unsigned = true;
                else if (typeof object.user_id === "string")
                    message.user_id = parseInt(object.user_id, 10);
                else if (typeof object.user_id === "number")
                    message.user_id = object.user_id;
                else if (typeof object.user_id === "object")
                    message.user_id = new $util.LongBits(object.user_id.low >>> 0, object.user_id.high >>> 0).toNumber(true);
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        Order.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.name = "";
                object.payment_by_individual = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.creation_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.creation_time = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.delivery_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.delivery_time = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.delivery_profile_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.delivery_profile_id = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.payment_organization_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.payment_organization_id = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.user_id = options.longs === String ? "0" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.payment_by_individual != null && message.hasOwnProperty("payment_by_individual"))
                object.payment_by_individual = message.payment_by_individual;
            if (message.creation_time != null && message.hasOwnProperty("creation_time"))
                if (typeof message.creation_time === "number")
                    object.creation_time = options.longs === String ? String(message.creation_time) : message.creation_time;
                else
                    object.creation_time = options.longs === String ? $util.Long.prototype.toString.call(message.creation_time) : options.longs === Number ? new $util.LongBits(message.creation_time.low >>> 0, message.creation_time.high >>> 0).toNumber() : message.creation_time;
            if (message.delivery_time != null && message.hasOwnProperty("delivery_time"))
                if (typeof message.delivery_time === "number")
                    object.delivery_time = options.longs === String ? String(message.delivery_time) : message.delivery_time;
                else
                    object.delivery_time = options.longs === String ? $util.Long.prototype.toString.call(message.delivery_time) : options.longs === Number ? new $util.LongBits(message.delivery_time.low >>> 0, message.delivery_time.high >>> 0).toNumber() : message.delivery_time;
            if (message.delivery_profile_id != null && message.hasOwnProperty("delivery_profile_id"))
                if (typeof message.delivery_profile_id === "number")
                    object.delivery_profile_id = options.longs === String ? String(message.delivery_profile_id) : message.delivery_profile_id;
                else
                    object.delivery_profile_id = options.longs === String ? $util.Long.prototype.toString.call(message.delivery_profile_id) : options.longs === Number ? new $util.LongBits(message.delivery_profile_id.low >>> 0, message.delivery_profile_id.high >>> 0).toNumber(true) : message.delivery_profile_id;
            if (message.payment_organization_id != null && message.hasOwnProperty("payment_organization_id"))
                if (typeof message.payment_organization_id === "number")
                    object.payment_organization_id = options.longs === String ? String(message.payment_organization_id) : message.payment_organization_id;
                else
                    object.payment_organization_id = options.longs === String ? $util.Long.prototype.toString.call(message.payment_organization_id) : options.longs === Number ? new $util.LongBits(message.payment_organization_id.low >>> 0, message.payment_organization_id.high >>> 0).toNumber(true) : message.payment_organization_id;
            if (message.user_id != null && message.hasOwnProperty("user_id"))
                if (typeof message.user_id === "number")
                    object.user_id = options.longs === String ? String(message.user_id) : message.user_id;
                else
                    object.user_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_id) : options.longs === Number ? new $util.LongBits(message.user_id.low >>> 0, message.user_id.high >>> 0).toNumber(true) : message.user_id;
            return object;
        };

        Order.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        Order.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.Order";
        };

        return Order;
    })();

    object_msg.OrderArray = (function() {

        function OrderArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderArray.prototype.array = $util.emptyArray;

        OrderArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.Order.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrderArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.Order.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderArray)
                return object;
            let message = new $root.object_msg.OrderArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrderArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrderArray.array: object expected");
                    message.array[i] = $root.object_msg.Order.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrderArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.Order.toObject(message.array[j], options);
            }
            return object;
        };

        OrderArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderArray";
        };

        return OrderArray;
    })();

    object_msg.OrderStatus = (function() {

        function OrderStatus(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStatus.prototype.creation_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        OrderStatus.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderStatus.prototype.status = "";

        OrderStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                writer.uint32(8).uint64(message.order_id);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(18).string(message.status);
            if (message.creation_time != null && Object.hasOwnProperty.call(message, "creation_time"))
                writer.uint32(40).int64(message.creation_time);
            return writer;
        };

        OrderStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStatus();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 5: {
                        message.creation_time = reader.int64();
                        break;
                    }
                case 1: {
                        message.order_id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.status = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStatus)
                return object;
            let message = new $root.object_msg.OrderStatus();
            if (object.creation_time != null)
                if ($util.Long)
                    (message.creation_time = $util.Long.fromValue(object.creation_time)).unsigned = false;
                else if (typeof object.creation_time === "string")
                    message.creation_time = parseInt(object.creation_time, 10);
                else if (typeof object.creation_time === "number")
                    message.creation_time = object.creation_time;
                else if (typeof object.creation_time === "object")
                    message.creation_time = new $util.LongBits(object.creation_time.low >>> 0, object.creation_time.high >>> 0).toNumber();
            if (object.order_id != null)
                if ($util.Long)
                    (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                else if (typeof object.order_id === "string")
                    message.order_id = parseInt(object.order_id, 10);
                else if (typeof object.order_id === "number")
                    message.order_id = object.order_id;
                else if (typeof object.order_id === "object")
                    message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
            if (object.status != null)
                message.status = String(object.status);
            return message;
        };

        OrderStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order_id = options.longs === String ? "0" : 0;
                object.status = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.creation_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.creation_time = options.longs === String ? "0" : 0;
            }
            if (message.order_id != null && message.hasOwnProperty("order_id"))
                if (typeof message.order_id === "number")
                    object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                else
                    object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            if (message.creation_time != null && message.hasOwnProperty("creation_time"))
                if (typeof message.creation_time === "number")
                    object.creation_time = options.longs === String ? String(message.creation_time) : message.creation_time;
                else
                    object.creation_time = options.longs === String ? $util.Long.prototype.toString.call(message.creation_time) : options.longs === Number ? new $util.LongBits(message.creation_time.low >>> 0, message.creation_time.high >>> 0).toNumber() : message.creation_time;
            return object;
        };

        OrderStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStatus";
        };

        return OrderStatus;
    })();

    object_msg.OrderStatusArray = (function() {

        function OrderStatusArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStatusArray.prototype.array = $util.emptyArray;

        OrderStatusArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.OrderStatus.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrderStatusArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStatusArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.OrderStatus.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStatusArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStatusArray)
                return object;
            let message = new $root.object_msg.OrderStatusArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrderStatusArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrderStatusArray.array: object expected");
                    message.array[i] = $root.object_msg.OrderStatus.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrderStatusArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.OrderStatus.toObject(message.array[j], options);
            }
            return object;
        };

        OrderStatusArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStatusArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStatusArray";
        };

        return OrderStatusArray;
    })();

    object_msg.OrderComment = (function() {

        function OrderComment(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderComment.prototype.author_name = "";
        OrderComment.prototype.creation_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        OrderComment.prototype.user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderComment.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderComment.prototype.comment = "";

        OrderComment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                writer.uint32(8).uint64(message.order_id);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(18).string(message.comment);
            if (message.author_name != null && Object.hasOwnProperty.call(message, "author_name"))
                writer.uint32(26).string(message.author_name);
            if (message.creation_time != null && Object.hasOwnProperty.call(message, "creation_time"))
                writer.uint32(40).int64(message.creation_time);
            if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                writer.uint32(48).uint64(message.user_id);
            return writer;
        };

        OrderComment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderComment();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.author_name = reader.string();
                        break;
                    }
                case 5: {
                        message.creation_time = reader.int64();
                        break;
                    }
                case 6: {
                        message.user_id = reader.uint64();
                        break;
                    }
                case 1: {
                        message.order_id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.comment = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderComment.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderComment)
                return object;
            let message = new $root.object_msg.OrderComment();
            if (object.author_name != null)
                message.author_name = String(object.author_name);
            if (object.creation_time != null)
                if ($util.Long)
                    (message.creation_time = $util.Long.fromValue(object.creation_time)).unsigned = false;
                else if (typeof object.creation_time === "string")
                    message.creation_time = parseInt(object.creation_time, 10);
                else if (typeof object.creation_time === "number")
                    message.creation_time = object.creation_time;
                else if (typeof object.creation_time === "object")
                    message.creation_time = new $util.LongBits(object.creation_time.low >>> 0, object.creation_time.high >>> 0).toNumber();
            if (object.user_id != null)
                if ($util.Long)
                    (message.user_id = $util.Long.fromValue(object.user_id)).unsigned = true;
                else if (typeof object.user_id === "string")
                    message.user_id = parseInt(object.user_id, 10);
                else if (typeof object.user_id === "number")
                    message.user_id = object.user_id;
                else if (typeof object.user_id === "object")
                    message.user_id = new $util.LongBits(object.user_id.low >>> 0, object.user_id.high >>> 0).toNumber(true);
            if (object.order_id != null)
                if ($util.Long)
                    (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                else if (typeof object.order_id === "string")
                    message.order_id = parseInt(object.order_id, 10);
                else if (typeof object.order_id === "number")
                    message.order_id = object.order_id;
                else if (typeof object.order_id === "object")
                    message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
            if (object.comment != null)
                message.comment = String(object.comment);
            return message;
        };

        OrderComment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order_id = options.longs === String ? "0" : 0;
                object.comment = "";
                object.author_name = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.creation_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.creation_time = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.user_id = options.longs === String ? "0" : 0;
            }
            if (message.order_id != null && message.hasOwnProperty("order_id"))
                if (typeof message.order_id === "number")
                    object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                else
                    object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
            if (message.comment != null && message.hasOwnProperty("comment"))
                object.comment = message.comment;
            if (message.author_name != null && message.hasOwnProperty("author_name"))
                object.author_name = message.author_name;
            if (message.creation_time != null && message.hasOwnProperty("creation_time"))
                if (typeof message.creation_time === "number")
                    object.creation_time = options.longs === String ? String(message.creation_time) : message.creation_time;
                else
                    object.creation_time = options.longs === String ? $util.Long.prototype.toString.call(message.creation_time) : options.longs === Number ? new $util.LongBits(message.creation_time.low >>> 0, message.creation_time.high >>> 0).toNumber() : message.creation_time;
            if (message.user_id != null && message.hasOwnProperty("user_id"))
                if (typeof message.user_id === "number")
                    object.user_id = options.longs === String ? String(message.user_id) : message.user_id;
                else
                    object.user_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_id) : options.longs === Number ? new $util.LongBits(message.user_id.low >>> 0, message.user_id.high >>> 0).toNumber(true) : message.user_id;
            return object;
        };

        OrderComment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderComment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderComment";
        };

        return OrderComment;
    })();

    object_msg.OrderCommentArray = (function() {

        function OrderCommentArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderCommentArray.prototype.array = $util.emptyArray;

        OrderCommentArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.OrderComment.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrderCommentArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderCommentArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.OrderComment.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderCommentArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderCommentArray)
                return object;
            let message = new $root.object_msg.OrderCommentArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrderCommentArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrderCommentArray.array: object expected");
                    message.array[i] = $root.object_msg.OrderComment.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrderCommentArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.OrderComment.toObject(message.array[j], options);
            }
            return object;
        };

        OrderCommentArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderCommentArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderCommentArray";
        };

        return OrderCommentArray;
    })();

    object_msg.OrderCommentFile = (function() {

        function OrderCommentFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderCommentFile.prototype.comment_creation_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        OrderCommentFile.prototype.file_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderCommentFile.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        OrderCommentFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                writer.uint32(8).uint64(message.order_id);
            if (message.comment_creation_time != null && Object.hasOwnProperty.call(message, "comment_creation_time"))
                writer.uint32(40).int64(message.comment_creation_time);
            if (message.file_id != null && Object.hasOwnProperty.call(message, "file_id"))
                writer.uint32(48).uint64(message.file_id);
            return writer;
        };

        OrderCommentFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderCommentFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 5: {
                        message.comment_creation_time = reader.int64();
                        break;
                    }
                case 6: {
                        message.file_id = reader.uint64();
                        break;
                    }
                case 1: {
                        message.order_id = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderCommentFile.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderCommentFile)
                return object;
            let message = new $root.object_msg.OrderCommentFile();
            if (object.comment_creation_time != null)
                if ($util.Long)
                    (message.comment_creation_time = $util.Long.fromValue(object.comment_creation_time)).unsigned = false;
                else if (typeof object.comment_creation_time === "string")
                    message.comment_creation_time = parseInt(object.comment_creation_time, 10);
                else if (typeof object.comment_creation_time === "number")
                    message.comment_creation_time = object.comment_creation_time;
                else if (typeof object.comment_creation_time === "object")
                    message.comment_creation_time = new $util.LongBits(object.comment_creation_time.low >>> 0, object.comment_creation_time.high >>> 0).toNumber();
            if (object.file_id != null)
                if ($util.Long)
                    (message.file_id = $util.Long.fromValue(object.file_id)).unsigned = true;
                else if (typeof object.file_id === "string")
                    message.file_id = parseInt(object.file_id, 10);
                else if (typeof object.file_id === "number")
                    message.file_id = object.file_id;
                else if (typeof object.file_id === "object")
                    message.file_id = new $util.LongBits(object.file_id.low >>> 0, object.file_id.high >>> 0).toNumber(true);
            if (object.order_id != null)
                if ($util.Long)
                    (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                else if (typeof object.order_id === "string")
                    message.order_id = parseInt(object.order_id, 10);
                else if (typeof object.order_id === "number")
                    message.order_id = object.order_id;
                else if (typeof object.order_id === "object")
                    message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
            return message;
        };

        OrderCommentFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order_id = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.comment_creation_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.comment_creation_time = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.file_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.file_id = options.longs === String ? "0" : 0;
            }
            if (message.order_id != null && message.hasOwnProperty("order_id"))
                if (typeof message.order_id === "number")
                    object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                else
                    object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
            if (message.comment_creation_time != null && message.hasOwnProperty("comment_creation_time"))
                if (typeof message.comment_creation_time === "number")
                    object.comment_creation_time = options.longs === String ? String(message.comment_creation_time) : message.comment_creation_time;
                else
                    object.comment_creation_time = options.longs === String ? $util.Long.prototype.toString.call(message.comment_creation_time) : options.longs === Number ? new $util.LongBits(message.comment_creation_time.low >>> 0, message.comment_creation_time.high >>> 0).toNumber() : message.comment_creation_time;
            if (message.file_id != null && message.hasOwnProperty("file_id"))
                if (typeof message.file_id === "number")
                    object.file_id = options.longs === String ? String(message.file_id) : message.file_id;
                else
                    object.file_id = options.longs === String ? $util.Long.prototype.toString.call(message.file_id) : options.longs === Number ? new $util.LongBits(message.file_id.low >>> 0, message.file_id.high >>> 0).toNumber(true) : message.file_id;
            return object;
        };

        OrderCommentFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderCommentFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderCommentFile";
        };

        return OrderCommentFile;
    })();

    object_msg.OrderCommentFileArray = (function() {

        function OrderCommentFileArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderCommentFileArray.prototype.array = $util.emptyArray;

        OrderCommentFileArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.OrderCommentFile.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrderCommentFileArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderCommentFileArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.OrderCommentFile.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderCommentFileArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderCommentFileArray)
                return object;
            let message = new $root.object_msg.OrderCommentFileArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrderCommentFileArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrderCommentFileArray.array: object expected");
                    message.array[i] = $root.object_msg.OrderCommentFile.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrderCommentFileArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.OrderCommentFile.toObject(message.array[j], options);
            }
            return object;
        };

        OrderCommentFileArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderCommentFileArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderCommentFileArray";
        };

        return OrderCommentFileArray;
    })();

    object_msg.OrderStage = (function() {

        function OrderStage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStage.prototype.name = "";
        OrderStage.prototype.action = "";
        OrderStage.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderStage.prototype.clients_material = false;
        OrderStage.prototype.approved_by_manager = false;
        OrderStage.prototype.manager_user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderStage.prototype.price = 0;
        OrderStage.prototype.approximate_price = 0;
        OrderStage.prototype.in_predefined_range = false;
        OrderStage.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderStage.prototype.stage_number = 0;

        OrderStage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                writer.uint32(8).uint64(message.order_id);
            if (message.stage_number != null && Object.hasOwnProperty.call(message, "stage_number"))
                writer.uint32(16).int32(message.stage_number);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(26).string(message.name);
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(34).string(message.action);
            if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                writer.uint32(40).uint64(message.place_id);
            if (message.clients_material != null && Object.hasOwnProperty.call(message, "clients_material"))
                writer.uint32(48).bool(message.clients_material);
            if (message.approved_by_manager != null && Object.hasOwnProperty.call(message, "approved_by_manager"))
                writer.uint32(56).bool(message.approved_by_manager);
            if (message.manager_user_id != null && Object.hasOwnProperty.call(message, "manager_user_id"))
                writer.uint32(64).uint64(message.manager_user_id);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                writer.uint32(77).float(message.price);
            if (message.approximate_price != null && Object.hasOwnProperty.call(message, "approximate_price"))
                writer.uint32(85).float(message.approximate_price);
            if (message.in_predefined_range != null && Object.hasOwnProperty.call(message, "in_predefined_range"))
                writer.uint32(88).bool(message.in_predefined_range);
            return writer;
        };

        OrderStage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 4: {
                        message.action = reader.string();
                        break;
                    }
                case 5: {
                        message.place_id = reader.uint64();
                        break;
                    }
                case 6: {
                        message.clients_material = reader.bool();
                        break;
                    }
                case 7: {
                        message.approved_by_manager = reader.bool();
                        break;
                    }
                case 8: {
                        message.manager_user_id = reader.uint64();
                        break;
                    }
                case 9: {
                        message.price = reader.float();
                        break;
                    }
                case 10: {
                        message.approximate_price = reader.float();
                        break;
                    }
                case 11: {
                        message.in_predefined_range = reader.bool();
                        break;
                    }
                case 1: {
                        message.order_id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.stage_number = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStage.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStage)
                return object;
            let message = new $root.object_msg.OrderStage();
            if (object.name != null)
                message.name = String(object.name);
            if (object.action != null)
                message.action = String(object.action);
            if (object.place_id != null)
                if ($util.Long)
                    (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                else if (typeof object.place_id === "string")
                    message.place_id = parseInt(object.place_id, 10);
                else if (typeof object.place_id === "number")
                    message.place_id = object.place_id;
                else if (typeof object.place_id === "object")
                    message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
            if (object.clients_material != null)
                message.clients_material = Boolean(object.clients_material);
            if (object.approved_by_manager != null)
                message.approved_by_manager = Boolean(object.approved_by_manager);
            if (object.manager_user_id != null)
                if ($util.Long)
                    (message.manager_user_id = $util.Long.fromValue(object.manager_user_id)).unsigned = true;
                else if (typeof object.manager_user_id === "string")
                    message.manager_user_id = parseInt(object.manager_user_id, 10);
                else if (typeof object.manager_user_id === "number")
                    message.manager_user_id = object.manager_user_id;
                else if (typeof object.manager_user_id === "object")
                    message.manager_user_id = new $util.LongBits(object.manager_user_id.low >>> 0, object.manager_user_id.high >>> 0).toNumber(true);
            if (object.price != null)
                message.price = Number(object.price);
            if (object.approximate_price != null)
                message.approximate_price = Number(object.approximate_price);
            if (object.in_predefined_range != null)
                message.in_predefined_range = Boolean(object.in_predefined_range);
            if (object.order_id != null)
                if ($util.Long)
                    (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                else if (typeof object.order_id === "string")
                    message.order_id = parseInt(object.order_id, 10);
                else if (typeof object.order_id === "number")
                    message.order_id = object.order_id;
                else if (typeof object.order_id === "object")
                    message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
            if (object.stage_number != null)
                message.stage_number = object.stage_number | 0;
            return message;
        };

        OrderStage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order_id = options.longs === String ? "0" : 0;
                object.stage_number = 0;
                object.name = "";
                object.action = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.place_id = options.longs === String ? "0" : 0;
                object.clients_material = false;
                object.approved_by_manager = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.manager_user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.manager_user_id = options.longs === String ? "0" : 0;
                object.price = 0;
                object.approximate_price = 0;
                object.in_predefined_range = false;
            }
            if (message.order_id != null && message.hasOwnProperty("order_id"))
                if (typeof message.order_id === "number")
                    object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                else
                    object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
            if (message.stage_number != null && message.hasOwnProperty("stage_number"))
                object.stage_number = message.stage_number;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = message.action;
            if (message.place_id != null && message.hasOwnProperty("place_id"))
                if (typeof message.place_id === "number")
                    object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                else
                    object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
            if (message.clients_material != null && message.hasOwnProperty("clients_material"))
                object.clients_material = message.clients_material;
            if (message.approved_by_manager != null && message.hasOwnProperty("approved_by_manager"))
                object.approved_by_manager = message.approved_by_manager;
            if (message.manager_user_id != null && message.hasOwnProperty("manager_user_id"))
                if (typeof message.manager_user_id === "number")
                    object.manager_user_id = options.longs === String ? String(message.manager_user_id) : message.manager_user_id;
                else
                    object.manager_user_id = options.longs === String ? $util.Long.prototype.toString.call(message.manager_user_id) : options.longs === Number ? new $util.LongBits(message.manager_user_id.low >>> 0, message.manager_user_id.high >>> 0).toNumber(true) : message.manager_user_id;
            if (message.price != null && message.hasOwnProperty("price"))
                object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
            if (message.approximate_price != null && message.hasOwnProperty("approximate_price"))
                object.approximate_price = options.json && !isFinite(message.approximate_price) ? String(message.approximate_price) : message.approximate_price;
            if (message.in_predefined_range != null && message.hasOwnProperty("in_predefined_range"))
                object.in_predefined_range = message.in_predefined_range;
            return object;
        };

        OrderStage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStage";
        };

        return OrderStage;
    })();

    object_msg.OrderStageArray = (function() {

        function OrderStageArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStageArray.prototype.array = $util.emptyArray;

        OrderStageArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.OrderStage.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrderStageArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStageArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.OrderStage.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStageArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStageArray)
                return object;
            let message = new $root.object_msg.OrderStageArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrderStageArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrderStageArray.array: object expected");
                    message.array[i] = $root.object_msg.OrderStage.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrderStageArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.OrderStage.toObject(message.array[j], options);
            }
            return object;
        };

        OrderStageArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStageArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStageArray";
        };

        return OrderStageArray;
    })();

    object_msg.OrderStageStatus = (function() {

        function OrderStageStatus(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStageStatus.prototype.status = "";
        OrderStageStatus.prototype.creation_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        OrderStageStatus.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderStageStatus.prototype.stage_number = 0;

        OrderStageStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                writer.uint32(8).uint64(message.order_id);
            if (message.stage_number != null && Object.hasOwnProperty.call(message, "stage_number"))
                writer.uint32(16).int32(message.stage_number);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(26).string(message.status);
            if (message.creation_time != null && Object.hasOwnProperty.call(message, "creation_time"))
                writer.uint32(40).int64(message.creation_time);
            return writer;
        };

        OrderStageStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStageStatus();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.status = reader.string();
                        break;
                    }
                case 5: {
                        message.creation_time = reader.int64();
                        break;
                    }
                case 1: {
                        message.order_id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.stage_number = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStageStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStageStatus)
                return object;
            let message = new $root.object_msg.OrderStageStatus();
            if (object.status != null)
                message.status = String(object.status);
            if (object.creation_time != null)
                if ($util.Long)
                    (message.creation_time = $util.Long.fromValue(object.creation_time)).unsigned = false;
                else if (typeof object.creation_time === "string")
                    message.creation_time = parseInt(object.creation_time, 10);
                else if (typeof object.creation_time === "number")
                    message.creation_time = object.creation_time;
                else if (typeof object.creation_time === "object")
                    message.creation_time = new $util.LongBits(object.creation_time.low >>> 0, object.creation_time.high >>> 0).toNumber();
            if (object.order_id != null)
                if ($util.Long)
                    (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                else if (typeof object.order_id === "string")
                    message.order_id = parseInt(object.order_id, 10);
                else if (typeof object.order_id === "number")
                    message.order_id = object.order_id;
                else if (typeof object.order_id === "object")
                    message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
            if (object.stage_number != null)
                message.stage_number = object.stage_number | 0;
            return message;
        };

        OrderStageStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order_id = options.longs === String ? "0" : 0;
                object.stage_number = 0;
                object.status = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.creation_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.creation_time = options.longs === String ? "0" : 0;
            }
            if (message.order_id != null && message.hasOwnProperty("order_id"))
                if (typeof message.order_id === "number")
                    object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                else
                    object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
            if (message.stage_number != null && message.hasOwnProperty("stage_number"))
                object.stage_number = message.stage_number;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            if (message.creation_time != null && message.hasOwnProperty("creation_time"))
                if (typeof message.creation_time === "number")
                    object.creation_time = options.longs === String ? String(message.creation_time) : message.creation_time;
                else
                    object.creation_time = options.longs === String ? $util.Long.prototype.toString.call(message.creation_time) : options.longs === Number ? new $util.LongBits(message.creation_time.low >>> 0, message.creation_time.high >>> 0).toNumber() : message.creation_time;
            return object;
        };

        OrderStageStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStageStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStageStatus";
        };

        return OrderStageStatus;
    })();

    object_msg.OrderStageStatusArray = (function() {

        function OrderStageStatusArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStageStatusArray.prototype.array = $util.emptyArray;

        OrderStageStatusArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.OrderStageStatus.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrderStageStatusArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStageStatusArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.OrderStageStatus.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStageStatusArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStageStatusArray)
                return object;
            let message = new $root.object_msg.OrderStageStatusArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrderStageStatusArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrderStageStatusArray.array: object expected");
                    message.array[i] = $root.object_msg.OrderStageStatus.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrderStageStatusArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.OrderStageStatus.toObject(message.array[j], options);
            }
            return object;
        };

        OrderStageStatusArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStageStatusArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStageStatusArray";
        };

        return OrderStageStatusArray;
    })();

    object_msg.OrderStageDeliveryProfile = (function() {

        function OrderStageDeliveryProfile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStageDeliveryProfile.prototype.contact_person = "";
        OrderStageDeliveryProfile.prototype.phone = "";
        OrderStageDeliveryProfile.prototype.address = "";
        OrderStageDeliveryProfile.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderStageDeliveryProfile.prototype.stage_number = 0;

        OrderStageDeliveryProfile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                writer.uint32(8).uint64(message.order_id);
            if (message.stage_number != null && Object.hasOwnProperty.call(message, "stage_number"))
                writer.uint32(16).int32(message.stage_number);
            if (message.contact_person != null && Object.hasOwnProperty.call(message, "contact_person"))
                writer.uint32(26).string(message.contact_person);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(34).string(message.phone);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                writer.uint32(42).string(message.address);
            return writer;
        };

        OrderStageDeliveryProfile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStageDeliveryProfile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.contact_person = reader.string();
                        break;
                    }
                case 4: {
                        message.phone = reader.string();
                        break;
                    }
                case 5: {
                        message.address = reader.string();
                        break;
                    }
                case 1: {
                        message.order_id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.stage_number = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStageDeliveryProfile.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStageDeliveryProfile)
                return object;
            let message = new $root.object_msg.OrderStageDeliveryProfile();
            if (object.contact_person != null)
                message.contact_person = String(object.contact_person);
            if (object.phone != null)
                message.phone = String(object.phone);
            if (object.address != null)
                message.address = String(object.address);
            if (object.order_id != null)
                if ($util.Long)
                    (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                else if (typeof object.order_id === "string")
                    message.order_id = parseInt(object.order_id, 10);
                else if (typeof object.order_id === "number")
                    message.order_id = object.order_id;
                else if (typeof object.order_id === "object")
                    message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
            if (object.stage_number != null)
                message.stage_number = object.stage_number | 0;
            return message;
        };

        OrderStageDeliveryProfile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order_id = options.longs === String ? "0" : 0;
                object.stage_number = 0;
                object.contact_person = "";
                object.phone = "";
                object.address = "";
            }
            if (message.order_id != null && message.hasOwnProperty("order_id"))
                if (typeof message.order_id === "number")
                    object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                else
                    object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
            if (message.stage_number != null && message.hasOwnProperty("stage_number"))
                object.stage_number = message.stage_number;
            if (message.contact_person != null && message.hasOwnProperty("contact_person"))
                object.contact_person = message.contact_person;
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            if (message.address != null && message.hasOwnProperty("address"))
                object.address = message.address;
            return object;
        };

        OrderStageDeliveryProfile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStageDeliveryProfile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStageDeliveryProfile";
        };

        return OrderStageDeliveryProfile;
    })();

    object_msg.OrderStageDeliveryProfileArray = (function() {

        function OrderStageDeliveryProfileArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStageDeliveryProfileArray.prototype.array = $util.emptyArray;

        OrderStageDeliveryProfileArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.OrderStageDeliveryProfile.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrderStageDeliveryProfileArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStageDeliveryProfileArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.OrderStageDeliveryProfile.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStageDeliveryProfileArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStageDeliveryProfileArray)
                return object;
            let message = new $root.object_msg.OrderStageDeliveryProfileArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrderStageDeliveryProfileArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrderStageDeliveryProfileArray.array: object expected");
                    message.array[i] = $root.object_msg.OrderStageDeliveryProfile.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrderStageDeliveryProfileArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.OrderStageDeliveryProfile.toObject(message.array[j], options);
            }
            return object;
        };

        OrderStageDeliveryProfileArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStageDeliveryProfileArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStageDeliveryProfileArray";
        };

        return OrderStageDeliveryProfileArray;
    })();

    object_msg.OrderStageComment = (function() {

        function OrderStageComment(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStageComment.prototype.comment = "";
        OrderStageComment.prototype.author_name = "";
        OrderStageComment.prototype.creation_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        OrderStageComment.prototype.user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderStageComment.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderStageComment.prototype.stage_number = 0;

        OrderStageComment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                writer.uint32(8).uint64(message.order_id);
            if (message.stage_number != null && Object.hasOwnProperty.call(message, "stage_number"))
                writer.uint32(16).int32(message.stage_number);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(26).string(message.comment);
            if (message.author_name != null && Object.hasOwnProperty.call(message, "author_name"))
                writer.uint32(34).string(message.author_name);
            if (message.creation_time != null && Object.hasOwnProperty.call(message, "creation_time"))
                writer.uint32(40).int64(message.creation_time);
            if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                writer.uint32(48).uint64(message.user_id);
            return writer;
        };

        OrderStageComment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStageComment();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.comment = reader.string();
                        break;
                    }
                case 4: {
                        message.author_name = reader.string();
                        break;
                    }
                case 5: {
                        message.creation_time = reader.int64();
                        break;
                    }
                case 6: {
                        message.user_id = reader.uint64();
                        break;
                    }
                case 1: {
                        message.order_id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.stage_number = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStageComment.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStageComment)
                return object;
            let message = new $root.object_msg.OrderStageComment();
            if (object.comment != null)
                message.comment = String(object.comment);
            if (object.author_name != null)
                message.author_name = String(object.author_name);
            if (object.creation_time != null)
                if ($util.Long)
                    (message.creation_time = $util.Long.fromValue(object.creation_time)).unsigned = false;
                else if (typeof object.creation_time === "string")
                    message.creation_time = parseInt(object.creation_time, 10);
                else if (typeof object.creation_time === "number")
                    message.creation_time = object.creation_time;
                else if (typeof object.creation_time === "object")
                    message.creation_time = new $util.LongBits(object.creation_time.low >>> 0, object.creation_time.high >>> 0).toNumber();
            if (object.user_id != null)
                if ($util.Long)
                    (message.user_id = $util.Long.fromValue(object.user_id)).unsigned = true;
                else if (typeof object.user_id === "string")
                    message.user_id = parseInt(object.user_id, 10);
                else if (typeof object.user_id === "number")
                    message.user_id = object.user_id;
                else if (typeof object.user_id === "object")
                    message.user_id = new $util.LongBits(object.user_id.low >>> 0, object.user_id.high >>> 0).toNumber(true);
            if (object.order_id != null)
                if ($util.Long)
                    (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                else if (typeof object.order_id === "string")
                    message.order_id = parseInt(object.order_id, 10);
                else if (typeof object.order_id === "number")
                    message.order_id = object.order_id;
                else if (typeof object.order_id === "object")
                    message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
            if (object.stage_number != null)
                message.stage_number = object.stage_number | 0;
            return message;
        };

        OrderStageComment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order_id = options.longs === String ? "0" : 0;
                object.stage_number = 0;
                object.comment = "";
                object.author_name = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.creation_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.creation_time = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.user_id = options.longs === String ? "0" : 0;
            }
            if (message.order_id != null && message.hasOwnProperty("order_id"))
                if (typeof message.order_id === "number")
                    object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                else
                    object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
            if (message.stage_number != null && message.hasOwnProperty("stage_number"))
                object.stage_number = message.stage_number;
            if (message.comment != null && message.hasOwnProperty("comment"))
                object.comment = message.comment;
            if (message.author_name != null && message.hasOwnProperty("author_name"))
                object.author_name = message.author_name;
            if (message.creation_time != null && message.hasOwnProperty("creation_time"))
                if (typeof message.creation_time === "number")
                    object.creation_time = options.longs === String ? String(message.creation_time) : message.creation_time;
                else
                    object.creation_time = options.longs === String ? $util.Long.prototype.toString.call(message.creation_time) : options.longs === Number ? new $util.LongBits(message.creation_time.low >>> 0, message.creation_time.high >>> 0).toNumber() : message.creation_time;
            if (message.user_id != null && message.hasOwnProperty("user_id"))
                if (typeof message.user_id === "number")
                    object.user_id = options.longs === String ? String(message.user_id) : message.user_id;
                else
                    object.user_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_id) : options.longs === Number ? new $util.LongBits(message.user_id.low >>> 0, message.user_id.high >>> 0).toNumber(true) : message.user_id;
            return object;
        };

        OrderStageComment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStageComment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStageComment";
        };

        return OrderStageComment;
    })();

    object_msg.OrderStageCommentArray = (function() {

        function OrderStageCommentArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStageCommentArray.prototype.array = $util.emptyArray;

        OrderStageCommentArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.OrderStageComment.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrderStageCommentArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStageCommentArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.OrderStageComment.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStageCommentArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStageCommentArray)
                return object;
            let message = new $root.object_msg.OrderStageCommentArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrderStageCommentArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrderStageCommentArray.array: object expected");
                    message.array[i] = $root.object_msg.OrderStageComment.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrderStageCommentArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.OrderStageComment.toObject(message.array[j], options);
            }
            return object;
        };

        OrderStageCommentArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStageCommentArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStageCommentArray";
        };

        return OrderStageCommentArray;
    })();

    object_msg.OrderStageCommentFile = (function() {

        function OrderStageCommentFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStageCommentFile.prototype.comment_creation_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        OrderStageCommentFile.prototype.file_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderStageCommentFile.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderStageCommentFile.prototype.stage_number = 0;

        OrderStageCommentFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                writer.uint32(8).uint64(message.order_id);
            if (message.stage_number != null && Object.hasOwnProperty.call(message, "stage_number"))
                writer.uint32(16).int32(message.stage_number);
            if (message.comment_creation_time != null && Object.hasOwnProperty.call(message, "comment_creation_time"))
                writer.uint32(40).int64(message.comment_creation_time);
            if (message.file_id != null && Object.hasOwnProperty.call(message, "file_id"))
                writer.uint32(48).uint64(message.file_id);
            return writer;
        };

        OrderStageCommentFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStageCommentFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 5: {
                        message.comment_creation_time = reader.int64();
                        break;
                    }
                case 6: {
                        message.file_id = reader.uint64();
                        break;
                    }
                case 1: {
                        message.order_id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.stage_number = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStageCommentFile.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStageCommentFile)
                return object;
            let message = new $root.object_msg.OrderStageCommentFile();
            if (object.comment_creation_time != null)
                if ($util.Long)
                    (message.comment_creation_time = $util.Long.fromValue(object.comment_creation_time)).unsigned = false;
                else if (typeof object.comment_creation_time === "string")
                    message.comment_creation_time = parseInt(object.comment_creation_time, 10);
                else if (typeof object.comment_creation_time === "number")
                    message.comment_creation_time = object.comment_creation_time;
                else if (typeof object.comment_creation_time === "object")
                    message.comment_creation_time = new $util.LongBits(object.comment_creation_time.low >>> 0, object.comment_creation_time.high >>> 0).toNumber();
            if (object.file_id != null)
                if ($util.Long)
                    (message.file_id = $util.Long.fromValue(object.file_id)).unsigned = true;
                else if (typeof object.file_id === "string")
                    message.file_id = parseInt(object.file_id, 10);
                else if (typeof object.file_id === "number")
                    message.file_id = object.file_id;
                else if (typeof object.file_id === "object")
                    message.file_id = new $util.LongBits(object.file_id.low >>> 0, object.file_id.high >>> 0).toNumber(true);
            if (object.order_id != null)
                if ($util.Long)
                    (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                else if (typeof object.order_id === "string")
                    message.order_id = parseInt(object.order_id, 10);
                else if (typeof object.order_id === "number")
                    message.order_id = object.order_id;
                else if (typeof object.order_id === "object")
                    message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
            if (object.stage_number != null)
                message.stage_number = object.stage_number | 0;
            return message;
        };

        OrderStageCommentFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order_id = options.longs === String ? "0" : 0;
                object.stage_number = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.comment_creation_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.comment_creation_time = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.file_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.file_id = options.longs === String ? "0" : 0;
            }
            if (message.order_id != null && message.hasOwnProperty("order_id"))
                if (typeof message.order_id === "number")
                    object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                else
                    object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
            if (message.stage_number != null && message.hasOwnProperty("stage_number"))
                object.stage_number = message.stage_number;
            if (message.comment_creation_time != null && message.hasOwnProperty("comment_creation_time"))
                if (typeof message.comment_creation_time === "number")
                    object.comment_creation_time = options.longs === String ? String(message.comment_creation_time) : message.comment_creation_time;
                else
                    object.comment_creation_time = options.longs === String ? $util.Long.prototype.toString.call(message.comment_creation_time) : options.longs === Number ? new $util.LongBits(message.comment_creation_time.low >>> 0, message.comment_creation_time.high >>> 0).toNumber() : message.comment_creation_time;
            if (message.file_id != null && message.hasOwnProperty("file_id"))
                if (typeof message.file_id === "number")
                    object.file_id = options.longs === String ? String(message.file_id) : message.file_id;
                else
                    object.file_id = options.longs === String ? $util.Long.prototype.toString.call(message.file_id) : options.longs === Number ? new $util.LongBits(message.file_id.low >>> 0, message.file_id.high >>> 0).toNumber(true) : message.file_id;
            return object;
        };

        OrderStageCommentFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStageCommentFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStageCommentFile";
        };

        return OrderStageCommentFile;
    })();

    object_msg.OrderStageCommentFileArray = (function() {

        function OrderStageCommentFileArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderStageCommentFileArray.prototype.array = $util.emptyArray;

        OrderStageCommentFileArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.OrderStageCommentFile.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrderStageCommentFileArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderStageCommentFileArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.OrderStageCommentFile.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderStageCommentFileArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderStageCommentFileArray)
                return object;
            let message = new $root.object_msg.OrderStageCommentFileArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrderStageCommentFileArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrderStageCommentFileArray.array: object expected");
                    message.array[i] = $root.object_msg.OrderStageCommentFile.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrderStageCommentFileArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.OrderStageCommentFile.toObject(message.array[j], options);
            }
            return object;
        };

        OrderStageCommentFileArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderStageCommentFileArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderStageCommentFileArray";
        };

        return OrderStageCommentFileArray;
    })();

    object_msg.UploadedFile = (function() {

        function UploadedFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        UploadedFile.prototype.upload_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        UploadedFile.prototype.owner_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        UploadedFile.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        UploadedFile.prototype.original_name = "";

        UploadedFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(8).uint64(message.id);
            if (message.original_name != null && Object.hasOwnProperty.call(message, "original_name"))
                writer.uint32(18).string(message.original_name);
            if (message.upload_time != null && Object.hasOwnProperty.call(message, "upload_time"))
                writer.uint32(40).int64(message.upload_time);
            if (message.owner_id != null && Object.hasOwnProperty.call(message, "owner_id"))
                writer.uint32(48).uint64(message.owner_id);
            return writer;
        };

        UploadedFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.UploadedFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 5: {
                        message.upload_time = reader.int64();
                        break;
                    }
                case 6: {
                        message.owner_id = reader.uint64();
                        break;
                    }
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.original_name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        UploadedFile.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.UploadedFile)
                return object;
            let message = new $root.object_msg.UploadedFile();
            if (object.upload_time != null)
                if ($util.Long)
                    (message.upload_time = $util.Long.fromValue(object.upload_time)).unsigned = false;
                else if (typeof object.upload_time === "string")
                    message.upload_time = parseInt(object.upload_time, 10);
                else if (typeof object.upload_time === "number")
                    message.upload_time = object.upload_time;
                else if (typeof object.upload_time === "object")
                    message.upload_time = new $util.LongBits(object.upload_time.low >>> 0, object.upload_time.high >>> 0).toNumber();
            if (object.owner_id != null)
                if ($util.Long)
                    (message.owner_id = $util.Long.fromValue(object.owner_id)).unsigned = true;
                else if (typeof object.owner_id === "string")
                    message.owner_id = parseInt(object.owner_id, 10);
                else if (typeof object.owner_id === "number")
                    message.owner_id = object.owner_id;
                else if (typeof object.owner_id === "object")
                    message.owner_id = new $util.LongBits(object.owner_id.low >>> 0, object.owner_id.high >>> 0).toNumber(true);
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.original_name != null)
                message.original_name = String(object.original_name);
            return message;
        };

        UploadedFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.original_name = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.upload_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.upload_time = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.owner_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.owner_id = options.longs === String ? "0" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.original_name != null && message.hasOwnProperty("original_name"))
                object.original_name = message.original_name;
            if (message.upload_time != null && message.hasOwnProperty("upload_time"))
                if (typeof message.upload_time === "number")
                    object.upload_time = options.longs === String ? String(message.upload_time) : message.upload_time;
                else
                    object.upload_time = options.longs === String ? $util.Long.prototype.toString.call(message.upload_time) : options.longs === Number ? new $util.LongBits(message.upload_time.low >>> 0, message.upload_time.high >>> 0).toNumber() : message.upload_time;
            if (message.owner_id != null && message.hasOwnProperty("owner_id"))
                if (typeof message.owner_id === "number")
                    object.owner_id = options.longs === String ? String(message.owner_id) : message.owner_id;
                else
                    object.owner_id = options.longs === String ? $util.Long.prototype.toString.call(message.owner_id) : options.longs === Number ? new $util.LongBits(message.owner_id.low >>> 0, message.owner_id.high >>> 0).toNumber(true) : message.owner_id;
            return object;
        };

        UploadedFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        UploadedFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.UploadedFile";
        };

        return UploadedFile;
    })();

    object_msg.UploadedFileArray = (function() {

        function UploadedFileArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        UploadedFileArray.prototype.array = $util.emptyArray;

        UploadedFileArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.UploadedFile.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        UploadedFileArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.UploadedFileArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.UploadedFile.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        UploadedFileArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.UploadedFileArray)
                return object;
            let message = new $root.object_msg.UploadedFileArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.UploadedFileArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.UploadedFileArray.array: object expected");
                    message.array[i] = $root.object_msg.UploadedFile.fromObject(object.array[i]);
                }
            }
            return message;
        };

        UploadedFileArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.UploadedFile.toObject(message.array[j], options);
            }
            return object;
        };

        UploadedFileArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        UploadedFileArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.UploadedFileArray";
        };

        return UploadedFileArray;
    })();

    object_msg.UserDeliveryProfile = (function() {

        function UserDeliveryProfile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        UserDeliveryProfile.prototype.address = "";
        UserDeliveryProfile.prototype.phone = "";
        UserDeliveryProfile.prototype.user_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        UserDeliveryProfile.prototype.last_use_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        UserDeliveryProfile.prototype.town = "";
        UserDeliveryProfile.prototype.latitude = 0;
        UserDeliveryProfile.prototype.longitude = 0;
        UserDeliveryProfile.prototype.address_quality = 0;
        UserDeliveryProfile.prototype.archive = false;
        UserDeliveryProfile.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        UserDeliveryProfile.prototype.contact_person = "";

        UserDeliveryProfile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(8).uint64(message.id);
            if (message.contact_person != null && Object.hasOwnProperty.call(message, "contact_person"))
                writer.uint32(18).string(message.contact_person);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                writer.uint32(26).string(message.address);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(34).string(message.phone);
            if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
                writer.uint32(40).uint64(message.user_id);
            if (message.last_use_time != null && Object.hasOwnProperty.call(message, "last_use_time"))
                writer.uint32(48).int64(message.last_use_time);
            if (message.town != null && Object.hasOwnProperty.call(message, "town"))
                writer.uint32(58).string(message.town);
            if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
                writer.uint32(69).float(message.latitude);
            if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                writer.uint32(77).float(message.longitude);
            if (message.address_quality != null && Object.hasOwnProperty.call(message, "address_quality"))
                writer.uint32(80).int32(message.address_quality);
            if (message.archive != null && Object.hasOwnProperty.call(message, "archive"))
                writer.uint32(88).bool(message.archive);
            return writer;
        };

        UserDeliveryProfile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.UserDeliveryProfile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.address = reader.string();
                        break;
                    }
                case 4: {
                        message.phone = reader.string();
                        break;
                    }
                case 5: {
                        message.user_id = reader.uint64();
                        break;
                    }
                case 6: {
                        message.last_use_time = reader.int64();
                        break;
                    }
                case 7: {
                        message.town = reader.string();
                        break;
                    }
                case 8: {
                        message.latitude = reader.float();
                        break;
                    }
                case 9: {
                        message.longitude = reader.float();
                        break;
                    }
                case 10: {
                        message.address_quality = reader.int32();
                        break;
                    }
                case 11: {
                        message.archive = reader.bool();
                        break;
                    }
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.contact_person = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        UserDeliveryProfile.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.UserDeliveryProfile)
                return object;
            let message = new $root.object_msg.UserDeliveryProfile();
            if (object.address != null)
                message.address = String(object.address);
            if (object.phone != null)
                message.phone = String(object.phone);
            if (object.user_id != null)
                if ($util.Long)
                    (message.user_id = $util.Long.fromValue(object.user_id)).unsigned = true;
                else if (typeof object.user_id === "string")
                    message.user_id = parseInt(object.user_id, 10);
                else if (typeof object.user_id === "number")
                    message.user_id = object.user_id;
                else if (typeof object.user_id === "object")
                    message.user_id = new $util.LongBits(object.user_id.low >>> 0, object.user_id.high >>> 0).toNumber(true);
            if (object.last_use_time != null)
                if ($util.Long)
                    (message.last_use_time = $util.Long.fromValue(object.last_use_time)).unsigned = false;
                else if (typeof object.last_use_time === "string")
                    message.last_use_time = parseInt(object.last_use_time, 10);
                else if (typeof object.last_use_time === "number")
                    message.last_use_time = object.last_use_time;
                else if (typeof object.last_use_time === "object")
                    message.last_use_time = new $util.LongBits(object.last_use_time.low >>> 0, object.last_use_time.high >>> 0).toNumber();
            if (object.town != null)
                message.town = String(object.town);
            if (object.latitude != null)
                message.latitude = Number(object.latitude);
            if (object.longitude != null)
                message.longitude = Number(object.longitude);
            if (object.address_quality != null)
                message.address_quality = object.address_quality | 0;
            if (object.archive != null)
                message.archive = Boolean(object.archive);
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.contact_person != null)
                message.contact_person = String(object.contact_person);
            return message;
        };

        UserDeliveryProfile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.contact_person = "";
                object.address = "";
                object.phone = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.user_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.user_id = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.last_use_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.last_use_time = options.longs === String ? "0" : 0;
                object.town = "";
                object.latitude = 0;
                object.longitude = 0;
                object.address_quality = 0;
                object.archive = false;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.contact_person != null && message.hasOwnProperty("contact_person"))
                object.contact_person = message.contact_person;
            if (message.address != null && message.hasOwnProperty("address"))
                object.address = message.address;
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            if (message.user_id != null && message.hasOwnProperty("user_id"))
                if (typeof message.user_id === "number")
                    object.user_id = options.longs === String ? String(message.user_id) : message.user_id;
                else
                    object.user_id = options.longs === String ? $util.Long.prototype.toString.call(message.user_id) : options.longs === Number ? new $util.LongBits(message.user_id.low >>> 0, message.user_id.high >>> 0).toNumber(true) : message.user_id;
            if (message.last_use_time != null && message.hasOwnProperty("last_use_time"))
                if (typeof message.last_use_time === "number")
                    object.last_use_time = options.longs === String ? String(message.last_use_time) : message.last_use_time;
                else
                    object.last_use_time = options.longs === String ? $util.Long.prototype.toString.call(message.last_use_time) : options.longs === Number ? new $util.LongBits(message.last_use_time.low >>> 0, message.last_use_time.high >>> 0).toNumber() : message.last_use_time;
            if (message.town != null && message.hasOwnProperty("town"))
                object.town = message.town;
            if (message.latitude != null && message.hasOwnProperty("latitude"))
                object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
            if (message.longitude != null && message.hasOwnProperty("longitude"))
                object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
            if (message.address_quality != null && message.hasOwnProperty("address_quality"))
                object.address_quality = message.address_quality;
            if (message.archive != null && message.hasOwnProperty("archive"))
                object.archive = message.archive;
            return object;
        };

        UserDeliveryProfile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        UserDeliveryProfile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.UserDeliveryProfile";
        };

        return UserDeliveryProfile;
    })();

    object_msg.UserDeliveryProfileArray = (function() {

        function UserDeliveryProfileArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        UserDeliveryProfileArray.prototype.array = $util.emptyArray;

        UserDeliveryProfileArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.UserDeliveryProfile.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        UserDeliveryProfileArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.UserDeliveryProfileArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.UserDeliveryProfile.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        UserDeliveryProfileArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.UserDeliveryProfileArray)
                return object;
            let message = new $root.object_msg.UserDeliveryProfileArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.UserDeliveryProfileArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.UserDeliveryProfileArray.array: object expected");
                    message.array[i] = $root.object_msg.UserDeliveryProfile.fromObject(object.array[i]);
                }
            }
            return message;
        };

        UserDeliveryProfileArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.UserDeliveryProfile.toObject(message.array[j], options);
            }
            return object;
        };

        UserDeliveryProfileArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        UserDeliveryProfileArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.UserDeliveryProfileArray";
        };

        return UserDeliveryProfileArray;
    })();

    object_msg.LaserCuttingStage = (function() {

        function LaserCuttingStage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LaserCuttingStage.prototype.material = "";
        LaserCuttingStage.prototype.thickness_01mm = 0;
        LaserCuttingStage.prototype.meters = 0;
        LaserCuttingStage.prototype.material_unified = false;
        LaserCuttingStage.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        LaserCuttingStage.prototype.stage_number = 0;

        LaserCuttingStage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                writer.uint32(8).uint64(message.order_id);
            if (message.stage_number != null && Object.hasOwnProperty.call(message, "stage_number"))
                writer.uint32(16).int32(message.stage_number);
            if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                writer.uint32(26).string(message.material);
            if (message.thickness_01mm != null && Object.hasOwnProperty.call(message, "thickness_01mm"))
                writer.uint32(32).int32(message.thickness_01mm);
            if (message.meters != null && Object.hasOwnProperty.call(message, "meters"))
                writer.uint32(40).int32(message.meters);
            if (message.material_unified != null && Object.hasOwnProperty.call(message, "material_unified"))
                writer.uint32(48).bool(message.material_unified);
            return writer;
        };

        LaserCuttingStage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LaserCuttingStage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.material = reader.string();
                        break;
                    }
                case 4: {
                        message.thickness_01mm = reader.int32();
                        break;
                    }
                case 5: {
                        message.meters = reader.int32();
                        break;
                    }
                case 6: {
                        message.material_unified = reader.bool();
                        break;
                    }
                case 1: {
                        message.order_id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.stage_number = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LaserCuttingStage.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LaserCuttingStage)
                return object;
            let message = new $root.object_msg.LaserCuttingStage();
            if (object.material != null)
                message.material = String(object.material);
            if (object.thickness_01mm != null)
                message.thickness_01mm = object.thickness_01mm | 0;
            if (object.meters != null)
                message.meters = object.meters | 0;
            if (object.material_unified != null)
                message.material_unified = Boolean(object.material_unified);
            if (object.order_id != null)
                if ($util.Long)
                    (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                else if (typeof object.order_id === "string")
                    message.order_id = parseInt(object.order_id, 10);
                else if (typeof object.order_id === "number")
                    message.order_id = object.order_id;
                else if (typeof object.order_id === "object")
                    message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
            if (object.stage_number != null)
                message.stage_number = object.stage_number | 0;
            return message;
        };

        LaserCuttingStage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order_id = options.longs === String ? "0" : 0;
                object.stage_number = 0;
                object.material = "";
                object.thickness_01mm = 0;
                object.meters = 0;
                object.material_unified = false;
            }
            if (message.order_id != null && message.hasOwnProperty("order_id"))
                if (typeof message.order_id === "number")
                    object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                else
                    object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
            if (message.stage_number != null && message.hasOwnProperty("stage_number"))
                object.stage_number = message.stage_number;
            if (message.material != null && message.hasOwnProperty("material"))
                object.material = message.material;
            if (message.thickness_01mm != null && message.hasOwnProperty("thickness_01mm"))
                object.thickness_01mm = message.thickness_01mm;
            if (message.meters != null && message.hasOwnProperty("meters"))
                object.meters = message.meters;
            if (message.material_unified != null && message.hasOwnProperty("material_unified"))
                object.material_unified = message.material_unified;
            return object;
        };

        LaserCuttingStage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LaserCuttingStage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LaserCuttingStage";
        };

        return LaserCuttingStage;
    })();

    object_msg.LaserCuttingStageArray = (function() {

        function LaserCuttingStageArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LaserCuttingStageArray.prototype.array = $util.emptyArray;

        LaserCuttingStageArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.LaserCuttingStage.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        LaserCuttingStageArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LaserCuttingStageArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.LaserCuttingStage.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LaserCuttingStageArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LaserCuttingStageArray)
                return object;
            let message = new $root.object_msg.LaserCuttingStageArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.LaserCuttingStageArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.LaserCuttingStageArray.array: object expected");
                    message.array[i] = $root.object_msg.LaserCuttingStage.fromObject(object.array[i]);
                }
            }
            return message;
        };

        LaserCuttingStageArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.LaserCuttingStage.toObject(message.array[j], options);
            }
            return object;
        };

        LaserCuttingStageArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LaserCuttingStageArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LaserCuttingStageArray";
        };

        return LaserCuttingStageArray;
    })();

    object_msg.LaserEngravingStage = (function() {

        function LaserEngravingStage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LaserEngravingStage.prototype.material = "";
        LaserEngravingStage.prototype.centimeters2 = 0;
        LaserEngravingStage.prototype.material_unified = false;
        LaserEngravingStage.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        LaserEngravingStage.prototype.stage_number = 0;

        LaserEngravingStage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                writer.uint32(8).uint64(message.order_id);
            if (message.stage_number != null && Object.hasOwnProperty.call(message, "stage_number"))
                writer.uint32(16).int32(message.stage_number);
            if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                writer.uint32(26).string(message.material);
            if (message.centimeters2 != null && Object.hasOwnProperty.call(message, "centimeters2"))
                writer.uint32(32).int32(message.centimeters2);
            if (message.material_unified != null && Object.hasOwnProperty.call(message, "material_unified"))
                writer.uint32(40).bool(message.material_unified);
            return writer;
        };

        LaserEngravingStage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LaserEngravingStage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.material = reader.string();
                        break;
                    }
                case 4: {
                        message.centimeters2 = reader.int32();
                        break;
                    }
                case 5: {
                        message.material_unified = reader.bool();
                        break;
                    }
                case 1: {
                        message.order_id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.stage_number = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LaserEngravingStage.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LaserEngravingStage)
                return object;
            let message = new $root.object_msg.LaserEngravingStage();
            if (object.material != null)
                message.material = String(object.material);
            if (object.centimeters2 != null)
                message.centimeters2 = object.centimeters2 | 0;
            if (object.material_unified != null)
                message.material_unified = Boolean(object.material_unified);
            if (object.order_id != null)
                if ($util.Long)
                    (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                else if (typeof object.order_id === "string")
                    message.order_id = parseInt(object.order_id, 10);
                else if (typeof object.order_id === "number")
                    message.order_id = object.order_id;
                else if (typeof object.order_id === "object")
                    message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
            if (object.stage_number != null)
                message.stage_number = object.stage_number | 0;
            return message;
        };

        LaserEngravingStage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order_id = options.longs === String ? "0" : 0;
                object.stage_number = 0;
                object.material = "";
                object.centimeters2 = 0;
                object.material_unified = false;
            }
            if (message.order_id != null && message.hasOwnProperty("order_id"))
                if (typeof message.order_id === "number")
                    object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                else
                    object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
            if (message.stage_number != null && message.hasOwnProperty("stage_number"))
                object.stage_number = message.stage_number;
            if (message.material != null && message.hasOwnProperty("material"))
                object.material = message.material;
            if (message.centimeters2 != null && message.hasOwnProperty("centimeters2"))
                object.centimeters2 = message.centimeters2;
            if (message.material_unified != null && message.hasOwnProperty("material_unified"))
                object.material_unified = message.material_unified;
            return object;
        };

        LaserEngravingStage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LaserEngravingStage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LaserEngravingStage";
        };

        return LaserEngravingStage;
    })();

    object_msg.LaserEngravingStageArray = (function() {

        function LaserEngravingStageArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LaserEngravingStageArray.prototype.array = $util.emptyArray;

        LaserEngravingStageArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.LaserEngravingStage.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        LaserEngravingStageArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LaserEngravingStageArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.LaserEngravingStage.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LaserEngravingStageArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LaserEngravingStageArray)
                return object;
            let message = new $root.object_msg.LaserEngravingStageArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.LaserEngravingStageArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.LaserEngravingStageArray.array: object expected");
                    message.array[i] = $root.object_msg.LaserEngravingStage.fromObject(object.array[i]);
                }
            }
            return message;
        };

        LaserEngravingStageArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.LaserEngravingStage.toObject(message.array[j], options);
            }
            return object;
        };

        LaserEngravingStageArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LaserEngravingStageArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LaserEngravingStageArray";
        };

        return LaserEngravingStageArray;
    })();

    object_msg.BankRu = (function() {

        function BankRu(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        BankRu.prototype.bic = "";
        BankRu.prototype.swift = "";
        BankRu.prototype.inn = "";
        BankRu.prototype.kpp = "";
        BankRu.prototype.correspondent_account = "";
        BankRu.prototype.legal_address = "";
        BankRu.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        BankRu.prototype.name = "";

        BankRu.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(8).uint64(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(18).string(message.name);
            if (message.bic != null && Object.hasOwnProperty.call(message, "bic"))
                writer.uint32(26).string(message.bic);
            if (message.swift != null && Object.hasOwnProperty.call(message, "swift"))
                writer.uint32(34).string(message.swift);
            if (message.inn != null && Object.hasOwnProperty.call(message, "inn"))
                writer.uint32(42).string(message.inn);
            if (message.kpp != null && Object.hasOwnProperty.call(message, "kpp"))
                writer.uint32(50).string(message.kpp);
            if (message.correspondent_account != null && Object.hasOwnProperty.call(message, "correspondent_account"))
                writer.uint32(58).string(message.correspondent_account);
            if (message.legal_address != null && Object.hasOwnProperty.call(message, "legal_address"))
                writer.uint32(66).string(message.legal_address);
            return writer;
        };

        BankRu.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.BankRu();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.bic = reader.string();
                        break;
                    }
                case 4: {
                        message.swift = reader.string();
                        break;
                    }
                case 5: {
                        message.inn = reader.string();
                        break;
                    }
                case 6: {
                        message.kpp = reader.string();
                        break;
                    }
                case 7: {
                        message.correspondent_account = reader.string();
                        break;
                    }
                case 8: {
                        message.legal_address = reader.string();
                        break;
                    }
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        BankRu.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.BankRu)
                return object;
            let message = new $root.object_msg.BankRu();
            if (object.bic != null)
                message.bic = String(object.bic);
            if (object.swift != null)
                message.swift = String(object.swift);
            if (object.inn != null)
                message.inn = String(object.inn);
            if (object.kpp != null)
                message.kpp = String(object.kpp);
            if (object.correspondent_account != null)
                message.correspondent_account = String(object.correspondent_account);
            if (object.legal_address != null)
                message.legal_address = String(object.legal_address);
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        BankRu.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.name = "";
                object.bic = "";
                object.swift = "";
                object.inn = "";
                object.kpp = "";
                object.correspondent_account = "";
                object.legal_address = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.bic != null && message.hasOwnProperty("bic"))
                object.bic = message.bic;
            if (message.swift != null && message.hasOwnProperty("swift"))
                object.swift = message.swift;
            if (message.inn != null && message.hasOwnProperty("inn"))
                object.inn = message.inn;
            if (message.kpp != null && message.hasOwnProperty("kpp"))
                object.kpp = message.kpp;
            if (message.correspondent_account != null && message.hasOwnProperty("correspondent_account"))
                object.correspondent_account = message.correspondent_account;
            if (message.legal_address != null && message.hasOwnProperty("legal_address"))
                object.legal_address = message.legal_address;
            return object;
        };

        BankRu.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        BankRu.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.BankRu";
        };

        return BankRu;
    })();

    object_msg.BankRuArray = (function() {

        function BankRuArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        BankRuArray.prototype.array = $util.emptyArray;

        BankRuArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.BankRu.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        BankRuArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.BankRuArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.BankRu.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        BankRuArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.BankRuArray)
                return object;
            let message = new $root.object_msg.BankRuArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.BankRuArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.BankRuArray.array: object expected");
                    message.array[i] = $root.object_msg.BankRu.fromObject(object.array[i]);
                }
            }
            return message;
        };

        BankRuArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.BankRu.toObject(message.array[j], options);
            }
            return object;
        };

        BankRuArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        BankRuArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.BankRuArray";
        };

        return BankRuArray;
    })();

    object_msg.ActionType = (function() {

        function ActionType(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        ActionType.prototype.action = "";

        ActionType.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(10).string(message.action);
            return writer;
        };

        ActionType.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.ActionType();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.action = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ActionType.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.ActionType)
                return object;
            let message = new $root.object_msg.ActionType();
            if (object.action != null)
                message.action = String(object.action);
            return message;
        };

        ActionType.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.action = "";
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = message.action;
            return object;
        };

        ActionType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ActionType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.ActionType";
        };

        return ActionType;
    })();

    object_msg.ActionTypeArray = (function() {

        function ActionTypeArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        ActionTypeArray.prototype.array = $util.emptyArray;

        ActionTypeArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.ActionType.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        ActionTypeArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.ActionTypeArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.ActionType.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ActionTypeArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.ActionTypeArray)
                return object;
            let message = new $root.object_msg.ActionTypeArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.ActionTypeArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.ActionTypeArray.array: object expected");
                    message.array[i] = $root.object_msg.ActionType.fromObject(object.array[i]);
                }
            }
            return message;
        };

        ActionTypeArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.ActionType.toObject(message.array[j], options);
            }
            return object;
        };

        ActionTypeArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ActionTypeArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.ActionTypeArray";
        };

        return ActionTypeArray;
    })();

    object_msg.ActionMaterial = (function() {

        function ActionMaterial(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        ActionMaterial.prototype.action = "";
        ActionMaterial.prototype.material = "";

        ActionMaterial.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(10).string(message.action);
            if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                writer.uint32(18).string(message.material);
            return writer;
        };

        ActionMaterial.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.ActionMaterial();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.action = reader.string();
                        break;
                    }
                case 2: {
                        message.material = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ActionMaterial.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.ActionMaterial)
                return object;
            let message = new $root.object_msg.ActionMaterial();
            if (object.action != null)
                message.action = String(object.action);
            if (object.material != null)
                message.material = String(object.material);
            return message;
        };

        ActionMaterial.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.action = "";
                object.material = "";
            }
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = message.action;
            if (message.material != null && message.hasOwnProperty("material"))
                object.material = message.material;
            return object;
        };

        ActionMaterial.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ActionMaterial.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.ActionMaterial";
        };

        return ActionMaterial;
    })();

    object_msg.ActionMaterialArray = (function() {

        function ActionMaterialArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        ActionMaterialArray.prototype.array = $util.emptyArray;

        ActionMaterialArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.ActionMaterial.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        ActionMaterialArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.ActionMaterialArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.ActionMaterial.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ActionMaterialArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.ActionMaterialArray)
                return object;
            let message = new $root.object_msg.ActionMaterialArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.ActionMaterialArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.ActionMaterialArray.array: object expected");
                    message.array[i] = $root.object_msg.ActionMaterial.fromObject(object.array[i]);
                }
            }
            return message;
        };

        ActionMaterialArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.ActionMaterial.toObject(message.array[j], options);
            }
            return object;
        };

        ActionMaterialArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ActionMaterialArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.ActionMaterialArray";
        };

        return ActionMaterialArray;
    })();

    object_msg.MaterialType = (function() {

        function MaterialType(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        MaterialType.prototype.material = "";

        MaterialType.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                writer.uint32(10).string(message.material);
            return writer;
        };

        MaterialType.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.MaterialType();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.material = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        MaterialType.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.MaterialType)
                return object;
            let message = new $root.object_msg.MaterialType();
            if (object.material != null)
                message.material = String(object.material);
            return message;
        };

        MaterialType.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.material = "";
            if (message.material != null && message.hasOwnProperty("material"))
                object.material = message.material;
            return object;
        };

        MaterialType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        MaterialType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.MaterialType";
        };

        return MaterialType;
    })();

    object_msg.MaterialTypeArray = (function() {

        function MaterialTypeArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        MaterialTypeArray.prototype.array = $util.emptyArray;

        MaterialTypeArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.MaterialType.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        MaterialTypeArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.MaterialTypeArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.MaterialType.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        MaterialTypeArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.MaterialTypeArray)
                return object;
            let message = new $root.object_msg.MaterialTypeArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.MaterialTypeArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.MaterialTypeArray.array: object expected");
                    message.array[i] = $root.object_msg.MaterialType.fromObject(object.array[i]);
                }
            }
            return message;
        };

        MaterialTypeArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.MaterialType.toObject(message.array[j], options);
            }
            return object;
        };

        MaterialTypeArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        MaterialTypeArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.MaterialTypeArray";
        };

        return MaterialTypeArray;
    })();

    object_msg.MachineType = (function() {

        function MachineType(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        MachineType.prototype.model = "";

        MachineType.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.model != null && Object.hasOwnProperty.call(message, "model"))
                writer.uint32(10).string(message.model);
            return writer;
        };

        MachineType.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.MachineType();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.model = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        MachineType.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.MachineType)
                return object;
            let message = new $root.object_msg.MachineType();
            if (object.model != null)
                message.model = String(object.model);
            return message;
        };

        MachineType.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.model = "";
            if (message.model != null && message.hasOwnProperty("model"))
                object.model = message.model;
            return object;
        };

        MachineType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        MachineType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.MachineType";
        };

        return MachineType;
    })();

    object_msg.MachineTypeArray = (function() {

        function MachineTypeArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        MachineTypeArray.prototype.array = $util.emptyArray;

        MachineTypeArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.MachineType.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        MachineTypeArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.MachineTypeArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.MachineType.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        MachineTypeArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.MachineTypeArray)
                return object;
            let message = new $root.object_msg.MachineTypeArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.MachineTypeArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.MachineTypeArray.array: object expected");
                    message.array[i] = $root.object_msg.MachineType.fromObject(object.array[i]);
                }
            }
            return message;
        };

        MachineTypeArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.MachineType.toObject(message.array[j], options);
            }
            return object;
        };

        MachineTypeArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        MachineTypeArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.MachineTypeArray";
        };

        return MachineTypeArray;
    })();

    object_msg.LabelType = (function() {

        function LabelType(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LabelType.prototype.key = "";
        LabelType.prototype.value = "";

        LabelType.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(10).string(message.key);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(18).string(message.value);
            return writer;
        };

        LabelType.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LabelType();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.string();
                        break;
                    }
                case 2: {
                        message.value = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LabelType.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LabelType)
                return object;
            let message = new $root.object_msg.LabelType();
            if (object.key != null)
                message.key = String(object.key);
            if (object.value != null)
                message.value = String(object.value);
            return message;
        };

        LabelType.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.value = "";
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            return object;
        };

        LabelType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LabelType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LabelType";
        };

        return LabelType;
    })();

    object_msg.LabelTypeArray = (function() {

        function LabelTypeArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LabelTypeArray.prototype.array = $util.emptyArray;

        LabelTypeArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.LabelType.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        LabelTypeArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LabelTypeArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.LabelType.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LabelTypeArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LabelTypeArray)
                return object;
            let message = new $root.object_msg.LabelTypeArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.LabelTypeArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.LabelTypeArray.array: object expected");
                    message.array[i] = $root.object_msg.LabelType.fromObject(object.array[i]);
                }
            }
            return message;
        };

        LabelTypeArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.LabelType.toObject(message.array[j], options);
            }
            return object;
        };

        LabelTypeArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LabelTypeArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LabelTypeArray";
        };

        return LabelTypeArray;
    })();

    object_msg.CityRu = (function() {

        function CityRu(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        CityRu.prototype.region = "";
        CityRu.prototype.population = 0;
        CityRu.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        CityRu.prototype.name = "";

        CityRu.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(8).uint64(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(18).string(message.name);
            if (message.region != null && Object.hasOwnProperty.call(message, "region"))
                writer.uint32(26).string(message.region);
            if (message.population != null && Object.hasOwnProperty.call(message, "population"))
                writer.uint32(40).int32(message.population);
            return writer;
        };

        CityRu.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.CityRu();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.region = reader.string();
                        break;
                    }
                case 5: {
                        message.population = reader.int32();
                        break;
                    }
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        CityRu.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.CityRu)
                return object;
            let message = new $root.object_msg.CityRu();
            if (object.region != null)
                message.region = String(object.region);
            if (object.population != null)
                message.population = object.population | 0;
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        CityRu.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.name = "";
                object.region = "";
                object.population = 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.region != null && message.hasOwnProperty("region"))
                object.region = message.region;
            if (message.population != null && message.hasOwnProperty("population"))
                object.population = message.population;
            return object;
        };

        CityRu.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        CityRu.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.CityRu";
        };

        return CityRu;
    })();

    object_msg.CityRuArray = (function() {

        function CityRuArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        CityRuArray.prototype.array = $util.emptyArray;

        CityRuArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.CityRu.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        CityRuArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.CityRuArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.CityRu.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        CityRuArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.CityRuArray)
                return object;
            let message = new $root.object_msg.CityRuArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.CityRuArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.CityRuArray.array: object expected");
                    message.array[i] = $root.object_msg.CityRu.fromObject(object.array[i]);
                }
            }
            return message;
        };

        CityRuArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.CityRu.toObject(message.array[j], options);
            }
            return object;
        };

        CityRuArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        CityRuArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.CityRuArray";
        };

        return CityRuArray;
    })();

    object_msg.Department = (function() {

        function Department(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        Department.prototype.latitude = 0;
        Department.prototype.longitude = 0;
        Department.prototype.city_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        Department.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        Department.prototype.address = "";

        Department.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(8).uint64(message.id);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                writer.uint32(18).string(message.address);
            if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
                writer.uint32(29).float(message.latitude);
            if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                writer.uint32(37).float(message.longitude);
            if (message.city_id != null && Object.hasOwnProperty.call(message, "city_id"))
                writer.uint32(40).uint64(message.city_id);
            return writer;
        };

        Department.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.Department();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.latitude = reader.float();
                        break;
                    }
                case 4: {
                        message.longitude = reader.float();
                        break;
                    }
                case 5: {
                        message.city_id = reader.uint64();
                        break;
                    }
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.address = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Department.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.Department)
                return object;
            let message = new $root.object_msg.Department();
            if (object.latitude != null)
                message.latitude = Number(object.latitude);
            if (object.longitude != null)
                message.longitude = Number(object.longitude);
            if (object.city_id != null)
                if ($util.Long)
                    (message.city_id = $util.Long.fromValue(object.city_id)).unsigned = true;
                else if (typeof object.city_id === "string")
                    message.city_id = parseInt(object.city_id, 10);
                else if (typeof object.city_id === "number")
                    message.city_id = object.city_id;
                else if (typeof object.city_id === "object")
                    message.city_id = new $util.LongBits(object.city_id.low >>> 0, object.city_id.high >>> 0).toNumber(true);
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.address != null)
                message.address = String(object.address);
            return message;
        };

        Department.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.address = "";
                object.latitude = 0;
                object.longitude = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.city_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.city_id = options.longs === String ? "0" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.address != null && message.hasOwnProperty("address"))
                object.address = message.address;
            if (message.latitude != null && message.hasOwnProperty("latitude"))
                object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
            if (message.longitude != null && message.hasOwnProperty("longitude"))
                object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
            if (message.city_id != null && message.hasOwnProperty("city_id"))
                if (typeof message.city_id === "number")
                    object.city_id = options.longs === String ? String(message.city_id) : message.city_id;
                else
                    object.city_id = options.longs === String ? $util.Long.prototype.toString.call(message.city_id) : options.longs === Number ? new $util.LongBits(message.city_id.low >>> 0, message.city_id.high >>> 0).toNumber(true) : message.city_id;
            return object;
        };

        Department.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        Department.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.Department";
        };

        return Department;
    })();

    object_msg.DepartmentArray = (function() {

        function DepartmentArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        DepartmentArray.prototype.array = $util.emptyArray;

        DepartmentArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.Department.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        DepartmentArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.DepartmentArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.Department.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        DepartmentArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.DepartmentArray)
                return object;
            let message = new $root.object_msg.DepartmentArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.DepartmentArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.DepartmentArray.array: object expected");
                    message.array[i] = $root.object_msg.Department.fromObject(object.array[i]);
                }
            }
            return message;
        };

        DepartmentArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.Department.toObject(message.array[j], options);
            }
            return object;
        };

        DepartmentArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        DepartmentArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.DepartmentArray";
        };

        return DepartmentArray;
    })();

    object_msg.DepartmentAction = (function() {

        function DepartmentAction(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        DepartmentAction.prototype.markup_percentage = 0;
        DepartmentAction.prototype.department_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        DepartmentAction.prototype.action = "";

        DepartmentAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.department_id != null && Object.hasOwnProperty.call(message, "department_id"))
                writer.uint32(8).uint64(message.department_id);
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(18).string(message.action);
            if (message.markup_percentage != null && Object.hasOwnProperty.call(message, "markup_percentage"))
                writer.uint32(24).int32(message.markup_percentage);
            return writer;
        };

        DepartmentAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.DepartmentAction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.markup_percentage = reader.int32();
                        break;
                    }
                case 1: {
                        message.department_id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.action = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        DepartmentAction.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.DepartmentAction)
                return object;
            let message = new $root.object_msg.DepartmentAction();
            if (object.markup_percentage != null)
                message.markup_percentage = object.markup_percentage | 0;
            if (object.department_id != null)
                if ($util.Long)
                    (message.department_id = $util.Long.fromValue(object.department_id)).unsigned = true;
                else if (typeof object.department_id === "string")
                    message.department_id = parseInt(object.department_id, 10);
                else if (typeof object.department_id === "number")
                    message.department_id = object.department_id;
                else if (typeof object.department_id === "object")
                    message.department_id = new $util.LongBits(object.department_id.low >>> 0, object.department_id.high >>> 0).toNumber(true);
            if (object.action != null)
                message.action = String(object.action);
            return message;
        };

        DepartmentAction.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.department_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.department_id = options.longs === String ? "0" : 0;
                object.action = "";
                object.markup_percentage = 0;
            }
            if (message.department_id != null && message.hasOwnProperty("department_id"))
                if (typeof message.department_id === "number")
                    object.department_id = options.longs === String ? String(message.department_id) : message.department_id;
                else
                    object.department_id = options.longs === String ? $util.Long.prototype.toString.call(message.department_id) : options.longs === Number ? new $util.LongBits(message.department_id.low >>> 0, message.department_id.high >>> 0).toNumber(true) : message.department_id;
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = message.action;
            if (message.markup_percentage != null && message.hasOwnProperty("markup_percentage"))
                object.markup_percentage = message.markup_percentage;
            return object;
        };

        DepartmentAction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        DepartmentAction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.DepartmentAction";
        };

        return DepartmentAction;
    })();

    object_msg.DepartmentActionArray = (function() {

        function DepartmentActionArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        DepartmentActionArray.prototype.array = $util.emptyArray;

        DepartmentActionArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.DepartmentAction.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        DepartmentActionArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.DepartmentActionArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.DepartmentAction.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        DepartmentActionArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.DepartmentActionArray)
                return object;
            let message = new $root.object_msg.DepartmentActionArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.DepartmentActionArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.DepartmentActionArray.array: object expected");
                    message.array[i] = $root.object_msg.DepartmentAction.fromObject(object.array[i]);
                }
            }
            return message;
        };

        DepartmentActionArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.DepartmentAction.toObject(message.array[j], options);
            }
            return object;
        };

        DepartmentActionArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        DepartmentActionArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.DepartmentActionArray";
        };

        return DepartmentActionArray;
    })();

    object_msg.LaserCuttingMaterialThickness = (function() {

        function LaserCuttingMaterialThickness(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LaserCuttingMaterialThickness.prototype.material = "";
        LaserCuttingMaterialThickness.prototype.thickness_01mm = 0;

        LaserCuttingMaterialThickness.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                writer.uint32(10).string(message.material);
            if (message.thickness_01mm != null && Object.hasOwnProperty.call(message, "thickness_01mm"))
                writer.uint32(16).int32(message.thickness_01mm);
            return writer;
        };

        LaserCuttingMaterialThickness.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LaserCuttingMaterialThickness();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.material = reader.string();
                        break;
                    }
                case 2: {
                        message.thickness_01mm = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LaserCuttingMaterialThickness.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LaserCuttingMaterialThickness)
                return object;
            let message = new $root.object_msg.LaserCuttingMaterialThickness();
            if (object.material != null)
                message.material = String(object.material);
            if (object.thickness_01mm != null)
                message.thickness_01mm = object.thickness_01mm | 0;
            return message;
        };

        LaserCuttingMaterialThickness.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.material = "";
                object.thickness_01mm = 0;
            }
            if (message.material != null && message.hasOwnProperty("material"))
                object.material = message.material;
            if (message.thickness_01mm != null && message.hasOwnProperty("thickness_01mm"))
                object.thickness_01mm = message.thickness_01mm;
            return object;
        };

        LaserCuttingMaterialThickness.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LaserCuttingMaterialThickness.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LaserCuttingMaterialThickness";
        };

        return LaserCuttingMaterialThickness;
    })();

    object_msg.LaserCuttingMaterialThicknessArray = (function() {

        function LaserCuttingMaterialThicknessArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LaserCuttingMaterialThicknessArray.prototype.array = $util.emptyArray;

        LaserCuttingMaterialThicknessArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.LaserCuttingMaterialThickness.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        LaserCuttingMaterialThicknessArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LaserCuttingMaterialThicknessArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.LaserCuttingMaterialThickness.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LaserCuttingMaterialThicknessArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LaserCuttingMaterialThicknessArray)
                return object;
            let message = new $root.object_msg.LaserCuttingMaterialThicknessArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.LaserCuttingMaterialThicknessArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.LaserCuttingMaterialThicknessArray.array: object expected");
                    message.array[i] = $root.object_msg.LaserCuttingMaterialThickness.fromObject(object.array[i]);
                }
            }
            return message;
        };

        LaserCuttingMaterialThicknessArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.LaserCuttingMaterialThickness.toObject(message.array[j], options);
            }
            return object;
        };

        LaserCuttingMaterialThicknessArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LaserCuttingMaterialThicknessArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LaserCuttingMaterialThicknessArray";
        };

        return LaserCuttingMaterialThicknessArray;
    })();

    object_msg.LocalLaserCuttingPrice = (function() {

        function LocalLaserCuttingPrice(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LocalLaserCuttingPrice.prototype.thickness_01mm = 0;
        LocalLaserCuttingPrice.prototype.min_m = 0;
        LocalLaserCuttingPrice.prototype.max_m = 0;
        LocalLaserCuttingPrice.prototype.price = 0;
        LocalLaserCuttingPrice.prototype.currency = "";
        LocalLaserCuttingPrice.prototype.reference_place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        LocalLaserCuttingPrice.prototype.department_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        LocalLaserCuttingPrice.prototype.material = "";

        LocalLaserCuttingPrice.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.department_id != null && Object.hasOwnProperty.call(message, "department_id"))
                writer.uint32(8).uint64(message.department_id);
            if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                writer.uint32(18).string(message.material);
            if (message.thickness_01mm != null && Object.hasOwnProperty.call(message, "thickness_01mm"))
                writer.uint32(24).int32(message.thickness_01mm);
            if (message.min_m != null && Object.hasOwnProperty.call(message, "min_m"))
                writer.uint32(32).int32(message.min_m);
            if (message.max_m != null && Object.hasOwnProperty.call(message, "max_m"))
                writer.uint32(40).int32(message.max_m);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                writer.uint32(53).float(message.price);
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(58).string(message.currency);
            if (message.reference_place_id != null && Object.hasOwnProperty.call(message, "reference_place_id"))
                writer.uint32(64).uint64(message.reference_place_id);
            return writer;
        };

        LocalLaserCuttingPrice.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LocalLaserCuttingPrice();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.thickness_01mm = reader.int32();
                        break;
                    }
                case 4: {
                        message.min_m = reader.int32();
                        break;
                    }
                case 5: {
                        message.max_m = reader.int32();
                        break;
                    }
                case 6: {
                        message.price = reader.float();
                        break;
                    }
                case 7: {
                        message.currency = reader.string();
                        break;
                    }
                case 8: {
                        message.reference_place_id = reader.uint64();
                        break;
                    }
                case 1: {
                        message.department_id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.material = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LocalLaserCuttingPrice.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LocalLaserCuttingPrice)
                return object;
            let message = new $root.object_msg.LocalLaserCuttingPrice();
            if (object.thickness_01mm != null)
                message.thickness_01mm = object.thickness_01mm | 0;
            if (object.min_m != null)
                message.min_m = object.min_m | 0;
            if (object.max_m != null)
                message.max_m = object.max_m | 0;
            if (object.price != null)
                message.price = Number(object.price);
            if (object.currency != null)
                message.currency = String(object.currency);
            if (object.reference_place_id != null)
                if ($util.Long)
                    (message.reference_place_id = $util.Long.fromValue(object.reference_place_id)).unsigned = true;
                else if (typeof object.reference_place_id === "string")
                    message.reference_place_id = parseInt(object.reference_place_id, 10);
                else if (typeof object.reference_place_id === "number")
                    message.reference_place_id = object.reference_place_id;
                else if (typeof object.reference_place_id === "object")
                    message.reference_place_id = new $util.LongBits(object.reference_place_id.low >>> 0, object.reference_place_id.high >>> 0).toNumber(true);
            if (object.department_id != null)
                if ($util.Long)
                    (message.department_id = $util.Long.fromValue(object.department_id)).unsigned = true;
                else if (typeof object.department_id === "string")
                    message.department_id = parseInt(object.department_id, 10);
                else if (typeof object.department_id === "number")
                    message.department_id = object.department_id;
                else if (typeof object.department_id === "object")
                    message.department_id = new $util.LongBits(object.department_id.low >>> 0, object.department_id.high >>> 0).toNumber(true);
            if (object.material != null)
                message.material = String(object.material);
            return message;
        };

        LocalLaserCuttingPrice.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.department_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.department_id = options.longs === String ? "0" : 0;
                object.material = "";
                object.thickness_01mm = 0;
                object.min_m = 0;
                object.max_m = 0;
                object.price = 0;
                object.currency = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.reference_place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.reference_place_id = options.longs === String ? "0" : 0;
            }
            if (message.department_id != null && message.hasOwnProperty("department_id"))
                if (typeof message.department_id === "number")
                    object.department_id = options.longs === String ? String(message.department_id) : message.department_id;
                else
                    object.department_id = options.longs === String ? $util.Long.prototype.toString.call(message.department_id) : options.longs === Number ? new $util.LongBits(message.department_id.low >>> 0, message.department_id.high >>> 0).toNumber(true) : message.department_id;
            if (message.material != null && message.hasOwnProperty("material"))
                object.material = message.material;
            if (message.thickness_01mm != null && message.hasOwnProperty("thickness_01mm"))
                object.thickness_01mm = message.thickness_01mm;
            if (message.min_m != null && message.hasOwnProperty("min_m"))
                object.min_m = message.min_m;
            if (message.max_m != null && message.hasOwnProperty("max_m"))
                object.max_m = message.max_m;
            if (message.price != null && message.hasOwnProperty("price"))
                object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            if (message.reference_place_id != null && message.hasOwnProperty("reference_place_id"))
                if (typeof message.reference_place_id === "number")
                    object.reference_place_id = options.longs === String ? String(message.reference_place_id) : message.reference_place_id;
                else
                    object.reference_place_id = options.longs === String ? $util.Long.prototype.toString.call(message.reference_place_id) : options.longs === Number ? new $util.LongBits(message.reference_place_id.low >>> 0, message.reference_place_id.high >>> 0).toNumber(true) : message.reference_place_id;
            return object;
        };

        LocalLaserCuttingPrice.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LocalLaserCuttingPrice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LocalLaserCuttingPrice";
        };

        return LocalLaserCuttingPrice;
    })();

    object_msg.LocalLaserCuttingPriceArray = (function() {

        function LocalLaserCuttingPriceArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LocalLaserCuttingPriceArray.prototype.array = $util.emptyArray;

        LocalLaserCuttingPriceArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.LocalLaserCuttingPrice.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        LocalLaserCuttingPriceArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LocalLaserCuttingPriceArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.LocalLaserCuttingPrice.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LocalLaserCuttingPriceArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LocalLaserCuttingPriceArray)
                return object;
            let message = new $root.object_msg.LocalLaserCuttingPriceArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.LocalLaserCuttingPriceArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.LocalLaserCuttingPriceArray.array: object expected");
                    message.array[i] = $root.object_msg.LocalLaserCuttingPrice.fromObject(object.array[i]);
                }
            }
            return message;
        };

        LocalLaserCuttingPriceArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.LocalLaserCuttingPrice.toObject(message.array[j], options);
            }
            return object;
        };

        LocalLaserCuttingPriceArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LocalLaserCuttingPriceArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LocalLaserCuttingPriceArray";
        };

        return LocalLaserCuttingPriceArray;
    })();

    object_msg.LocalLaserEngravingPrice = (function() {

        function LocalLaserEngravingPrice(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LocalLaserEngravingPrice.prototype.min_cm2 = 0;
        LocalLaserEngravingPrice.prototype.max_cm2 = 0;
        LocalLaserEngravingPrice.prototype.price = 0;
        LocalLaserEngravingPrice.prototype.currency = "";
        LocalLaserEngravingPrice.prototype.reference_place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        LocalLaserEngravingPrice.prototype.department_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        LocalLaserEngravingPrice.prototype.material = "";

        LocalLaserEngravingPrice.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.department_id != null && Object.hasOwnProperty.call(message, "department_id"))
                writer.uint32(8).uint64(message.department_id);
            if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                writer.uint32(18).string(message.material);
            if (message.min_cm2 != null && Object.hasOwnProperty.call(message, "min_cm2"))
                writer.uint32(24).int32(message.min_cm2);
            if (message.max_cm2 != null && Object.hasOwnProperty.call(message, "max_cm2"))
                writer.uint32(32).int32(message.max_cm2);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                writer.uint32(45).float(message.price);
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(50).string(message.currency);
            if (message.reference_place_id != null && Object.hasOwnProperty.call(message, "reference_place_id"))
                writer.uint32(56).uint64(message.reference_place_id);
            return writer;
        };

        LocalLaserEngravingPrice.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LocalLaserEngravingPrice();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.min_cm2 = reader.int32();
                        break;
                    }
                case 4: {
                        message.max_cm2 = reader.int32();
                        break;
                    }
                case 5: {
                        message.price = reader.float();
                        break;
                    }
                case 6: {
                        message.currency = reader.string();
                        break;
                    }
                case 7: {
                        message.reference_place_id = reader.uint64();
                        break;
                    }
                case 1: {
                        message.department_id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.material = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LocalLaserEngravingPrice.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LocalLaserEngravingPrice)
                return object;
            let message = new $root.object_msg.LocalLaserEngravingPrice();
            if (object.min_cm2 != null)
                message.min_cm2 = object.min_cm2 | 0;
            if (object.max_cm2 != null)
                message.max_cm2 = object.max_cm2 | 0;
            if (object.price != null)
                message.price = Number(object.price);
            if (object.currency != null)
                message.currency = String(object.currency);
            if (object.reference_place_id != null)
                if ($util.Long)
                    (message.reference_place_id = $util.Long.fromValue(object.reference_place_id)).unsigned = true;
                else if (typeof object.reference_place_id === "string")
                    message.reference_place_id = parseInt(object.reference_place_id, 10);
                else if (typeof object.reference_place_id === "number")
                    message.reference_place_id = object.reference_place_id;
                else if (typeof object.reference_place_id === "object")
                    message.reference_place_id = new $util.LongBits(object.reference_place_id.low >>> 0, object.reference_place_id.high >>> 0).toNumber(true);
            if (object.department_id != null)
                if ($util.Long)
                    (message.department_id = $util.Long.fromValue(object.department_id)).unsigned = true;
                else if (typeof object.department_id === "string")
                    message.department_id = parseInt(object.department_id, 10);
                else if (typeof object.department_id === "number")
                    message.department_id = object.department_id;
                else if (typeof object.department_id === "object")
                    message.department_id = new $util.LongBits(object.department_id.low >>> 0, object.department_id.high >>> 0).toNumber(true);
            if (object.material != null)
                message.material = String(object.material);
            return message;
        };

        LocalLaserEngravingPrice.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.department_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.department_id = options.longs === String ? "0" : 0;
                object.material = "";
                object.min_cm2 = 0;
                object.max_cm2 = 0;
                object.price = 0;
                object.currency = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.reference_place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.reference_place_id = options.longs === String ? "0" : 0;
            }
            if (message.department_id != null && message.hasOwnProperty("department_id"))
                if (typeof message.department_id === "number")
                    object.department_id = options.longs === String ? String(message.department_id) : message.department_id;
                else
                    object.department_id = options.longs === String ? $util.Long.prototype.toString.call(message.department_id) : options.longs === Number ? new $util.LongBits(message.department_id.low >>> 0, message.department_id.high >>> 0).toNumber(true) : message.department_id;
            if (message.material != null && message.hasOwnProperty("material"))
                object.material = message.material;
            if (message.min_cm2 != null && message.hasOwnProperty("min_cm2"))
                object.min_cm2 = message.min_cm2;
            if (message.max_cm2 != null && message.hasOwnProperty("max_cm2"))
                object.max_cm2 = message.max_cm2;
            if (message.price != null && message.hasOwnProperty("price"))
                object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            if (message.reference_place_id != null && message.hasOwnProperty("reference_place_id"))
                if (typeof message.reference_place_id === "number")
                    object.reference_place_id = options.longs === String ? String(message.reference_place_id) : message.reference_place_id;
                else
                    object.reference_place_id = options.longs === String ? $util.Long.prototype.toString.call(message.reference_place_id) : options.longs === Number ? new $util.LongBits(message.reference_place_id.low >>> 0, message.reference_place_id.high >>> 0).toNumber(true) : message.reference_place_id;
            return object;
        };

        LocalLaserEngravingPrice.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LocalLaserEngravingPrice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LocalLaserEngravingPrice";
        };

        return LocalLaserEngravingPrice;
    })();

    object_msg.LocalLaserEngravingPriceArray = (function() {

        function LocalLaserEngravingPriceArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        LocalLaserEngravingPriceArray.prototype.array = $util.emptyArray;

        LocalLaserEngravingPriceArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.LocalLaserEngravingPrice.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        LocalLaserEngravingPriceArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.LocalLaserEngravingPriceArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.LocalLaserEngravingPrice.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        LocalLaserEngravingPriceArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.LocalLaserEngravingPriceArray)
                return object;
            let message = new $root.object_msg.LocalLaserEngravingPriceArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.LocalLaserEngravingPriceArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.LocalLaserEngravingPriceArray.array: object expected");
                    message.array[i] = $root.object_msg.LocalLaserEngravingPrice.fromObject(object.array[i]);
                }
            }
            return message;
        };

        LocalLaserEngravingPriceArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.LocalLaserEngravingPrice.toObject(message.array[j], options);
            }
            return object;
        };

        LocalLaserEngravingPriceArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        LocalLaserEngravingPriceArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.LocalLaserEngravingPriceArray";
        };

        return LocalLaserEngravingPriceArray;
    })();

    object_msg.OrderService = (function() {

        function OrderService(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderService.prototype.type = "";
        OrderService.prototype.price = 0;
        OrderService.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderService.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
        OrderService.prototype.name = "";

        OrderService.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(8).uint64(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(18).string(message.name);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(26).string(message.type);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                writer.uint32(37).float(message.price);
            if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                writer.uint32(40).uint64(message.order_id);
            return writer;
        };

        OrderService.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderService();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.type = reader.string();
                        break;
                    }
                case 4: {
                        message.price = reader.float();
                        break;
                    }
                case 5: {
                        message.order_id = reader.uint64();
                        break;
                    }
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderService.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderService)
                return object;
            let message = new $root.object_msg.OrderService();
            if (object.type != null)
                message.type = String(object.type);
            if (object.price != null)
                message.price = Number(object.price);
            if (object.order_id != null)
                if ($util.Long)
                    (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                else if (typeof object.order_id === "string")
                    message.order_id = parseInt(object.order_id, 10);
                else if (typeof object.order_id === "number")
                    message.order_id = object.order_id;
                else if (typeof object.order_id === "object")
                    message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        OrderService.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.name = "";
                object.type = "";
                object.price = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.order_id = options.longs === String ? "0" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = message.type;
            if (message.price != null && message.hasOwnProperty("price"))
                object.price = options.json && !isFinite(message.price) ? String(message.price) : message.price;
            if (message.order_id != null && message.hasOwnProperty("order_id"))
                if (typeof message.order_id === "number")
                    object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                else
                    object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
            return object;
        };

        OrderService.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderService.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderService";
        };

        return OrderService;
    })();

    object_msg.OrderServiceArray = (function() {

        function OrderServiceArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrderServiceArray.prototype.array = $util.emptyArray;

        OrderServiceArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.OrderService.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        OrderServiceArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrderServiceArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.OrderService.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrderServiceArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrderServiceArray)
                return object;
            let message = new $root.object_msg.OrderServiceArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.OrderServiceArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.OrderServiceArray.array: object expected");
                    message.array[i] = $root.object_msg.OrderService.fromObject(object.array[i]);
                }
            }
            return message;
        };

        OrderServiceArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.OrderService.toObject(message.array[j], options);
            }
            return object;
        };

        OrderServiceArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderServiceArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrderServiceArray";
        };

        return OrderServiceArray;
    })();

    object_msg.logEntry = (function() {

        function logEntry(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        logEntry.prototype.message = "";
        logEntry.prototype["function"] = "";
        logEntry.prototype.object = "";
        logEntry.prototype.app = "";
        logEntry.prototype.pod = "";
        logEntry.prototype.raw_request = "";
        logEntry.prototype.phone = "";
        logEntry.prototype.ip = "";
        logEntry.prototype.honeypot_error_type = 0;
        logEntry.prototype.processed_request = "";
        logEntry.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        logEntry.prototype.level = 0;

        logEntry.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                writer.uint32(8).int64(message.time);
            if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                writer.uint32(16).int32(message.level);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(26).string(message.message);
            if (message["function"] != null && Object.hasOwnProperty.call(message, "function"))
                writer.uint32(34).string(message["function"]);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                writer.uint32(42).string(message.object);
            if (message.app != null && Object.hasOwnProperty.call(message, "app"))
                writer.uint32(50).string(message.app);
            if (message.pod != null && Object.hasOwnProperty.call(message, "pod"))
                writer.uint32(58).string(message.pod);
            if (message.raw_request != null && Object.hasOwnProperty.call(message, "raw_request"))
                writer.uint32(66).string(message.raw_request);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(74).string(message.phone);
            if (message.ip != null && Object.hasOwnProperty.call(message, "ip"))
                writer.uint32(82).string(message.ip);
            if (message.honeypot_error_type != null && Object.hasOwnProperty.call(message, "honeypot_error_type"))
                writer.uint32(88).int32(message.honeypot_error_type);
            if (message.processed_request != null && Object.hasOwnProperty.call(message, "processed_request"))
                writer.uint32(98).string(message.processed_request);
            return writer;
        };

        logEntry.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.logEntry();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3: {
                        message.message = reader.string();
                        break;
                    }
                case 4: {
                        message["function"] = reader.string();
                        break;
                    }
                case 5: {
                        message.object = reader.string();
                        break;
                    }
                case 6: {
                        message.app = reader.string();
                        break;
                    }
                case 7: {
                        message.pod = reader.string();
                        break;
                    }
                case 8: {
                        message.raw_request = reader.string();
                        break;
                    }
                case 9: {
                        message.phone = reader.string();
                        break;
                    }
                case 10: {
                        message.ip = reader.string();
                        break;
                    }
                case 11: {
                        message.honeypot_error_type = reader.int32();
                        break;
                    }
                case 12: {
                        message.processed_request = reader.string();
                        break;
                    }
                case 1: {
                        message.time = reader.int64();
                        break;
                    }
                case 2: {
                        message.level = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        logEntry.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.logEntry)
                return object;
            let message = new $root.object_msg.logEntry();
            if (object.message != null)
                message.message = String(object.message);
            if (object["function"] != null)
                message["function"] = String(object["function"]);
            if (object.object != null)
                message.object = String(object.object);
            if (object.app != null)
                message.app = String(object.app);
            if (object.pod != null)
                message.pod = String(object.pod);
            if (object.raw_request != null)
                message.raw_request = String(object.raw_request);
            if (object.phone != null)
                message.phone = String(object.phone);
            if (object.ip != null)
                message.ip = String(object.ip);
            if (object.honeypot_error_type != null)
                message.honeypot_error_type = object.honeypot_error_type | 0;
            if (object.processed_request != null)
                message.processed_request = String(object.processed_request);
            if (object.time != null)
                if ($util.Long)
                    (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                else if (typeof object.time === "string")
                    message.time = parseInt(object.time, 10);
                else if (typeof object.time === "number")
                    message.time = object.time;
                else if (typeof object.time === "object")
                    message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
            if (object.level != null)
                message.level = object.level | 0;
            return message;
        };

        logEntry.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.time = options.longs === String ? "0" : 0;
                object.level = 0;
                object.message = "";
                object["function"] = "";
                object.object = "";
                object.app = "";
                object.pod = "";
                object.raw_request = "";
                object.phone = "";
                object.ip = "";
                object.honeypot_error_type = 0;
                object.processed_request = "";
            }
            if (message.time != null && message.hasOwnProperty("time"))
                if (typeof message.time === "number")
                    object.time = options.longs === String ? String(message.time) : message.time;
                else
                    object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
            if (message.level != null && message.hasOwnProperty("level"))
                object.level = message.level;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            if (message["function"] != null && message.hasOwnProperty("function"))
                object["function"] = message["function"];
            if (message.object != null && message.hasOwnProperty("object"))
                object.object = message.object;
            if (message.app != null && message.hasOwnProperty("app"))
                object.app = message.app;
            if (message.pod != null && message.hasOwnProperty("pod"))
                object.pod = message.pod;
            if (message.raw_request != null && message.hasOwnProperty("raw_request"))
                object.raw_request = message.raw_request;
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            if (message.ip != null && message.hasOwnProperty("ip"))
                object.ip = message.ip;
            if (message.honeypot_error_type != null && message.hasOwnProperty("honeypot_error_type"))
                object.honeypot_error_type = message.honeypot_error_type;
            if (message.processed_request != null && message.hasOwnProperty("processed_request"))
                object.processed_request = message.processed_request;
            return object;
        };

        logEntry.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        logEntry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.logEntry";
        };

        return logEntry;
    })();

    object_msg.logEntryArray = (function() {

        function logEntryArray(properties) {
            this.array = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        logEntryArray.prototype.array = $util.emptyArray;

        logEntryArray.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.array != null && message.array.length)
                for (let i = 0; i < message.array.length; ++i)
                    $root.object_msg.logEntry.encode(message.array[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        logEntryArray.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.logEntryArray();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.array && message.array.length))
                            message.array = [];
                        message.array.push($root.object_msg.logEntry.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        logEntryArray.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.logEntryArray)
                return object;
            let message = new $root.object_msg.logEntryArray();
            if (object.array) {
                if (!Array.isArray(object.array))
                    throw TypeError(".object_msg.logEntryArray.array: array expected");
                message.array = [];
                for (let i = 0; i < object.array.length; ++i) {
                    if (typeof object.array[i] !== "object")
                        throw TypeError(".object_msg.logEntryArray.array: object expected");
                    message.array[i] = $root.object_msg.logEntry.fromObject(object.array[i]);
                }
            }
            return message;
        };

        logEntryArray.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.array = [];
            if (message.array && message.array.length) {
                object.array = [];
                for (let j = 0; j < message.array.length; ++j)
                    object.array[j] = $root.object_msg.logEntry.toObject(message.array[j], options);
            }
            return object;
        };

        logEntryArray.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        logEntryArray.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.logEntryArray";
        };

        return logEntryArray;
    })();

    object_msg.OrdersApiRequest = (function() {

        function OrdersApiRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrdersApiRequest.prototype.update_order_by_user_args = null;
        OrdersApiRequest.prototype.update_delivery_profiles_by_user_args = null;
        OrdersApiRequest.prototype.reload_delivery_profiles_args = null;
        OrdersApiRequest.prototype.select_order_args = null;
        OrdersApiRequest.prototype.select_order_stage_args = null;
        OrdersApiRequest.prototype.search_orders_args = null;
        OrdersApiRequest.prototype.get_stage_action_approximate_price_args = null;
        OrdersApiRequest.prototype.search_incoming_orders_args = null;
        OrdersApiRequest.prototype.get_last_organization_used_for_payment_args = null;

        let $oneOfFields;

        Object.defineProperty(OrdersApiRequest.prototype, "args", {
            get: $util.oneOfGetter($oneOfFields = ["update_order_by_user_args", "update_delivery_profiles_by_user_args", "reload_delivery_profiles_args", "select_order_args", "select_order_stage_args", "search_orders_args", "get_stage_action_approximate_price_args", "search_incoming_orders_args", "get_last_organization_used_for_payment_args"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        OrdersApiRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.update_order_by_user_args != null && Object.hasOwnProperty.call(message, "update_order_by_user_args"))
                $root.object_msg.OrdersApiRequest.update_order_by_user.encode(message.update_order_by_user_args, writer.uint32(10).fork()).ldelim();
            if (message.update_delivery_profiles_by_user_args != null && Object.hasOwnProperty.call(message, "update_delivery_profiles_by_user_args"))
                $root.object_msg.OrdersApiRequest.update_delivery_profiles_by_user.encode(message.update_delivery_profiles_by_user_args, writer.uint32(18).fork()).ldelim();
            if (message.reload_delivery_profiles_args != null && Object.hasOwnProperty.call(message, "reload_delivery_profiles_args"))
                $root.object_msg.OrdersApiRequest.reload_delivery_profiles.encode(message.reload_delivery_profiles_args, writer.uint32(26).fork()).ldelim();
            if (message.select_order_args != null && Object.hasOwnProperty.call(message, "select_order_args"))
                $root.object_msg.OrdersApiRequest.select_order.encode(message.select_order_args, writer.uint32(34).fork()).ldelim();
            if (message.select_order_stage_args != null && Object.hasOwnProperty.call(message, "select_order_stage_args"))
                $root.object_msg.OrdersApiRequest.select_order_stage.encode(message.select_order_stage_args, writer.uint32(42).fork()).ldelim();
            if (message.search_orders_args != null && Object.hasOwnProperty.call(message, "search_orders_args"))
                $root.object_msg.OrdersApiRequest.search_orders.encode(message.search_orders_args, writer.uint32(50).fork()).ldelim();
            if (message.get_stage_action_approximate_price_args != null && Object.hasOwnProperty.call(message, "get_stage_action_approximate_price_args"))
                $root.object_msg.OrdersApiRequest.get_stage_action_approximate_price.encode(message.get_stage_action_approximate_price_args, writer.uint32(58).fork()).ldelim();
            if (message.search_incoming_orders_args != null && Object.hasOwnProperty.call(message, "search_incoming_orders_args"))
                $root.object_msg.OrdersApiRequest.search_incoming_orders.encode(message.search_incoming_orders_args, writer.uint32(66).fork()).ldelim();
            if (message.get_last_organization_used_for_payment_args != null && Object.hasOwnProperty.call(message, "get_last_organization_used_for_payment_args"))
                $root.object_msg.OrdersApiRequest.get_last_organization_used_for_payment.encode(message.get_last_organization_used_for_payment_args, writer.uint32(74).fork()).ldelim();
            return writer;
        };

        OrdersApiRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.update_order_by_user_args = $root.object_msg.OrdersApiRequest.update_order_by_user.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.update_delivery_profiles_by_user_args = $root.object_msg.OrdersApiRequest.update_delivery_profiles_by_user.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.reload_delivery_profiles_args = $root.object_msg.OrdersApiRequest.reload_delivery_profiles.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.select_order_args = $root.object_msg.OrdersApiRequest.select_order.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.select_order_stage_args = $root.object_msg.OrdersApiRequest.select_order_stage.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.search_orders_args = $root.object_msg.OrdersApiRequest.search_orders.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.get_stage_action_approximate_price_args = $root.object_msg.OrdersApiRequest.get_stage_action_approximate_price.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.search_incoming_orders_args = $root.object_msg.OrdersApiRequest.search_incoming_orders.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.get_last_organization_used_for_payment_args = $root.object_msg.OrdersApiRequest.get_last_organization_used_for_payment.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrdersApiRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrdersApiRequest)
                return object;
            let message = new $root.object_msg.OrdersApiRequest();
            if (object.update_order_by_user_args != null) {
                if (typeof object.update_order_by_user_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.update_order_by_user_args: object expected");
                message.update_order_by_user_args = $root.object_msg.OrdersApiRequest.update_order_by_user.fromObject(object.update_order_by_user_args);
            }
            if (object.update_delivery_profiles_by_user_args != null) {
                if (typeof object.update_delivery_profiles_by_user_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.update_delivery_profiles_by_user_args: object expected");
                message.update_delivery_profiles_by_user_args = $root.object_msg.OrdersApiRequest.update_delivery_profiles_by_user.fromObject(object.update_delivery_profiles_by_user_args);
            }
            if (object.reload_delivery_profiles_args != null) {
                if (typeof object.reload_delivery_profiles_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.reload_delivery_profiles_args: object expected");
                message.reload_delivery_profiles_args = $root.object_msg.OrdersApiRequest.reload_delivery_profiles.fromObject(object.reload_delivery_profiles_args);
            }
            if (object.select_order_args != null) {
                if (typeof object.select_order_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.select_order_args: object expected");
                message.select_order_args = $root.object_msg.OrdersApiRequest.select_order.fromObject(object.select_order_args);
            }
            if (object.select_order_stage_args != null) {
                if (typeof object.select_order_stage_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.select_order_stage_args: object expected");
                message.select_order_stage_args = $root.object_msg.OrdersApiRequest.select_order_stage.fromObject(object.select_order_stage_args);
            }
            if (object.search_orders_args != null) {
                if (typeof object.search_orders_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.search_orders_args: object expected");
                message.search_orders_args = $root.object_msg.OrdersApiRequest.search_orders.fromObject(object.search_orders_args);
            }
            if (object.get_stage_action_approximate_price_args != null) {
                if (typeof object.get_stage_action_approximate_price_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.get_stage_action_approximate_price_args: object expected");
                message.get_stage_action_approximate_price_args = $root.object_msg.OrdersApiRequest.get_stage_action_approximate_price.fromObject(object.get_stage_action_approximate_price_args);
            }
            if (object.search_incoming_orders_args != null) {
                if (typeof object.search_incoming_orders_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.search_incoming_orders_args: object expected");
                message.search_incoming_orders_args = $root.object_msg.OrdersApiRequest.search_incoming_orders.fromObject(object.search_incoming_orders_args);
            }
            if (object.get_last_organization_used_for_payment_args != null) {
                if (typeof object.get_last_organization_used_for_payment_args !== "object")
                    throw TypeError(".object_msg.OrdersApiRequest.get_last_organization_used_for_payment_args: object expected");
                message.get_last_organization_used_for_payment_args = $root.object_msg.OrdersApiRequest.get_last_organization_used_for_payment.fromObject(object.get_last_organization_used_for_payment_args);
            }
            return message;
        };

        OrdersApiRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.update_order_by_user_args != null && message.hasOwnProperty("update_order_by_user_args")) {
                object.update_order_by_user_args = $root.object_msg.OrdersApiRequest.update_order_by_user.toObject(message.update_order_by_user_args, options);
                if (options.oneofs)
                    object.args = "update_order_by_user_args";
            }
            if (message.update_delivery_profiles_by_user_args != null && message.hasOwnProperty("update_delivery_profiles_by_user_args")) {
                object.update_delivery_profiles_by_user_args = $root.object_msg.OrdersApiRequest.update_delivery_profiles_by_user.toObject(message.update_delivery_profiles_by_user_args, options);
                if (options.oneofs)
                    object.args = "update_delivery_profiles_by_user_args";
            }
            if (message.reload_delivery_profiles_args != null && message.hasOwnProperty("reload_delivery_profiles_args")) {
                object.reload_delivery_profiles_args = $root.object_msg.OrdersApiRequest.reload_delivery_profiles.toObject(message.reload_delivery_profiles_args, options);
                if (options.oneofs)
                    object.args = "reload_delivery_profiles_args";
            }
            if (message.select_order_args != null && message.hasOwnProperty("select_order_args")) {
                object.select_order_args = $root.object_msg.OrdersApiRequest.select_order.toObject(message.select_order_args, options);
                if (options.oneofs)
                    object.args = "select_order_args";
            }
            if (message.select_order_stage_args != null && message.hasOwnProperty("select_order_stage_args")) {
                object.select_order_stage_args = $root.object_msg.OrdersApiRequest.select_order_stage.toObject(message.select_order_stage_args, options);
                if (options.oneofs)
                    object.args = "select_order_stage_args";
            }
            if (message.search_orders_args != null && message.hasOwnProperty("search_orders_args")) {
                object.search_orders_args = $root.object_msg.OrdersApiRequest.search_orders.toObject(message.search_orders_args, options);
                if (options.oneofs)
                    object.args = "search_orders_args";
            }
            if (message.get_stage_action_approximate_price_args != null && message.hasOwnProperty("get_stage_action_approximate_price_args")) {
                object.get_stage_action_approximate_price_args = $root.object_msg.OrdersApiRequest.get_stage_action_approximate_price.toObject(message.get_stage_action_approximate_price_args, options);
                if (options.oneofs)
                    object.args = "get_stage_action_approximate_price_args";
            }
            if (message.search_incoming_orders_args != null && message.hasOwnProperty("search_incoming_orders_args")) {
                object.search_incoming_orders_args = $root.object_msg.OrdersApiRequest.search_incoming_orders.toObject(message.search_incoming_orders_args, options);
                if (options.oneofs)
                    object.args = "search_incoming_orders_args";
            }
            if (message.get_last_organization_used_for_payment_args != null && message.hasOwnProperty("get_last_organization_used_for_payment_args")) {
                object.get_last_organization_used_for_payment_args = $root.object_msg.OrdersApiRequest.get_last_organization_used_for_payment.toObject(message.get_last_organization_used_for_payment_args, options);
                if (options.oneofs)
                    object.args = "get_last_organization_used_for_payment_args";
            }
            return object;
        };

        OrdersApiRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrdersApiRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrdersApiRequest";
        };

        OrdersApiRequest.Method = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "unknownMethod"] = 0;
            values[valuesById[1] = "updateOrderByUser"] = 1;
            values[valuesById[2] = "updateDeliveryProfilesByUser"] = 2;
            values[valuesById[3] = "reloadDeliveryProfiles"] = 3;
            values[valuesById[4] = "selectOrder"] = 4;
            values[valuesById[5] = "selectOrderStage"] = 5;
            values[valuesById[6] = "searchOrders"] = 6;
            values[valuesById[7] = "getStageActionApproximatePrice"] = 7;
            values[valuesById[8] = "searchIncomingOrders"] = 8;
            values[valuesById[9] = "getLastOrganizationUsedForPayment"] = 9;
            return values;
        })();

        OrdersApiRequest.update_order_by_user = (function() {

            function update_order_by_user(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            update_order_by_user.prototype.orders = $util.newBuffer([]);
            update_order_by_user.prototype.order_services = $util.newBuffer([]);
            update_order_by_user.prototype.order_comments = $util.newBuffer([]);
            update_order_by_user.prototype.order_comment_files = $util.newBuffer([]);
            update_order_by_user.prototype.order_stages = $util.newBuffer([]);
            update_order_by_user.prototype.order_stage_comments = $util.newBuffer([]);
            update_order_by_user.prototype.order_stage_comment_files = $util.newBuffer([]);
            update_order_by_user.prototype.laser_cutting_stage = $util.newBuffer([]);
            update_order_by_user.prototype.laser_engraving_stage = $util.newBuffer([]);
            update_order_by_user.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            update_order_by_user.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orders != null && Object.hasOwnProperty.call(message, "orders"))
                    writer.uint32(10).bytes(message.orders);
                if (message.order_services != null && Object.hasOwnProperty.call(message, "order_services"))
                    writer.uint32(18).bytes(message.order_services);
                if (message.order_comments != null && Object.hasOwnProperty.call(message, "order_comments"))
                    writer.uint32(26).bytes(message.order_comments);
                if (message.order_comment_files != null && Object.hasOwnProperty.call(message, "order_comment_files"))
                    writer.uint32(34).bytes(message.order_comment_files);
                if (message.order_stages != null && Object.hasOwnProperty.call(message, "order_stages"))
                    writer.uint32(42).bytes(message.order_stages);
                if (message.order_stage_comments != null && Object.hasOwnProperty.call(message, "order_stage_comments"))
                    writer.uint32(50).bytes(message.order_stage_comments);
                if (message.order_stage_comment_files != null && Object.hasOwnProperty.call(message, "order_stage_comment_files"))
                    writer.uint32(58).bytes(message.order_stage_comment_files);
                if (message.laser_cutting_stage != null && Object.hasOwnProperty.call(message, "laser_cutting_stage"))
                    writer.uint32(66).bytes(message.laser_cutting_stage);
                if (message.laser_engraving_stage != null && Object.hasOwnProperty.call(message, "laser_engraving_stage"))
                    writer.uint32(74).bytes(message.laser_engraving_stage);
                if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                    writer.uint32(80).uint64(message.order_id);
                return writer;
            };

            update_order_by_user.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.update_order_by_user();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.orders = reader.bytes();
                            break;
                        }
                    case 2: {
                            message.order_services = reader.bytes();
                            break;
                        }
                    case 3: {
                            message.order_comments = reader.bytes();
                            break;
                        }
                    case 4: {
                            message.order_comment_files = reader.bytes();
                            break;
                        }
                    case 5: {
                            message.order_stages = reader.bytes();
                            break;
                        }
                    case 6: {
                            message.order_stage_comments = reader.bytes();
                            break;
                        }
                    case 7: {
                            message.order_stage_comment_files = reader.bytes();
                            break;
                        }
                    case 8: {
                            message.laser_cutting_stage = reader.bytes();
                            break;
                        }
                    case 9: {
                            message.laser_engraving_stage = reader.bytes();
                            break;
                        }
                    case 10: {
                            message.order_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            update_order_by_user.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.update_order_by_user)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.update_order_by_user();
                if (object.orders != null)
                    if (typeof object.orders === "string")
                        $util.base64.decode(object.orders, message.orders = $util.newBuffer($util.base64.length(object.orders)), 0);
                    else if (object.orders.length >= 0)
                        message.orders = object.orders;
                if (object.order_services != null)
                    if (typeof object.order_services === "string")
                        $util.base64.decode(object.order_services, message.order_services = $util.newBuffer($util.base64.length(object.order_services)), 0);
                    else if (object.order_services.length >= 0)
                        message.order_services = object.order_services;
                if (object.order_comments != null)
                    if (typeof object.order_comments === "string")
                        $util.base64.decode(object.order_comments, message.order_comments = $util.newBuffer($util.base64.length(object.order_comments)), 0);
                    else if (object.order_comments.length >= 0)
                        message.order_comments = object.order_comments;
                if (object.order_comment_files != null)
                    if (typeof object.order_comment_files === "string")
                        $util.base64.decode(object.order_comment_files, message.order_comment_files = $util.newBuffer($util.base64.length(object.order_comment_files)), 0);
                    else if (object.order_comment_files.length >= 0)
                        message.order_comment_files = object.order_comment_files;
                if (object.order_stages != null)
                    if (typeof object.order_stages === "string")
                        $util.base64.decode(object.order_stages, message.order_stages = $util.newBuffer($util.base64.length(object.order_stages)), 0);
                    else if (object.order_stages.length >= 0)
                        message.order_stages = object.order_stages;
                if (object.order_stage_comments != null)
                    if (typeof object.order_stage_comments === "string")
                        $util.base64.decode(object.order_stage_comments, message.order_stage_comments = $util.newBuffer($util.base64.length(object.order_stage_comments)), 0);
                    else if (object.order_stage_comments.length >= 0)
                        message.order_stage_comments = object.order_stage_comments;
                if (object.order_stage_comment_files != null)
                    if (typeof object.order_stage_comment_files === "string")
                        $util.base64.decode(object.order_stage_comment_files, message.order_stage_comment_files = $util.newBuffer($util.base64.length(object.order_stage_comment_files)), 0);
                    else if (object.order_stage_comment_files.length >= 0)
                        message.order_stage_comment_files = object.order_stage_comment_files;
                if (object.laser_cutting_stage != null)
                    if (typeof object.laser_cutting_stage === "string")
                        $util.base64.decode(object.laser_cutting_stage, message.laser_cutting_stage = $util.newBuffer($util.base64.length(object.laser_cutting_stage)), 0);
                    else if (object.laser_cutting_stage.length >= 0)
                        message.laser_cutting_stage = object.laser_cutting_stage;
                if (object.laser_engraving_stage != null)
                    if (typeof object.laser_engraving_stage === "string")
                        $util.base64.decode(object.laser_engraving_stage, message.laser_engraving_stage = $util.newBuffer($util.base64.length(object.laser_engraving_stage)), 0);
                    else if (object.laser_engraving_stage.length >= 0)
                        message.laser_engraving_stage = object.laser_engraving_stage;
                if (object.order_id != null)
                    if ($util.Long)
                        (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                    else if (typeof object.order_id === "string")
                        message.order_id = parseInt(object.order_id, 10);
                    else if (typeof object.order_id === "number")
                        message.order_id = object.order_id;
                    else if (typeof object.order_id === "object")
                        message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
                return message;
            };

            update_order_by_user.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.orders = "";
                    else {
                        object.orders = [];
                        if (options.bytes !== Array)
                            object.orders = $util.newBuffer(object.orders);
                    }
                    if (options.bytes === String)
                        object.order_services = "";
                    else {
                        object.order_services = [];
                        if (options.bytes !== Array)
                            object.order_services = $util.newBuffer(object.order_services);
                    }
                    if (options.bytes === String)
                        object.order_comments = "";
                    else {
                        object.order_comments = [];
                        if (options.bytes !== Array)
                            object.order_comments = $util.newBuffer(object.order_comments);
                    }
                    if (options.bytes === String)
                        object.order_comment_files = "";
                    else {
                        object.order_comment_files = [];
                        if (options.bytes !== Array)
                            object.order_comment_files = $util.newBuffer(object.order_comment_files);
                    }
                    if (options.bytes === String)
                        object.order_stages = "";
                    else {
                        object.order_stages = [];
                        if (options.bytes !== Array)
                            object.order_stages = $util.newBuffer(object.order_stages);
                    }
                    if (options.bytes === String)
                        object.order_stage_comments = "";
                    else {
                        object.order_stage_comments = [];
                        if (options.bytes !== Array)
                            object.order_stage_comments = $util.newBuffer(object.order_stage_comments);
                    }
                    if (options.bytes === String)
                        object.order_stage_comment_files = "";
                    else {
                        object.order_stage_comment_files = [];
                        if (options.bytes !== Array)
                            object.order_stage_comment_files = $util.newBuffer(object.order_stage_comment_files);
                    }
                    if (options.bytes === String)
                        object.laser_cutting_stage = "";
                    else {
                        object.laser_cutting_stage = [];
                        if (options.bytes !== Array)
                            object.laser_cutting_stage = $util.newBuffer(object.laser_cutting_stage);
                    }
                    if (options.bytes === String)
                        object.laser_engraving_stage = "";
                    else {
                        object.laser_engraving_stage = [];
                        if (options.bytes !== Array)
                            object.laser_engraving_stage = $util.newBuffer(object.laser_engraving_stage);
                    }
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.order_id = options.longs === String ? "0" : 0;
                }
                if (message.orders != null && message.hasOwnProperty("orders"))
                    object.orders = options.bytes === String ? $util.base64.encode(message.orders, 0, message.orders.length) : options.bytes === Array ? Array.prototype.slice.call(message.orders) : message.orders;
                if (message.order_services != null && message.hasOwnProperty("order_services"))
                    object.order_services = options.bytes === String ? $util.base64.encode(message.order_services, 0, message.order_services.length) : options.bytes === Array ? Array.prototype.slice.call(message.order_services) : message.order_services;
                if (message.order_comments != null && message.hasOwnProperty("order_comments"))
                    object.order_comments = options.bytes === String ? $util.base64.encode(message.order_comments, 0, message.order_comments.length) : options.bytes === Array ? Array.prototype.slice.call(message.order_comments) : message.order_comments;
                if (message.order_comment_files != null && message.hasOwnProperty("order_comment_files"))
                    object.order_comment_files = options.bytes === String ? $util.base64.encode(message.order_comment_files, 0, message.order_comment_files.length) : options.bytes === Array ? Array.prototype.slice.call(message.order_comment_files) : message.order_comment_files;
                if (message.order_stages != null && message.hasOwnProperty("order_stages"))
                    object.order_stages = options.bytes === String ? $util.base64.encode(message.order_stages, 0, message.order_stages.length) : options.bytes === Array ? Array.prototype.slice.call(message.order_stages) : message.order_stages;
                if (message.order_stage_comments != null && message.hasOwnProperty("order_stage_comments"))
                    object.order_stage_comments = options.bytes === String ? $util.base64.encode(message.order_stage_comments, 0, message.order_stage_comments.length) : options.bytes === Array ? Array.prototype.slice.call(message.order_stage_comments) : message.order_stage_comments;
                if (message.order_stage_comment_files != null && message.hasOwnProperty("order_stage_comment_files"))
                    object.order_stage_comment_files = options.bytes === String ? $util.base64.encode(message.order_stage_comment_files, 0, message.order_stage_comment_files.length) : options.bytes === Array ? Array.prototype.slice.call(message.order_stage_comment_files) : message.order_stage_comment_files;
                if (message.laser_cutting_stage != null && message.hasOwnProperty("laser_cutting_stage"))
                    object.laser_cutting_stage = options.bytes === String ? $util.base64.encode(message.laser_cutting_stage, 0, message.laser_cutting_stage.length) : options.bytes === Array ? Array.prototype.slice.call(message.laser_cutting_stage) : message.laser_cutting_stage;
                if (message.laser_engraving_stage != null && message.hasOwnProperty("laser_engraving_stage"))
                    object.laser_engraving_stage = options.bytes === String ? $util.base64.encode(message.laser_engraving_stage, 0, message.laser_engraving_stage.length) : options.bytes === Array ? Array.prototype.slice.call(message.laser_engraving_stage) : message.laser_engraving_stage;
                if (message.order_id != null && message.hasOwnProperty("order_id"))
                    if (typeof message.order_id === "number")
                        object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                    else
                        object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
                return object;
            };

            update_order_by_user.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            update_order_by_user.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.update_order_by_user";
            };

            return update_order_by_user;
        })();

        OrdersApiRequest.update_delivery_profiles_by_user = (function() {

            function update_delivery_profiles_by_user(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            update_delivery_profiles_by_user.prototype.profiles = $util.newBuffer([]);

            update_delivery_profiles_by_user.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.profiles != null && Object.hasOwnProperty.call(message, "profiles"))
                    writer.uint32(10).bytes(message.profiles);
                return writer;
            };

            update_delivery_profiles_by_user.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.update_delivery_profiles_by_user();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.profiles = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            update_delivery_profiles_by_user.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.update_delivery_profiles_by_user)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.update_delivery_profiles_by_user();
                if (object.profiles != null)
                    if (typeof object.profiles === "string")
                        $util.base64.decode(object.profiles, message.profiles = $util.newBuffer($util.base64.length(object.profiles)), 0);
                    else if (object.profiles.length >= 0)
                        message.profiles = object.profiles;
                return message;
            };

            update_delivery_profiles_by_user.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if (options.bytes === String)
                        object.profiles = "";
                    else {
                        object.profiles = [];
                        if (options.bytes !== Array)
                            object.profiles = $util.newBuffer(object.profiles);
                    }
                if (message.profiles != null && message.hasOwnProperty("profiles"))
                    object.profiles = options.bytes === String ? $util.base64.encode(message.profiles, 0, message.profiles.length) : options.bytes === Array ? Array.prototype.slice.call(message.profiles) : message.profiles;
                return object;
            };

            update_delivery_profiles_by_user.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            update_delivery_profiles_by_user.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.update_delivery_profiles_by_user";
            };

            return update_delivery_profiles_by_user;
        })();

        OrdersApiRequest.reload_delivery_profiles = (function() {

            function reload_delivery_profiles(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            reload_delivery_profiles.prototype.auto_update = false;
            reload_delivery_profiles.prototype.unload = false;

            reload_delivery_profiles.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.auto_update != null && Object.hasOwnProperty.call(message, "auto_update"))
                    writer.uint32(8).bool(message.auto_update);
                if (message.unload != null && Object.hasOwnProperty.call(message, "unload"))
                    writer.uint32(16).bool(message.unload);
                return writer;
            };

            reload_delivery_profiles.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.reload_delivery_profiles();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.auto_update = reader.bool();
                            break;
                        }
                    case 2: {
                            message.unload = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            reload_delivery_profiles.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.reload_delivery_profiles)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.reload_delivery_profiles();
                if (object.auto_update != null)
                    message.auto_update = Boolean(object.auto_update);
                if (object.unload != null)
                    message.unload = Boolean(object.unload);
                return message;
            };

            reload_delivery_profiles.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.auto_update = false;
                    object.unload = false;
                }
                if (message.auto_update != null && message.hasOwnProperty("auto_update"))
                    object.auto_update = message.auto_update;
                if (message.unload != null && message.hasOwnProperty("unload"))
                    object.unload = message.unload;
                return object;
            };

            reload_delivery_profiles.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            reload_delivery_profiles.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.reload_delivery_profiles";
            };

            return reload_delivery_profiles;
        })();

        OrdersApiRequest.select_order = (function() {

            function select_order(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            select_order.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            select_order.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                    writer.uint32(8).uint64(message.order_id);
                return writer;
            };

            select_order.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.select_order();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.order_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            select_order.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.select_order)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.select_order();
                if (object.order_id != null)
                    if ($util.Long)
                        (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                    else if (typeof object.order_id === "string")
                        message.order_id = parseInt(object.order_id, 10);
                    else if (typeof object.order_id === "number")
                        message.order_id = object.order_id;
                    else if (typeof object.order_id === "object")
                        message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
                return message;
            };

            select_order.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.order_id = options.longs === String ? "0" : 0;
                if (message.order_id != null && message.hasOwnProperty("order_id"))
                    if (typeof message.order_id === "number")
                        object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                    else
                        object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
                return object;
            };

            select_order.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            select_order.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.select_order";
            };

            return select_order;
        })();

        OrdersApiRequest.select_order_stage = (function() {

            function select_order_stage(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            select_order_stage.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            select_order_stage.prototype.stage_number = 0;

            select_order_stage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                    writer.uint32(8).uint64(message.order_id);
                if (message.stage_number != null && Object.hasOwnProperty.call(message, "stage_number"))
                    writer.uint32(16).int32(message.stage_number);
                return writer;
            };

            select_order_stage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.select_order_stage();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.order_id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.stage_number = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            select_order_stage.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.select_order_stage)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.select_order_stage();
                if (object.order_id != null)
                    if ($util.Long)
                        (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                    else if (typeof object.order_id === "string")
                        message.order_id = parseInt(object.order_id, 10);
                    else if (typeof object.order_id === "number")
                        message.order_id = object.order_id;
                    else if (typeof object.order_id === "object")
                        message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
                if (object.stage_number != null)
                    message.stage_number = object.stage_number | 0;
                return message;
            };

            select_order_stage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.order_id = options.longs === String ? "0" : 0;
                    object.stage_number = 0;
                }
                if (message.order_id != null && message.hasOwnProperty("order_id"))
                    if (typeof message.order_id === "number")
                        object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                    else
                        object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
                if (message.stage_number != null && message.hasOwnProperty("stage_number"))
                    object.stage_number = message.stage_number;
                return object;
            };

            select_order_stage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            select_order_stage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.select_order_stage";
            };

            return select_order_stage;
        })();

        OrdersApiRequest.search_orders = (function() {

            function search_orders(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            search_orders.prototype.dullarg = false;

            search_orders.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.dullarg != null && Object.hasOwnProperty.call(message, "dullarg"))
                    writer.uint32(8).bool(message.dullarg);
                return writer;
            };

            search_orders.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.search_orders();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.dullarg = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            search_orders.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.search_orders)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.search_orders();
                if (object.dullarg != null)
                    message.dullarg = Boolean(object.dullarg);
                return message;
            };

            search_orders.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.dullarg = false;
                if (message.dullarg != null && message.hasOwnProperty("dullarg"))
                    object.dullarg = message.dullarg;
                return object;
            };

            search_orders.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            search_orders.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.search_orders";
            };

            return search_orders;
        })();

        OrdersApiRequest.get_stage_action_approximate_price = (function() {

            function get_stage_action_approximate_price(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_stage_action_approximate_price.prototype.delivery_profile_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            get_stage_action_approximate_price.prototype.stage_action_data = $util.newBuffer([]);

            get_stage_action_approximate_price.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.delivery_profile_id != null && Object.hasOwnProperty.call(message, "delivery_profile_id"))
                    writer.uint32(8).uint64(message.delivery_profile_id);
                if (message.stage_action_data != null && Object.hasOwnProperty.call(message, "stage_action_data"))
                    writer.uint32(18).bytes(message.stage_action_data);
                return writer;
            };

            get_stage_action_approximate_price.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.get_stage_action_approximate_price();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.delivery_profile_id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.stage_action_data = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_stage_action_approximate_price.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.get_stage_action_approximate_price)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.get_stage_action_approximate_price();
                if (object.delivery_profile_id != null)
                    if ($util.Long)
                        (message.delivery_profile_id = $util.Long.fromValue(object.delivery_profile_id)).unsigned = true;
                    else if (typeof object.delivery_profile_id === "string")
                        message.delivery_profile_id = parseInt(object.delivery_profile_id, 10);
                    else if (typeof object.delivery_profile_id === "number")
                        message.delivery_profile_id = object.delivery_profile_id;
                    else if (typeof object.delivery_profile_id === "object")
                        message.delivery_profile_id = new $util.LongBits(object.delivery_profile_id.low >>> 0, object.delivery_profile_id.high >>> 0).toNumber(true);
                if (object.stage_action_data != null)
                    if (typeof object.stage_action_data === "string")
                        $util.base64.decode(object.stage_action_data, message.stage_action_data = $util.newBuffer($util.base64.length(object.stage_action_data)), 0);
                    else if (object.stage_action_data.length >= 0)
                        message.stage_action_data = object.stage_action_data;
                return message;
            };

            get_stage_action_approximate_price.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.delivery_profile_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.delivery_profile_id = options.longs === String ? "0" : 0;
                    if (options.bytes === String)
                        object.stage_action_data = "";
                    else {
                        object.stage_action_data = [];
                        if (options.bytes !== Array)
                            object.stage_action_data = $util.newBuffer(object.stage_action_data);
                    }
                }
                if (message.delivery_profile_id != null && message.hasOwnProperty("delivery_profile_id"))
                    if (typeof message.delivery_profile_id === "number")
                        object.delivery_profile_id = options.longs === String ? String(message.delivery_profile_id) : message.delivery_profile_id;
                    else
                        object.delivery_profile_id = options.longs === String ? $util.Long.prototype.toString.call(message.delivery_profile_id) : options.longs === Number ? new $util.LongBits(message.delivery_profile_id.low >>> 0, message.delivery_profile_id.high >>> 0).toNumber(true) : message.delivery_profile_id;
                if (message.stage_action_data != null && message.hasOwnProperty("stage_action_data"))
                    object.stage_action_data = options.bytes === String ? $util.base64.encode(message.stage_action_data, 0, message.stage_action_data.length) : options.bytes === Array ? Array.prototype.slice.call(message.stage_action_data) : message.stage_action_data;
                return object;
            };

            get_stage_action_approximate_price.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_stage_action_approximate_price.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.get_stage_action_approximate_price";
            };

            return get_stage_action_approximate_price;
        })();

        OrdersApiRequest.search_incoming_orders = (function() {

            function search_incoming_orders(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            search_incoming_orders.prototype.dullarg = false;

            search_incoming_orders.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.dullarg != null && Object.hasOwnProperty.call(message, "dullarg"))
                    writer.uint32(8).bool(message.dullarg);
                return writer;
            };

            search_incoming_orders.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.search_incoming_orders();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.dullarg = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            search_incoming_orders.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.search_incoming_orders)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.search_incoming_orders();
                if (object.dullarg != null)
                    message.dullarg = Boolean(object.dullarg);
                return message;
            };

            search_incoming_orders.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.dullarg = false;
                if (message.dullarg != null && message.hasOwnProperty("dullarg"))
                    object.dullarg = message.dullarg;
                return object;
            };

            search_incoming_orders.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            search_incoming_orders.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.search_incoming_orders";
            };

            return search_incoming_orders;
        })();

        OrdersApiRequest.get_last_organization_used_for_payment = (function() {

            function get_last_organization_used_for_payment(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_last_organization_used_for_payment.prototype.dullarg = false;

            get_last_organization_used_for_payment.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.dullarg != null && Object.hasOwnProperty.call(message, "dullarg"))
                    writer.uint32(8).bool(message.dullarg);
                return writer;
            };

            get_last_organization_used_for_payment.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrdersApiRequest.get_last_organization_used_for_payment();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.dullarg = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_last_organization_used_for_payment.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrdersApiRequest.get_last_organization_used_for_payment)
                    return object;
                let message = new $root.object_msg.OrdersApiRequest.get_last_organization_used_for_payment();
                if (object.dullarg != null)
                    message.dullarg = Boolean(object.dullarg);
                return message;
            };

            get_last_organization_used_for_payment.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.dullarg = false;
                if (message.dullarg != null && message.hasOwnProperty("dullarg"))
                    object.dullarg = message.dullarg;
                return object;
            };

            get_last_organization_used_for_payment.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_last_organization_used_for_payment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrdersApiRequest.get_last_organization_used_for_payment";
            };

            return get_last_organization_used_for_payment;
        })();

        return OrdersApiRequest;
    })();

    object_msg.search_orders_return_msg = (function() {

        function search_orders_return_msg(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        search_orders_return_msg.prototype.items = $util.emptyArray;

        search_orders_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.object_msg.search_orders_return_msg.ItemWithId.encode(message.items[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        search_orders_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_orders_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.object_msg.search_orders_return_msg.ItemWithId.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        search_orders_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.search_orders_return_msg)
                return object;
            let message = new $root.object_msg.search_orders_return_msg();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".object_msg.search_orders_return_msg.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".object_msg.search_orders_return_msg.items: object expected");
                    message.items[i] = $root.object_msg.search_orders_return_msg.ItemWithId.fromObject(object.items[i]);
                }
            }
            return message;
        };

        search_orders_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.object_msg.search_orders_return_msg.ItemWithId.toObject(message.items[j], options);
            }
            return object;
        };

        search_orders_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        search_orders_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.search_orders_return_msg";
        };

        search_orders_return_msg.ItemWithId = (function() {

            function ItemWithId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            ItemWithId.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            ItemWithId.prototype.creation_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            ItemWithId.prototype.name = "";
            ItemWithId.prototype.status_time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            ItemWithId.prototype.status = "";

            ItemWithId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(8).uint64(message.id);
                if (message.creation_time != null && Object.hasOwnProperty.call(message, "creation_time"))
                    writer.uint32(16).int64(message.creation_time);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(26).string(message.name);
                if (message.status_time != null && Object.hasOwnProperty.call(message, "status_time"))
                    writer.uint32(32).int64(message.status_time);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(42).string(message.status);
                return writer;
            };

            ItemWithId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_orders_return_msg.ItemWithId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.creation_time = reader.int64();
                            break;
                        }
                    case 3: {
                            message.name = reader.string();
                            break;
                        }
                    case 4: {
                            message.status_time = reader.int64();
                            break;
                        }
                    case 5: {
                            message.status = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            ItemWithId.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.search_orders_return_msg.ItemWithId)
                    return object;
                let message = new $root.object_msg.search_orders_return_msg.ItemWithId();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.creation_time != null)
                    if ($util.Long)
                        (message.creation_time = $util.Long.fromValue(object.creation_time)).unsigned = false;
                    else if (typeof object.creation_time === "string")
                        message.creation_time = parseInt(object.creation_time, 10);
                    else if (typeof object.creation_time === "number")
                        message.creation_time = object.creation_time;
                    else if (typeof object.creation_time === "object")
                        message.creation_time = new $util.LongBits(object.creation_time.low >>> 0, object.creation_time.high >>> 0).toNumber();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.status_time != null)
                    if ($util.Long)
                        (message.status_time = $util.Long.fromValue(object.status_time)).unsigned = false;
                    else if (typeof object.status_time === "string")
                        message.status_time = parseInt(object.status_time, 10);
                    else if (typeof object.status_time === "number")
                        message.status_time = object.status_time;
                    else if (typeof object.status_time === "object")
                        message.status_time = new $util.LongBits(object.status_time.low >>> 0, object.status_time.high >>> 0).toNumber();
                if (object.status != null)
                    message.status = String(object.status);
                return message;
            };

            ItemWithId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.creation_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.creation_time = options.longs === String ? "0" : 0;
                    object.name = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.status_time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.status_time = options.longs === String ? "0" : 0;
                    object.status = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.creation_time != null && message.hasOwnProperty("creation_time"))
                    if (typeof message.creation_time === "number")
                        object.creation_time = options.longs === String ? String(message.creation_time) : message.creation_time;
                    else
                        object.creation_time = options.longs === String ? $util.Long.prototype.toString.call(message.creation_time) : options.longs === Number ? new $util.LongBits(message.creation_time.low >>> 0, message.creation_time.high >>> 0).toNumber() : message.creation_time;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.status_time != null && message.hasOwnProperty("status_time"))
                    if (typeof message.status_time === "number")
                        object.status_time = options.longs === String ? String(message.status_time) : message.status_time;
                    else
                        object.status_time = options.longs === String ? $util.Long.prototype.toString.call(message.status_time) : options.longs === Number ? new $util.LongBits(message.status_time.low >>> 0, message.status_time.high >>> 0).toNumber() : message.status_time;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = message.status;
                return object;
            };

            ItemWithId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            ItemWithId.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.search_orders_return_msg.ItemWithId";
            };

            return ItemWithId;
        })();

        return search_orders_return_msg;
    })();

    object_msg.get_stage_action_approximate_price_return_msg = (function() {

        function get_stage_action_approximate_price_return_msg(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        get_stage_action_approximate_price_return_msg.prototype.approximate_price = 0;
        get_stage_action_approximate_price_return_msg.prototype.in_predefined_range = false;

        get_stage_action_approximate_price_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.approximate_price != null && Object.hasOwnProperty.call(message, "approximate_price"))
                writer.uint32(8).int32(message.approximate_price);
            if (message.in_predefined_range != null && Object.hasOwnProperty.call(message, "in_predefined_range"))
                writer.uint32(16).bool(message.in_predefined_range);
            return writer;
        };

        get_stage_action_approximate_price_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_stage_action_approximate_price_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.approximate_price = reader.int32();
                        break;
                    }
                case 2: {
                        message.in_predefined_range = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        get_stage_action_approximate_price_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.get_stage_action_approximate_price_return_msg)
                return object;
            let message = new $root.object_msg.get_stage_action_approximate_price_return_msg();
            if (object.approximate_price != null)
                message.approximate_price = object.approximate_price | 0;
            if (object.in_predefined_range != null)
                message.in_predefined_range = Boolean(object.in_predefined_range);
            return message;
        };

        get_stage_action_approximate_price_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.approximate_price = 0;
                object.in_predefined_range = false;
            }
            if (message.approximate_price != null && message.hasOwnProperty("approximate_price"))
                object.approximate_price = message.approximate_price;
            if (message.in_predefined_range != null && message.hasOwnProperty("in_predefined_range"))
                object.in_predefined_range = message.in_predefined_range;
            return object;
        };

        get_stage_action_approximate_price_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        get_stage_action_approximate_price_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.get_stage_action_approximate_price_return_msg";
        };

        return get_stage_action_approximate_price_return_msg;
    })();

    object_msg.search_incoming_orders_return_msg = (function() {

        function search_incoming_orders_return_msg(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        search_incoming_orders_return_msg.prototype.items = $util.emptyArray;

        search_incoming_orders_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.object_msg.search_incoming_orders_return_msg.ItemWithId.encode(message.items[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        search_incoming_orders_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_incoming_orders_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.object_msg.search_incoming_orders_return_msg.ItemWithId.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        search_incoming_orders_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.search_incoming_orders_return_msg)
                return object;
            let message = new $root.object_msg.search_incoming_orders_return_msg();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".object_msg.search_incoming_orders_return_msg.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".object_msg.search_incoming_orders_return_msg.items: object expected");
                    message.items[i] = $root.object_msg.search_incoming_orders_return_msg.ItemWithId.fromObject(object.items[i]);
                }
            }
            return message;
        };

        search_incoming_orders_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.object_msg.search_incoming_orders_return_msg.ItemWithId.toObject(message.items[j], options);
            }
            return object;
        };

        search_incoming_orders_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        search_incoming_orders_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.search_incoming_orders_return_msg";
        };

        search_incoming_orders_return_msg.ItemWithId = (function() {

            function ItemWithId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            ItemWithId.prototype.order_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            ItemWithId.prototype.stage_number = 0;
            ItemWithId.prototype.name = "";
            ItemWithId.prototype.action = "";
            ItemWithId.prototype.place_name = "";
            ItemWithId.prototype.place_address = "";

            ItemWithId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
                    writer.uint32(8).uint64(message.order_id);
                if (message.stage_number != null && Object.hasOwnProperty.call(message, "stage_number"))
                    writer.uint32(16).int32(message.stage_number);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(26).string(message.name);
                if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                    writer.uint32(34).string(message.action);
                if (message.place_name != null && Object.hasOwnProperty.call(message, "place_name"))
                    writer.uint32(42).string(message.place_name);
                if (message.place_address != null && Object.hasOwnProperty.call(message, "place_address"))
                    writer.uint32(50).string(message.place_address);
                return writer;
            };

            ItemWithId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_incoming_orders_return_msg.ItemWithId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.order_id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.stage_number = reader.int32();
                            break;
                        }
                    case 3: {
                            message.name = reader.string();
                            break;
                        }
                    case 4: {
                            message.action = reader.string();
                            break;
                        }
                    case 5: {
                            message.place_name = reader.string();
                            break;
                        }
                    case 6: {
                            message.place_address = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            ItemWithId.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.search_incoming_orders_return_msg.ItemWithId)
                    return object;
                let message = new $root.object_msg.search_incoming_orders_return_msg.ItemWithId();
                if (object.order_id != null)
                    if ($util.Long)
                        (message.order_id = $util.Long.fromValue(object.order_id)).unsigned = true;
                    else if (typeof object.order_id === "string")
                        message.order_id = parseInt(object.order_id, 10);
                    else if (typeof object.order_id === "number")
                        message.order_id = object.order_id;
                    else if (typeof object.order_id === "object")
                        message.order_id = new $util.LongBits(object.order_id.low >>> 0, object.order_id.high >>> 0).toNumber(true);
                if (object.stage_number != null)
                    message.stage_number = object.stage_number | 0;
                if (object.name != null)
                    message.name = String(object.name);
                if (object.action != null)
                    message.action = String(object.action);
                if (object.place_name != null)
                    message.place_name = String(object.place_name);
                if (object.place_address != null)
                    message.place_address = String(object.place_address);
                return message;
            };

            ItemWithId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.order_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.order_id = options.longs === String ? "0" : 0;
                    object.stage_number = 0;
                    object.name = "";
                    object.action = "";
                    object.place_name = "";
                    object.place_address = "";
                }
                if (message.order_id != null && message.hasOwnProperty("order_id"))
                    if (typeof message.order_id === "number")
                        object.order_id = options.longs === String ? String(message.order_id) : message.order_id;
                    else
                        object.order_id = options.longs === String ? $util.Long.prototype.toString.call(message.order_id) : options.longs === Number ? new $util.LongBits(message.order_id.low >>> 0, message.order_id.high >>> 0).toNumber(true) : message.order_id;
                if (message.stage_number != null && message.hasOwnProperty("stage_number"))
                    object.stage_number = message.stage_number;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.action != null && message.hasOwnProperty("action"))
                    object.action = message.action;
                if (message.place_name != null && message.hasOwnProperty("place_name"))
                    object.place_name = message.place_name;
                if (message.place_address != null && message.hasOwnProperty("place_address"))
                    object.place_address = message.place_address;
                return object;
            };

            ItemWithId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            ItemWithId.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.search_incoming_orders_return_msg.ItemWithId";
            };

            return ItemWithId;
        })();

        return search_incoming_orders_return_msg;
    })();

    object_msg.PlacesApiRequest = (function() {

        function PlacesApiRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        PlacesApiRequest.prototype.update_place_by_user_args = null;
        PlacesApiRequest.prototype.search_user_places_args = null;
        PlacesApiRequest.prototype.select_place_args = null;
        PlacesApiRequest.prototype.reload_laser_cutting_material_thicknesses_args = null;
        PlacesApiRequest.prototype.reload_action_materials_args = null;
        PlacesApiRequest.prototype.does_user_have_place_args = null;

        let $oneOfFields;

        Object.defineProperty(PlacesApiRequest.prototype, "args", {
            get: $util.oneOfGetter($oneOfFields = ["update_place_by_user_args", "search_user_places_args", "select_place_args", "reload_laser_cutting_material_thicknesses_args", "reload_action_materials_args", "does_user_have_place_args"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        PlacesApiRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.update_place_by_user_args != null && Object.hasOwnProperty.call(message, "update_place_by_user_args"))
                $root.object_msg.PlacesApiRequest.update_place_by_user.encode(message.update_place_by_user_args, writer.uint32(10).fork()).ldelim();
            if (message.search_user_places_args != null && Object.hasOwnProperty.call(message, "search_user_places_args"))
                $root.object_msg.PlacesApiRequest.search_user_places.encode(message.search_user_places_args, writer.uint32(18).fork()).ldelim();
            if (message.select_place_args != null && Object.hasOwnProperty.call(message, "select_place_args"))
                $root.object_msg.PlacesApiRequest.select_place.encode(message.select_place_args, writer.uint32(26).fork()).ldelim();
            if (message.reload_laser_cutting_material_thicknesses_args != null && Object.hasOwnProperty.call(message, "reload_laser_cutting_material_thicknesses_args"))
                $root.object_msg.PlacesApiRequest.reload_laser_cutting_material_thicknesses.encode(message.reload_laser_cutting_material_thicknesses_args, writer.uint32(34).fork()).ldelim();
            if (message.reload_action_materials_args != null && Object.hasOwnProperty.call(message, "reload_action_materials_args"))
                $root.object_msg.PlacesApiRequest.reload_action_materials.encode(message.reload_action_materials_args, writer.uint32(42).fork()).ldelim();
            if (message.does_user_have_place_args != null && Object.hasOwnProperty.call(message, "does_user_have_place_args"))
                $root.object_msg.PlacesApiRequest.does_user_have_place.encode(message.does_user_have_place_args, writer.uint32(50).fork()).ldelim();
            return writer;
        };

        PlacesApiRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.update_place_by_user_args = $root.object_msg.PlacesApiRequest.update_place_by_user.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.search_user_places_args = $root.object_msg.PlacesApiRequest.search_user_places.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.select_place_args = $root.object_msg.PlacesApiRequest.select_place.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.reload_laser_cutting_material_thicknesses_args = $root.object_msg.PlacesApiRequest.reload_laser_cutting_material_thicknesses.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.reload_action_materials_args = $root.object_msg.PlacesApiRequest.reload_action_materials.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.does_user_have_place_args = $root.object_msg.PlacesApiRequest.does_user_have_place.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        PlacesApiRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.PlacesApiRequest)
                return object;
            let message = new $root.object_msg.PlacesApiRequest();
            if (object.update_place_by_user_args != null) {
                if (typeof object.update_place_by_user_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.update_place_by_user_args: object expected");
                message.update_place_by_user_args = $root.object_msg.PlacesApiRequest.update_place_by_user.fromObject(object.update_place_by_user_args);
            }
            if (object.search_user_places_args != null) {
                if (typeof object.search_user_places_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.search_user_places_args: object expected");
                message.search_user_places_args = $root.object_msg.PlacesApiRequest.search_user_places.fromObject(object.search_user_places_args);
            }
            if (object.select_place_args != null) {
                if (typeof object.select_place_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.select_place_args: object expected");
                message.select_place_args = $root.object_msg.PlacesApiRequest.select_place.fromObject(object.select_place_args);
            }
            if (object.reload_laser_cutting_material_thicknesses_args != null) {
                if (typeof object.reload_laser_cutting_material_thicknesses_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.reload_laser_cutting_material_thicknesses_args: object expected");
                message.reload_laser_cutting_material_thicknesses_args = $root.object_msg.PlacesApiRequest.reload_laser_cutting_material_thicknesses.fromObject(object.reload_laser_cutting_material_thicknesses_args);
            }
            if (object.reload_action_materials_args != null) {
                if (typeof object.reload_action_materials_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.reload_action_materials_args: object expected");
                message.reload_action_materials_args = $root.object_msg.PlacesApiRequest.reload_action_materials.fromObject(object.reload_action_materials_args);
            }
            if (object.does_user_have_place_args != null) {
                if (typeof object.does_user_have_place_args !== "object")
                    throw TypeError(".object_msg.PlacesApiRequest.does_user_have_place_args: object expected");
                message.does_user_have_place_args = $root.object_msg.PlacesApiRequest.does_user_have_place.fromObject(object.does_user_have_place_args);
            }
            return message;
        };

        PlacesApiRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.update_place_by_user_args != null && message.hasOwnProperty("update_place_by_user_args")) {
                object.update_place_by_user_args = $root.object_msg.PlacesApiRequest.update_place_by_user.toObject(message.update_place_by_user_args, options);
                if (options.oneofs)
                    object.args = "update_place_by_user_args";
            }
            if (message.search_user_places_args != null && message.hasOwnProperty("search_user_places_args")) {
                object.search_user_places_args = $root.object_msg.PlacesApiRequest.search_user_places.toObject(message.search_user_places_args, options);
                if (options.oneofs)
                    object.args = "search_user_places_args";
            }
            if (message.select_place_args != null && message.hasOwnProperty("select_place_args")) {
                object.select_place_args = $root.object_msg.PlacesApiRequest.select_place.toObject(message.select_place_args, options);
                if (options.oneofs)
                    object.args = "select_place_args";
            }
            if (message.reload_laser_cutting_material_thicknesses_args != null && message.hasOwnProperty("reload_laser_cutting_material_thicknesses_args")) {
                object.reload_laser_cutting_material_thicknesses_args = $root.object_msg.PlacesApiRequest.reload_laser_cutting_material_thicknesses.toObject(message.reload_laser_cutting_material_thicknesses_args, options);
                if (options.oneofs)
                    object.args = "reload_laser_cutting_material_thicknesses_args";
            }
            if (message.reload_action_materials_args != null && message.hasOwnProperty("reload_action_materials_args")) {
                object.reload_action_materials_args = $root.object_msg.PlacesApiRequest.reload_action_materials.toObject(message.reload_action_materials_args, options);
                if (options.oneofs)
                    object.args = "reload_action_materials_args";
            }
            if (message.does_user_have_place_args != null && message.hasOwnProperty("does_user_have_place_args")) {
                object.does_user_have_place_args = $root.object_msg.PlacesApiRequest.does_user_have_place.toObject(message.does_user_have_place_args, options);
                if (options.oneofs)
                    object.args = "does_user_have_place_args";
            }
            return object;
        };

        PlacesApiRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PlacesApiRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.PlacesApiRequest";
        };

        PlacesApiRequest.Method = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "unknownMethod"] = 0;
            values[valuesById[1] = "updatePlaceByUser"] = 1;
            values[valuesById[2] = "searchUserPlaces"] = 2;
            values[valuesById[3] = "selectPlace"] = 3;
            values[valuesById[4] = "reloadLaserCuttingMaterialThicknesses"] = 4;
            values[valuesById[5] = "reloadActionMaterials"] = 5;
            values[valuesById[6] = "doesUserHavePlace"] = 6;
            return values;
        })();

        PlacesApiRequest.update_place_by_user = (function() {

            function update_place_by_user(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            update_place_by_user.prototype.place = $util.newBuffer([]);
            update_place_by_user.prototype.place_actions = $util.newBuffer([]);
            update_place_by_user.prototype.laser_cutting_prices = $util.newBuffer([]);
            update_place_by_user.prototype.laser_engraving_prices = $util.newBuffer([]);
            update_place_by_user.prototype.place_phones = $util.newBuffer([]);
            update_place_by_user.prototype.place_emails = $util.newBuffer([]);
            update_place_by_user.prototype.place_machines = $util.newBuffer([]);
            update_place_by_user.prototype.place_labels = $util.newBuffer([]);
            update_place_by_user.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            update_place_by_user.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.place != null && Object.hasOwnProperty.call(message, "place"))
                    writer.uint32(10).bytes(message.place);
                if (message.place_actions != null && Object.hasOwnProperty.call(message, "place_actions"))
                    writer.uint32(18).bytes(message.place_actions);
                if (message.laser_cutting_prices != null && Object.hasOwnProperty.call(message, "laser_cutting_prices"))
                    writer.uint32(26).bytes(message.laser_cutting_prices);
                if (message.laser_engraving_prices != null && Object.hasOwnProperty.call(message, "laser_engraving_prices"))
                    writer.uint32(34).bytes(message.laser_engraving_prices);
                if (message.place_phones != null && Object.hasOwnProperty.call(message, "place_phones"))
                    writer.uint32(42).bytes(message.place_phones);
                if (message.place_emails != null && Object.hasOwnProperty.call(message, "place_emails"))
                    writer.uint32(50).bytes(message.place_emails);
                if (message.place_machines != null && Object.hasOwnProperty.call(message, "place_machines"))
                    writer.uint32(58).bytes(message.place_machines);
                if (message.place_labels != null && Object.hasOwnProperty.call(message, "place_labels"))
                    writer.uint32(66).bytes(message.place_labels);
                if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                    writer.uint32(72).uint64(message.place_id);
                return writer;
            };

            update_place_by_user.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.update_place_by_user();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.place = reader.bytes();
                            break;
                        }
                    case 2: {
                            message.place_actions = reader.bytes();
                            break;
                        }
                    case 3: {
                            message.laser_cutting_prices = reader.bytes();
                            break;
                        }
                    case 4: {
                            message.laser_engraving_prices = reader.bytes();
                            break;
                        }
                    case 5: {
                            message.place_phones = reader.bytes();
                            break;
                        }
                    case 6: {
                            message.place_emails = reader.bytes();
                            break;
                        }
                    case 7: {
                            message.place_machines = reader.bytes();
                            break;
                        }
                    case 8: {
                            message.place_labels = reader.bytes();
                            break;
                        }
                    case 9: {
                            message.place_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            update_place_by_user.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.update_place_by_user)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.update_place_by_user();
                if (object.place != null)
                    if (typeof object.place === "string")
                        $util.base64.decode(object.place, message.place = $util.newBuffer($util.base64.length(object.place)), 0);
                    else if (object.place.length >= 0)
                        message.place = object.place;
                if (object.place_actions != null)
                    if (typeof object.place_actions === "string")
                        $util.base64.decode(object.place_actions, message.place_actions = $util.newBuffer($util.base64.length(object.place_actions)), 0);
                    else if (object.place_actions.length >= 0)
                        message.place_actions = object.place_actions;
                if (object.laser_cutting_prices != null)
                    if (typeof object.laser_cutting_prices === "string")
                        $util.base64.decode(object.laser_cutting_prices, message.laser_cutting_prices = $util.newBuffer($util.base64.length(object.laser_cutting_prices)), 0);
                    else if (object.laser_cutting_prices.length >= 0)
                        message.laser_cutting_prices = object.laser_cutting_prices;
                if (object.laser_engraving_prices != null)
                    if (typeof object.laser_engraving_prices === "string")
                        $util.base64.decode(object.laser_engraving_prices, message.laser_engraving_prices = $util.newBuffer($util.base64.length(object.laser_engraving_prices)), 0);
                    else if (object.laser_engraving_prices.length >= 0)
                        message.laser_engraving_prices = object.laser_engraving_prices;
                if (object.place_phones != null)
                    if (typeof object.place_phones === "string")
                        $util.base64.decode(object.place_phones, message.place_phones = $util.newBuffer($util.base64.length(object.place_phones)), 0);
                    else if (object.place_phones.length >= 0)
                        message.place_phones = object.place_phones;
                if (object.place_emails != null)
                    if (typeof object.place_emails === "string")
                        $util.base64.decode(object.place_emails, message.place_emails = $util.newBuffer($util.base64.length(object.place_emails)), 0);
                    else if (object.place_emails.length >= 0)
                        message.place_emails = object.place_emails;
                if (object.place_machines != null)
                    if (typeof object.place_machines === "string")
                        $util.base64.decode(object.place_machines, message.place_machines = $util.newBuffer($util.base64.length(object.place_machines)), 0);
                    else if (object.place_machines.length >= 0)
                        message.place_machines = object.place_machines;
                if (object.place_labels != null)
                    if (typeof object.place_labels === "string")
                        $util.base64.decode(object.place_labels, message.place_labels = $util.newBuffer($util.base64.length(object.place_labels)), 0);
                    else if (object.place_labels.length >= 0)
                        message.place_labels = object.place_labels;
                if (object.place_id != null)
                    if ($util.Long)
                        (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                    else if (typeof object.place_id === "string")
                        message.place_id = parseInt(object.place_id, 10);
                    else if (typeof object.place_id === "number")
                        message.place_id = object.place_id;
                    else if (typeof object.place_id === "object")
                        message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
                return message;
            };

            update_place_by_user.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.place = "";
                    else {
                        object.place = [];
                        if (options.bytes !== Array)
                            object.place = $util.newBuffer(object.place);
                    }
                    if (options.bytes === String)
                        object.place_actions = "";
                    else {
                        object.place_actions = [];
                        if (options.bytes !== Array)
                            object.place_actions = $util.newBuffer(object.place_actions);
                    }
                    if (options.bytes === String)
                        object.laser_cutting_prices = "";
                    else {
                        object.laser_cutting_prices = [];
                        if (options.bytes !== Array)
                            object.laser_cutting_prices = $util.newBuffer(object.laser_cutting_prices);
                    }
                    if (options.bytes === String)
                        object.laser_engraving_prices = "";
                    else {
                        object.laser_engraving_prices = [];
                        if (options.bytes !== Array)
                            object.laser_engraving_prices = $util.newBuffer(object.laser_engraving_prices);
                    }
                    if (options.bytes === String)
                        object.place_phones = "";
                    else {
                        object.place_phones = [];
                        if (options.bytes !== Array)
                            object.place_phones = $util.newBuffer(object.place_phones);
                    }
                    if (options.bytes === String)
                        object.place_emails = "";
                    else {
                        object.place_emails = [];
                        if (options.bytes !== Array)
                            object.place_emails = $util.newBuffer(object.place_emails);
                    }
                    if (options.bytes === String)
                        object.place_machines = "";
                    else {
                        object.place_machines = [];
                        if (options.bytes !== Array)
                            object.place_machines = $util.newBuffer(object.place_machines);
                    }
                    if (options.bytes === String)
                        object.place_labels = "";
                    else {
                        object.place_labels = [];
                        if (options.bytes !== Array)
                            object.place_labels = $util.newBuffer(object.place_labels);
                    }
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.place_id = options.longs === String ? "0" : 0;
                }
                if (message.place != null && message.hasOwnProperty("place"))
                    object.place = options.bytes === String ? $util.base64.encode(message.place, 0, message.place.length) : options.bytes === Array ? Array.prototype.slice.call(message.place) : message.place;
                if (message.place_actions != null && message.hasOwnProperty("place_actions"))
                    object.place_actions = options.bytes === String ? $util.base64.encode(message.place_actions, 0, message.place_actions.length) : options.bytes === Array ? Array.prototype.slice.call(message.place_actions) : message.place_actions;
                if (message.laser_cutting_prices != null && message.hasOwnProperty("laser_cutting_prices"))
                    object.laser_cutting_prices = options.bytes === String ? $util.base64.encode(message.laser_cutting_prices, 0, message.laser_cutting_prices.length) : options.bytes === Array ? Array.prototype.slice.call(message.laser_cutting_prices) : message.laser_cutting_prices;
                if (message.laser_engraving_prices != null && message.hasOwnProperty("laser_engraving_prices"))
                    object.laser_engraving_prices = options.bytes === String ? $util.base64.encode(message.laser_engraving_prices, 0, message.laser_engraving_prices.length) : options.bytes === Array ? Array.prototype.slice.call(message.laser_engraving_prices) : message.laser_engraving_prices;
                if (message.place_phones != null && message.hasOwnProperty("place_phones"))
                    object.place_phones = options.bytes === String ? $util.base64.encode(message.place_phones, 0, message.place_phones.length) : options.bytes === Array ? Array.prototype.slice.call(message.place_phones) : message.place_phones;
                if (message.place_emails != null && message.hasOwnProperty("place_emails"))
                    object.place_emails = options.bytes === String ? $util.base64.encode(message.place_emails, 0, message.place_emails.length) : options.bytes === Array ? Array.prototype.slice.call(message.place_emails) : message.place_emails;
                if (message.place_machines != null && message.hasOwnProperty("place_machines"))
                    object.place_machines = options.bytes === String ? $util.base64.encode(message.place_machines, 0, message.place_machines.length) : options.bytes === Array ? Array.prototype.slice.call(message.place_machines) : message.place_machines;
                if (message.place_labels != null && message.hasOwnProperty("place_labels"))
                    object.place_labels = options.bytes === String ? $util.base64.encode(message.place_labels, 0, message.place_labels.length) : options.bytes === Array ? Array.prototype.slice.call(message.place_labels) : message.place_labels;
                if (message.place_id != null && message.hasOwnProperty("place_id"))
                    if (typeof message.place_id === "number")
                        object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                    else
                        object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
                return object;
            };

            update_place_by_user.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            update_place_by_user.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.update_place_by_user";
            };

            return update_place_by_user;
        })();

        PlacesApiRequest.search_user_places = (function() {

            function search_user_places(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            search_user_places.prototype.dull_arg = false;

            search_user_places.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.dull_arg != null && Object.hasOwnProperty.call(message, "dull_arg"))
                    writer.uint32(8).bool(message.dull_arg);
                return writer;
            };

            search_user_places.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.search_user_places();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.dull_arg = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            search_user_places.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.search_user_places)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.search_user_places();
                if (object.dull_arg != null)
                    message.dull_arg = Boolean(object.dull_arg);
                return message;
            };

            search_user_places.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.dull_arg = false;
                if (message.dull_arg != null && message.hasOwnProperty("dull_arg"))
                    object.dull_arg = message.dull_arg;
                return object;
            };

            search_user_places.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            search_user_places.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.search_user_places";
            };

            return search_user_places;
        })();

        PlacesApiRequest.select_place = (function() {

            function select_place(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            select_place.prototype.place_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            select_place.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.place_id != null && Object.hasOwnProperty.call(message, "place_id"))
                    writer.uint32(8).uint64(message.place_id);
                return writer;
            };

            select_place.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.select_place();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.place_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            select_place.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.select_place)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.select_place();
                if (object.place_id != null)
                    if ($util.Long)
                        (message.place_id = $util.Long.fromValue(object.place_id)).unsigned = true;
                    else if (typeof object.place_id === "string")
                        message.place_id = parseInt(object.place_id, 10);
                    else if (typeof object.place_id === "number")
                        message.place_id = object.place_id;
                    else if (typeof object.place_id === "object")
                        message.place_id = new $util.LongBits(object.place_id.low >>> 0, object.place_id.high >>> 0).toNumber(true);
                return message;
            };

            select_place.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.place_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.place_id = options.longs === String ? "0" : 0;
                if (message.place_id != null && message.hasOwnProperty("place_id"))
                    if (typeof message.place_id === "number")
                        object.place_id = options.longs === String ? String(message.place_id) : message.place_id;
                    else
                        object.place_id = options.longs === String ? $util.Long.prototype.toString.call(message.place_id) : options.longs === Number ? new $util.LongBits(message.place_id.low >>> 0, message.place_id.high >>> 0).toNumber(true) : message.place_id;
                return object;
            };

            select_place.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            select_place.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.select_place";
            };

            return select_place;
        })();

        PlacesApiRequest.reload_laser_cutting_material_thicknesses = (function() {

            function reload_laser_cutting_material_thicknesses(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            reload_laser_cutting_material_thicknesses.prototype.material = "";
            reload_laser_cutting_material_thicknesses.prototype.auto_update = false;
            reload_laser_cutting_material_thicknesses.prototype.unload_model = false;

            reload_laser_cutting_material_thicknesses.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.material != null && Object.hasOwnProperty.call(message, "material"))
                    writer.uint32(10).string(message.material);
                if (message.auto_update != null && Object.hasOwnProperty.call(message, "auto_update"))
                    writer.uint32(16).bool(message.auto_update);
                if (message.unload_model != null && Object.hasOwnProperty.call(message, "unload_model"))
                    writer.uint32(24).bool(message.unload_model);
                return writer;
            };

            reload_laser_cutting_material_thicknesses.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.reload_laser_cutting_material_thicknesses();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.material = reader.string();
                            break;
                        }
                    case 2: {
                            message.auto_update = reader.bool();
                            break;
                        }
                    case 3: {
                            message.unload_model = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            reload_laser_cutting_material_thicknesses.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.reload_laser_cutting_material_thicknesses)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.reload_laser_cutting_material_thicknesses();
                if (object.material != null)
                    message.material = String(object.material);
                if (object.auto_update != null)
                    message.auto_update = Boolean(object.auto_update);
                if (object.unload_model != null)
                    message.unload_model = Boolean(object.unload_model);
                return message;
            };

            reload_laser_cutting_material_thicknesses.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.material = "";
                    object.auto_update = false;
                    object.unload_model = false;
                }
                if (message.material != null && message.hasOwnProperty("material"))
                    object.material = message.material;
                if (message.auto_update != null && message.hasOwnProperty("auto_update"))
                    object.auto_update = message.auto_update;
                if (message.unload_model != null && message.hasOwnProperty("unload_model"))
                    object.unload_model = message.unload_model;
                return object;
            };

            reload_laser_cutting_material_thicknesses.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            reload_laser_cutting_material_thicknesses.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.reload_laser_cutting_material_thicknesses";
            };

            return reload_laser_cutting_material_thicknesses;
        })();

        PlacesApiRequest.reload_action_materials = (function() {

            function reload_action_materials(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            reload_action_materials.prototype.action = "";

            reload_action_materials.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                    writer.uint32(10).string(message.action);
                return writer;
            };

            reload_action_materials.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.reload_action_materials();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.action = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            reload_action_materials.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.reload_action_materials)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.reload_action_materials();
                if (object.action != null)
                    message.action = String(object.action);
                return message;
            };

            reload_action_materials.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.action = "";
                if (message.action != null && message.hasOwnProperty("action"))
                    object.action = message.action;
                return object;
            };

            reload_action_materials.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            reload_action_materials.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.reload_action_materials";
            };

            return reload_action_materials;
        })();

        PlacesApiRequest.does_user_have_place = (function() {

            function does_user_have_place(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            does_user_have_place.prototype.name = "";
            does_user_have_place.prototype.address = "";

            does_user_have_place.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(10).string(message.name);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(18).string(message.address);
                return writer;
            };

            does_user_have_place.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.PlacesApiRequest.does_user_have_place();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.address = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            does_user_have_place.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.PlacesApiRequest.does_user_have_place)
                    return object;
                let message = new $root.object_msg.PlacesApiRequest.does_user_have_place();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.address != null)
                    message.address = String(object.address);
                return message;
            };

            does_user_have_place.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.address = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                return object;
            };

            does_user_have_place.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            does_user_have_place.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.PlacesApiRequest.does_user_have_place";
            };

            return does_user_have_place;
        })();

        return PlacesApiRequest;
    })();

    object_msg.search_user_places_return_msg = (function() {

        function search_user_places_return_msg(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        search_user_places_return_msg.prototype.items = $util.emptyArray;

        search_user_places_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.object_msg.search_user_places_return_msg.ItemWithId.encode(message.items[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        search_user_places_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_user_places_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.object_msg.search_user_places_return_msg.ItemWithId.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        search_user_places_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.search_user_places_return_msg)
                return object;
            let message = new $root.object_msg.search_user_places_return_msg();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".object_msg.search_user_places_return_msg.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".object_msg.search_user_places_return_msg.items: object expected");
                    message.items[i] = $root.object_msg.search_user_places_return_msg.ItemWithId.fromObject(object.items[i]);
                }
            }
            return message;
        };

        search_user_places_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.object_msg.search_user_places_return_msg.ItemWithId.toObject(message.items[j], options);
            }
            return object;
        };

        search_user_places_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        search_user_places_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.search_user_places_return_msg";
        };

        search_user_places_return_msg.ItemWithId = (function() {

            function ItemWithId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            ItemWithId.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            ItemWithId.prototype.item = "";

            ItemWithId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(8).uint64(message.id);
                if (message.item != null && Object.hasOwnProperty.call(message, "item"))
                    writer.uint32(18).string(message.item);
                return writer;
            };

            ItemWithId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_user_places_return_msg.ItemWithId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.item = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            ItemWithId.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.search_user_places_return_msg.ItemWithId)
                    return object;
                let message = new $root.object_msg.search_user_places_return_msg.ItemWithId();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.item != null)
                    message.item = String(object.item);
                return message;
            };

            ItemWithId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.item = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.item != null && message.hasOwnProperty("item"))
                    object.item = message.item;
                return object;
            };

            ItemWithId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            ItemWithId.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.search_user_places_return_msg.ItemWithId";
            };

            return ItemWithId;
        })();

        return search_user_places_return_msg;
    })();

    object_msg.OrganizationsApiRequest = (function() {

        function OrganizationsApiRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        OrganizationsApiRequest.prototype.update_organization_by_user_args = null;
        OrganizationsApiRequest.prototype.get_or_add_bank_args = null;
        OrganizationsApiRequest.prototype.get_organization_suggestion_args = null;
        OrganizationsApiRequest.prototype.search_organization_args = null;
        OrganizationsApiRequest.prototype.select_organization_args = null;
        OrganizationsApiRequest.prototype.reload_user_organizations_info_args = null;
        OrganizationsApiRequest.prototype.search_user_organizations_args = null;
        OrganizationsApiRequest.prototype.archive_user_organizaton_args = null;
        OrganizationsApiRequest.prototype.does_user_have_organization_args = null;

        let $oneOfFields;

        Object.defineProperty(OrganizationsApiRequest.prototype, "args", {
            get: $util.oneOfGetter($oneOfFields = ["update_organization_by_user_args", "get_or_add_bank_args", "get_organization_suggestion_args", "search_organization_args", "select_organization_args", "reload_user_organizations_info_args", "search_user_organizations_args", "archive_user_organizaton_args", "does_user_have_organization_args"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        OrganizationsApiRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.update_organization_by_user_args != null && Object.hasOwnProperty.call(message, "update_organization_by_user_args"))
                $root.object_msg.OrganizationsApiRequest.update_organization_by_user.encode(message.update_organization_by_user_args, writer.uint32(10).fork()).ldelim();
            if (message.get_or_add_bank_args != null && Object.hasOwnProperty.call(message, "get_or_add_bank_args"))
                $root.object_msg.OrganizationsApiRequest.get_or_add_bank.encode(message.get_or_add_bank_args, writer.uint32(18).fork()).ldelim();
            if (message.get_organization_suggestion_args != null && Object.hasOwnProperty.call(message, "get_organization_suggestion_args"))
                $root.object_msg.OrganizationsApiRequest.get_organization_suggestion.encode(message.get_organization_suggestion_args, writer.uint32(26).fork()).ldelim();
            if (message.search_organization_args != null && Object.hasOwnProperty.call(message, "search_organization_args"))
                $root.object_msg.OrganizationsApiRequest.search_organization.encode(message.search_organization_args, writer.uint32(34).fork()).ldelim();
            if (message.select_organization_args != null && Object.hasOwnProperty.call(message, "select_organization_args"))
                $root.object_msg.OrganizationsApiRequest.select_organization.encode(message.select_organization_args, writer.uint32(42).fork()).ldelim();
            if (message.reload_user_organizations_info_args != null && Object.hasOwnProperty.call(message, "reload_user_organizations_info_args"))
                $root.object_msg.OrganizationsApiRequest.reload_user_organizations_info.encode(message.reload_user_organizations_info_args, writer.uint32(50).fork()).ldelim();
            if (message.search_user_organizations_args != null && Object.hasOwnProperty.call(message, "search_user_organizations_args"))
                $root.object_msg.OrganizationsApiRequest.search_user_organizations.encode(message.search_user_organizations_args, writer.uint32(58).fork()).ldelim();
            if (message.archive_user_organizaton_args != null && Object.hasOwnProperty.call(message, "archive_user_organizaton_args"))
                $root.object_msg.OrganizationsApiRequest.archive_user_organizaton.encode(message.archive_user_organizaton_args, writer.uint32(66).fork()).ldelim();
            if (message.does_user_have_organization_args != null && Object.hasOwnProperty.call(message, "does_user_have_organization_args"))
                $root.object_msg.OrganizationsApiRequest.does_user_have_organization.encode(message.does_user_have_organization_args, writer.uint32(74).fork()).ldelim();
            return writer;
        };

        OrganizationsApiRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationsApiRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.update_organization_by_user_args = $root.object_msg.OrganizationsApiRequest.update_organization_by_user.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.get_or_add_bank_args = $root.object_msg.OrganizationsApiRequest.get_or_add_bank.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.get_organization_suggestion_args = $root.object_msg.OrganizationsApiRequest.get_organization_suggestion.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.search_organization_args = $root.object_msg.OrganizationsApiRequest.search_organization.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.select_organization_args = $root.object_msg.OrganizationsApiRequest.select_organization.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.reload_user_organizations_info_args = $root.object_msg.OrganizationsApiRequest.reload_user_organizations_info.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.search_user_organizations_args = $root.object_msg.OrganizationsApiRequest.search_user_organizations.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.archive_user_organizaton_args = $root.object_msg.OrganizationsApiRequest.archive_user_organizaton.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.does_user_have_organization_args = $root.object_msg.OrganizationsApiRequest.does_user_have_organization.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        OrganizationsApiRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.OrganizationsApiRequest)
                return object;
            let message = new $root.object_msg.OrganizationsApiRequest();
            if (object.update_organization_by_user_args != null) {
                if (typeof object.update_organization_by_user_args !== "object")
                    throw TypeError(".object_msg.OrganizationsApiRequest.update_organization_by_user_args: object expected");
                message.update_organization_by_user_args = $root.object_msg.OrganizationsApiRequest.update_organization_by_user.fromObject(object.update_organization_by_user_args);
            }
            if (object.get_or_add_bank_args != null) {
                if (typeof object.get_or_add_bank_args !== "object")
                    throw TypeError(".object_msg.OrganizationsApiRequest.get_or_add_bank_args: object expected");
                message.get_or_add_bank_args = $root.object_msg.OrganizationsApiRequest.get_or_add_bank.fromObject(object.get_or_add_bank_args);
            }
            if (object.get_organization_suggestion_args != null) {
                if (typeof object.get_organization_suggestion_args !== "object")
                    throw TypeError(".object_msg.OrganizationsApiRequest.get_organization_suggestion_args: object expected");
                message.get_organization_suggestion_args = $root.object_msg.OrganizationsApiRequest.get_organization_suggestion.fromObject(object.get_organization_suggestion_args);
            }
            if (object.search_organization_args != null) {
                if (typeof object.search_organization_args !== "object")
                    throw TypeError(".object_msg.OrganizationsApiRequest.search_organization_args: object expected");
                message.search_organization_args = $root.object_msg.OrganizationsApiRequest.search_organization.fromObject(object.search_organization_args);
            }
            if (object.select_organization_args != null) {
                if (typeof object.select_organization_args !== "object")
                    throw TypeError(".object_msg.OrganizationsApiRequest.select_organization_args: object expected");
                message.select_organization_args = $root.object_msg.OrganizationsApiRequest.select_organization.fromObject(object.select_organization_args);
            }
            if (object.reload_user_organizations_info_args != null) {
                if (typeof object.reload_user_organizations_info_args !== "object")
                    throw TypeError(".object_msg.OrganizationsApiRequest.reload_user_organizations_info_args: object expected");
                message.reload_user_organizations_info_args = $root.object_msg.OrganizationsApiRequest.reload_user_organizations_info.fromObject(object.reload_user_organizations_info_args);
            }
            if (object.search_user_organizations_args != null) {
                if (typeof object.search_user_organizations_args !== "object")
                    throw TypeError(".object_msg.OrganizationsApiRequest.search_user_organizations_args: object expected");
                message.search_user_organizations_args = $root.object_msg.OrganizationsApiRequest.search_user_organizations.fromObject(object.search_user_organizations_args);
            }
            if (object.archive_user_organizaton_args != null) {
                if (typeof object.archive_user_organizaton_args !== "object")
                    throw TypeError(".object_msg.OrganizationsApiRequest.archive_user_organizaton_args: object expected");
                message.archive_user_organizaton_args = $root.object_msg.OrganizationsApiRequest.archive_user_organizaton.fromObject(object.archive_user_organizaton_args);
            }
            if (object.does_user_have_organization_args != null) {
                if (typeof object.does_user_have_organization_args !== "object")
                    throw TypeError(".object_msg.OrganizationsApiRequest.does_user_have_organization_args: object expected");
                message.does_user_have_organization_args = $root.object_msg.OrganizationsApiRequest.does_user_have_organization.fromObject(object.does_user_have_organization_args);
            }
            return message;
        };

        OrganizationsApiRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.update_organization_by_user_args != null && message.hasOwnProperty("update_organization_by_user_args")) {
                object.update_organization_by_user_args = $root.object_msg.OrganizationsApiRequest.update_organization_by_user.toObject(message.update_organization_by_user_args, options);
                if (options.oneofs)
                    object.args = "update_organization_by_user_args";
            }
            if (message.get_or_add_bank_args != null && message.hasOwnProperty("get_or_add_bank_args")) {
                object.get_or_add_bank_args = $root.object_msg.OrganizationsApiRequest.get_or_add_bank.toObject(message.get_or_add_bank_args, options);
                if (options.oneofs)
                    object.args = "get_or_add_bank_args";
            }
            if (message.get_organization_suggestion_args != null && message.hasOwnProperty("get_organization_suggestion_args")) {
                object.get_organization_suggestion_args = $root.object_msg.OrganizationsApiRequest.get_organization_suggestion.toObject(message.get_organization_suggestion_args, options);
                if (options.oneofs)
                    object.args = "get_organization_suggestion_args";
            }
            if (message.search_organization_args != null && message.hasOwnProperty("search_organization_args")) {
                object.search_organization_args = $root.object_msg.OrganizationsApiRequest.search_organization.toObject(message.search_organization_args, options);
                if (options.oneofs)
                    object.args = "search_organization_args";
            }
            if (message.select_organization_args != null && message.hasOwnProperty("select_organization_args")) {
                object.select_organization_args = $root.object_msg.OrganizationsApiRequest.select_organization.toObject(message.select_organization_args, options);
                if (options.oneofs)
                    object.args = "select_organization_args";
            }
            if (message.reload_user_organizations_info_args != null && message.hasOwnProperty("reload_user_organizations_info_args")) {
                object.reload_user_organizations_info_args = $root.object_msg.OrganizationsApiRequest.reload_user_organizations_info.toObject(message.reload_user_organizations_info_args, options);
                if (options.oneofs)
                    object.args = "reload_user_organizations_info_args";
            }
            if (message.search_user_organizations_args != null && message.hasOwnProperty("search_user_organizations_args")) {
                object.search_user_organizations_args = $root.object_msg.OrganizationsApiRequest.search_user_organizations.toObject(message.search_user_organizations_args, options);
                if (options.oneofs)
                    object.args = "search_user_organizations_args";
            }
            if (message.archive_user_organizaton_args != null && message.hasOwnProperty("archive_user_organizaton_args")) {
                object.archive_user_organizaton_args = $root.object_msg.OrganizationsApiRequest.archive_user_organizaton.toObject(message.archive_user_organizaton_args, options);
                if (options.oneofs)
                    object.args = "archive_user_organizaton_args";
            }
            if (message.does_user_have_organization_args != null && message.hasOwnProperty("does_user_have_organization_args")) {
                object.does_user_have_organization_args = $root.object_msg.OrganizationsApiRequest.does_user_have_organization.toObject(message.does_user_have_organization_args, options);
                if (options.oneofs)
                    object.args = "does_user_have_organization_args";
            }
            return object;
        };

        OrganizationsApiRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrganizationsApiRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.OrganizationsApiRequest";
        };

        OrganizationsApiRequest.Method = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "unknownMethod"] = 0;
            values[valuesById[1] = "updateOrganizationByUser"] = 1;
            values[valuesById[2] = "getOrAddBank"] = 2;
            values[valuesById[3] = "getOrganizationSuggestion"] = 3;
            values[valuesById[4] = "searchOrganization"] = 4;
            values[valuesById[5] = "selectOrganization"] = 5;
            values[valuesById[6] = "reloadUserOrganizationsInfo"] = 6;
            values[valuesById[7] = "searchUserOrganizations"] = 7;
            values[valuesById[8] = "archiveUserOrganizaton"] = 8;
            values[valuesById[9] = "doesUserHaveOrganization"] = 9;
            return values;
        })();

        OrganizationsApiRequest.update_organization_by_user = (function() {

            function update_organization_by_user(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            update_organization_by_user.prototype.organizations = $util.newBuffer([]);
            update_organization_by_user.prototype.org_id_for_archive = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            update_organization_by_user.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.organizations != null && Object.hasOwnProperty.call(message, "organizations"))
                    writer.uint32(10).bytes(message.organizations);
                if (message.org_id_for_archive != null && Object.hasOwnProperty.call(message, "org_id_for_archive"))
                    writer.uint32(16).uint64(message.org_id_for_archive);
                return writer;
            };

            update_organization_by_user.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationsApiRequest.update_organization_by_user();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.organizations = reader.bytes();
                            break;
                        }
                    case 2: {
                            message.org_id_for_archive = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            update_organization_by_user.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrganizationsApiRequest.update_organization_by_user)
                    return object;
                let message = new $root.object_msg.OrganizationsApiRequest.update_organization_by_user();
                if (object.organizations != null)
                    if (typeof object.organizations === "string")
                        $util.base64.decode(object.organizations, message.organizations = $util.newBuffer($util.base64.length(object.organizations)), 0);
                    else if (object.organizations.length >= 0)
                        message.organizations = object.organizations;
                if (object.org_id_for_archive != null)
                    if ($util.Long)
                        (message.org_id_for_archive = $util.Long.fromValue(object.org_id_for_archive)).unsigned = true;
                    else if (typeof object.org_id_for_archive === "string")
                        message.org_id_for_archive = parseInt(object.org_id_for_archive, 10);
                    else if (typeof object.org_id_for_archive === "number")
                        message.org_id_for_archive = object.org_id_for_archive;
                    else if (typeof object.org_id_for_archive === "object")
                        message.org_id_for_archive = new $util.LongBits(object.org_id_for_archive.low >>> 0, object.org_id_for_archive.high >>> 0).toNumber(true);
                return message;
            };

            update_organization_by_user.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if (options.bytes === String)
                        object.organizations = "";
                    else {
                        object.organizations = [];
                        if (options.bytes !== Array)
                            object.organizations = $util.newBuffer(object.organizations);
                    }
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.org_id_for_archive = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.org_id_for_archive = options.longs === String ? "0" : 0;
                }
                if (message.organizations != null && message.hasOwnProperty("organizations"))
                    object.organizations = options.bytes === String ? $util.base64.encode(message.organizations, 0, message.organizations.length) : options.bytes === Array ? Array.prototype.slice.call(message.organizations) : message.organizations;
                if (message.org_id_for_archive != null && message.hasOwnProperty("org_id_for_archive"))
                    if (typeof message.org_id_for_archive === "number")
                        object.org_id_for_archive = options.longs === String ? String(message.org_id_for_archive) : message.org_id_for_archive;
                    else
                        object.org_id_for_archive = options.longs === String ? $util.Long.prototype.toString.call(message.org_id_for_archive) : options.longs === Number ? new $util.LongBits(message.org_id_for_archive.low >>> 0, message.org_id_for_archive.high >>> 0).toNumber(true) : message.org_id_for_archive;
                return object;
            };

            update_organization_by_user.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            update_organization_by_user.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrganizationsApiRequest.update_organization_by_user";
            };

            return update_organization_by_user;
        })();

        OrganizationsApiRequest.get_or_add_bank = (function() {

            function get_or_add_bank(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_or_add_bank.prototype.bic = "";

            get_or_add_bank.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.bic != null && Object.hasOwnProperty.call(message, "bic"))
                    writer.uint32(10).string(message.bic);
                return writer;
            };

            get_or_add_bank.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationsApiRequest.get_or_add_bank();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.bic = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_or_add_bank.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrganizationsApiRequest.get_or_add_bank)
                    return object;
                let message = new $root.object_msg.OrganizationsApiRequest.get_or_add_bank();
                if (object.bic != null)
                    message.bic = String(object.bic);
                return message;
            };

            get_or_add_bank.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.bic = "";
                if (message.bic != null && message.hasOwnProperty("bic"))
                    object.bic = message.bic;
                return object;
            };

            get_or_add_bank.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_or_add_bank.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrganizationsApiRequest.get_or_add_bank";
            };

            return get_or_add_bank;
        })();

        OrganizationsApiRequest.get_organization_suggestion = (function() {

            function get_organization_suggestion(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_organization_suggestion.prototype.input = "";

            get_organization_suggestion.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.input != null && Object.hasOwnProperty.call(message, "input"))
                    writer.uint32(10).string(message.input);
                return writer;
            };

            get_organization_suggestion.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationsApiRequest.get_organization_suggestion();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.input = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_organization_suggestion.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrganizationsApiRequest.get_organization_suggestion)
                    return object;
                let message = new $root.object_msg.OrganizationsApiRequest.get_organization_suggestion();
                if (object.input != null)
                    message.input = String(object.input);
                return message;
            };

            get_organization_suggestion.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.input = "";
                if (message.input != null && message.hasOwnProperty("input"))
                    object.input = message.input;
                return object;
            };

            get_organization_suggestion.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_organization_suggestion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrganizationsApiRequest.get_organization_suggestion";
            };

            return get_organization_suggestion;
        })();

        OrganizationsApiRequest.search_organization = (function() {

            function search_organization(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            search_organization.prototype.organization_name_inn = "";
            search_organization.prototype.organization_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            search_organization.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.organization_name_inn != null && Object.hasOwnProperty.call(message, "organization_name_inn"))
                    writer.uint32(10).string(message.organization_name_inn);
                if (message.organization_id != null && Object.hasOwnProperty.call(message, "organization_id"))
                    writer.uint32(16).uint64(message.organization_id);
                return writer;
            };

            search_organization.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationsApiRequest.search_organization();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.organization_name_inn = reader.string();
                            break;
                        }
                    case 2: {
                            message.organization_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            search_organization.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrganizationsApiRequest.search_organization)
                    return object;
                let message = new $root.object_msg.OrganizationsApiRequest.search_organization();
                if (object.organization_name_inn != null)
                    message.organization_name_inn = String(object.organization_name_inn);
                if (object.organization_id != null)
                    if ($util.Long)
                        (message.organization_id = $util.Long.fromValue(object.organization_id)).unsigned = true;
                    else if (typeof object.organization_id === "string")
                        message.organization_id = parseInt(object.organization_id, 10);
                    else if (typeof object.organization_id === "number")
                        message.organization_id = object.organization_id;
                    else if (typeof object.organization_id === "object")
                        message.organization_id = new $util.LongBits(object.organization_id.low >>> 0, object.organization_id.high >>> 0).toNumber(true);
                return message;
            };

            search_organization.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.organization_name_inn = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.organization_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.organization_id = options.longs === String ? "0" : 0;
                }
                if (message.organization_name_inn != null && message.hasOwnProperty("organization_name_inn"))
                    object.organization_name_inn = message.organization_name_inn;
                if (message.organization_id != null && message.hasOwnProperty("organization_id"))
                    if (typeof message.organization_id === "number")
                        object.organization_id = options.longs === String ? String(message.organization_id) : message.organization_id;
                    else
                        object.organization_id = options.longs === String ? $util.Long.prototype.toString.call(message.organization_id) : options.longs === Number ? new $util.LongBits(message.organization_id.low >>> 0, message.organization_id.high >>> 0).toNumber(true) : message.organization_id;
                return object;
            };

            search_organization.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            search_organization.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrganizationsApiRequest.search_organization";
            };

            return search_organization;
        })();

        OrganizationsApiRequest.select_organization = (function() {

            function select_organization(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            select_organization.prototype.organization_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            select_organization.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.organization_id != null && Object.hasOwnProperty.call(message, "organization_id"))
                    writer.uint32(8).uint64(message.organization_id);
                return writer;
            };

            select_organization.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationsApiRequest.select_organization();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.organization_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            select_organization.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrganizationsApiRequest.select_organization)
                    return object;
                let message = new $root.object_msg.OrganizationsApiRequest.select_organization();
                if (object.organization_id != null)
                    if ($util.Long)
                        (message.organization_id = $util.Long.fromValue(object.organization_id)).unsigned = true;
                    else if (typeof object.organization_id === "string")
                        message.organization_id = parseInt(object.organization_id, 10);
                    else if (typeof object.organization_id === "number")
                        message.organization_id = object.organization_id;
                    else if (typeof object.organization_id === "object")
                        message.organization_id = new $util.LongBits(object.organization_id.low >>> 0, object.organization_id.high >>> 0).toNumber(true);
                return message;
            };

            select_organization.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.organization_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.organization_id = options.longs === String ? "0" : 0;
                if (message.organization_id != null && message.hasOwnProperty("organization_id"))
                    if (typeof message.organization_id === "number")
                        object.organization_id = options.longs === String ? String(message.organization_id) : message.organization_id;
                    else
                        object.organization_id = options.longs === String ? $util.Long.prototype.toString.call(message.organization_id) : options.longs === Number ? new $util.LongBits(message.organization_id.low >>> 0, message.organization_id.high >>> 0).toNumber(true) : message.organization_id;
                return object;
            };

            select_organization.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            select_organization.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrganizationsApiRequest.select_organization";
            };

            return select_organization;
        })();

        OrganizationsApiRequest.reload_user_organizations_info = (function() {

            function reload_user_organizations_info(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            reload_user_organizations_info.prototype.dull_arg = false;

            reload_user_organizations_info.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.dull_arg != null && Object.hasOwnProperty.call(message, "dull_arg"))
                    writer.uint32(8).bool(message.dull_arg);
                return writer;
            };

            reload_user_organizations_info.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationsApiRequest.reload_user_organizations_info();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.dull_arg = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            reload_user_organizations_info.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrganizationsApiRequest.reload_user_organizations_info)
                    return object;
                let message = new $root.object_msg.OrganizationsApiRequest.reload_user_organizations_info();
                if (object.dull_arg != null)
                    message.dull_arg = Boolean(object.dull_arg);
                return message;
            };

            reload_user_organizations_info.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.dull_arg = false;
                if (message.dull_arg != null && message.hasOwnProperty("dull_arg"))
                    object.dull_arg = message.dull_arg;
                return object;
            };

            reload_user_organizations_info.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            reload_user_organizations_info.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrganizationsApiRequest.reload_user_organizations_info";
            };

            return reload_user_organizations_info;
        })();

        OrganizationsApiRequest.search_user_organizations = (function() {

            function search_user_organizations(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            search_user_organizations.prototype.dull_arg = false;

            search_user_organizations.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.dull_arg != null && Object.hasOwnProperty.call(message, "dull_arg"))
                    writer.uint32(8).bool(message.dull_arg);
                return writer;
            };

            search_user_organizations.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationsApiRequest.search_user_organizations();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.dull_arg = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            search_user_organizations.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrganizationsApiRequest.search_user_organizations)
                    return object;
                let message = new $root.object_msg.OrganizationsApiRequest.search_user_organizations();
                if (object.dull_arg != null)
                    message.dull_arg = Boolean(object.dull_arg);
                return message;
            };

            search_user_organizations.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.dull_arg = false;
                if (message.dull_arg != null && message.hasOwnProperty("dull_arg"))
                    object.dull_arg = message.dull_arg;
                return object;
            };

            search_user_organizations.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            search_user_organizations.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrganizationsApiRequest.search_user_organizations";
            };

            return search_user_organizations;
        })();

        OrganizationsApiRequest.archive_user_organizaton = (function() {

            function archive_user_organizaton(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            archive_user_organizaton.prototype.organization_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            archive_user_organizaton.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.organization_id != null && Object.hasOwnProperty.call(message, "organization_id"))
                    writer.uint32(8).uint64(message.organization_id);
                return writer;
            };

            archive_user_organizaton.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationsApiRequest.archive_user_organizaton();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.organization_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            archive_user_organizaton.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrganizationsApiRequest.archive_user_organizaton)
                    return object;
                let message = new $root.object_msg.OrganizationsApiRequest.archive_user_organizaton();
                if (object.organization_id != null)
                    if ($util.Long)
                        (message.organization_id = $util.Long.fromValue(object.organization_id)).unsigned = true;
                    else if (typeof object.organization_id === "string")
                        message.organization_id = parseInt(object.organization_id, 10);
                    else if (typeof object.organization_id === "number")
                        message.organization_id = object.organization_id;
                    else if (typeof object.organization_id === "object")
                        message.organization_id = new $util.LongBits(object.organization_id.low >>> 0, object.organization_id.high >>> 0).toNumber(true);
                return message;
            };

            archive_user_organizaton.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.organization_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.organization_id = options.longs === String ? "0" : 0;
                if (message.organization_id != null && message.hasOwnProperty("organization_id"))
                    if (typeof message.organization_id === "number")
                        object.organization_id = options.longs === String ? String(message.organization_id) : message.organization_id;
                    else
                        object.organization_id = options.longs === String ? $util.Long.prototype.toString.call(message.organization_id) : options.longs === Number ? new $util.LongBits(message.organization_id.low >>> 0, message.organization_id.high >>> 0).toNumber(true) : message.organization_id;
                return object;
            };

            archive_user_organizaton.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            archive_user_organizaton.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrganizationsApiRequest.archive_user_organizaton";
            };

            return archive_user_organizaton;
        })();

        OrganizationsApiRequest.does_user_have_organization = (function() {

            function does_user_have_organization(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            does_user_have_organization.prototype.inn = "";
            does_user_have_organization.prototype.kpp = "";

            does_user_have_organization.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.inn != null && Object.hasOwnProperty.call(message, "inn"))
                    writer.uint32(10).string(message.inn);
                if (message.kpp != null && Object.hasOwnProperty.call(message, "kpp"))
                    writer.uint32(18).string(message.kpp);
                return writer;
            };

            does_user_have_organization.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.OrganizationsApiRequest.does_user_have_organization();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.inn = reader.string();
                            break;
                        }
                    case 2: {
                            message.kpp = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            does_user_have_organization.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.OrganizationsApiRequest.does_user_have_organization)
                    return object;
                let message = new $root.object_msg.OrganizationsApiRequest.does_user_have_organization();
                if (object.inn != null)
                    message.inn = String(object.inn);
                if (object.kpp != null)
                    message.kpp = String(object.kpp);
                return message;
            };

            does_user_have_organization.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.inn = "";
                    object.kpp = "";
                }
                if (message.inn != null && message.hasOwnProperty("inn"))
                    object.inn = message.inn;
                if (message.kpp != null && message.hasOwnProperty("kpp"))
                    object.kpp = message.kpp;
                return object;
            };

            does_user_have_organization.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            does_user_have_organization.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.OrganizationsApiRequest.does_user_have_organization";
            };

            return does_user_have_organization;
        })();

        return OrganizationsApiRequest;
    })();

    object_msg.update_organization_by_user_return_msg = (function() {

        function update_organization_by_user_return_msg(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        update_organization_by_user_return_msg.prototype.id = null;
        update_organization_by_user_return_msg.prototype.success = false;

        let $oneOfFields;

        Object.defineProperty(update_organization_by_user_return_msg.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        update_organization_by_user_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(8).uint64(message.id);
            if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                writer.uint32(16).bool(message.success);
            return writer;
        };

        update_organization_by_user_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.update_organization_by_user_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.uint64();
                        break;
                    }
                case 2: {
                        message.success = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        update_organization_by_user_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.update_organization_by_user_return_msg)
                return object;
            let message = new $root.object_msg.update_organization_by_user_return_msg();
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.success != null)
                message.success = Boolean(object.success);
            return message;
        };

        update_organization_by_user_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.success = false;
            if (message.id != null && message.hasOwnProperty("id")) {
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (options.oneofs)
                    object._id = "id";
            }
            if (message.success != null && message.hasOwnProperty("success"))
                object.success = message.success;
            return object;
        };

        update_organization_by_user_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        update_organization_by_user_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.update_organization_by_user_return_msg";
        };

        return update_organization_by_user_return_msg;
    })();

    object_msg.get_or_add_bank_return_msg = (function() {

        function get_or_add_bank_return_msg(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        get_or_add_bank_return_msg.prototype.name = "";
        get_or_add_bank_return_msg.prototype.bic = "";
        get_or_add_bank_return_msg.prototype.swift = "";
        get_or_add_bank_return_msg.prototype.inn = "";
        get_or_add_bank_return_msg.prototype.kpp = "";
        get_or_add_bank_return_msg.prototype.correspondent_account = "";
        get_or_add_bank_return_msg.prototype.legal_address = "";
        get_or_add_bank_return_msg.prototype.bank_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        get_or_add_bank_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(10).string(message.name);
            if (message.bic != null && Object.hasOwnProperty.call(message, "bic"))
                writer.uint32(18).string(message.bic);
            if (message.swift != null && Object.hasOwnProperty.call(message, "swift"))
                writer.uint32(26).string(message.swift);
            if (message.inn != null && Object.hasOwnProperty.call(message, "inn"))
                writer.uint32(34).string(message.inn);
            if (message.kpp != null && Object.hasOwnProperty.call(message, "kpp"))
                writer.uint32(42).string(message.kpp);
            if (message.correspondent_account != null && Object.hasOwnProperty.call(message, "correspondent_account"))
                writer.uint32(50).string(message.correspondent_account);
            if (message.legal_address != null && Object.hasOwnProperty.call(message, "legal_address"))
                writer.uint32(58).string(message.legal_address);
            if (message.bank_id != null && Object.hasOwnProperty.call(message, "bank_id"))
                writer.uint32(64).uint64(message.bank_id);
            return writer;
        };

        get_or_add_bank_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_or_add_bank_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.bic = reader.string();
                        break;
                    }
                case 3: {
                        message.swift = reader.string();
                        break;
                    }
                case 4: {
                        message.inn = reader.string();
                        break;
                    }
                case 5: {
                        message.kpp = reader.string();
                        break;
                    }
                case 6: {
                        message.correspondent_account = reader.string();
                        break;
                    }
                case 7: {
                        message.legal_address = reader.string();
                        break;
                    }
                case 8: {
                        message.bank_id = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        get_or_add_bank_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.get_or_add_bank_return_msg)
                return object;
            let message = new $root.object_msg.get_or_add_bank_return_msg();
            if (object.name != null)
                message.name = String(object.name);
            if (object.bic != null)
                message.bic = String(object.bic);
            if (object.swift != null)
                message.swift = String(object.swift);
            if (object.inn != null)
                message.inn = String(object.inn);
            if (object.kpp != null)
                message.kpp = String(object.kpp);
            if (object.correspondent_account != null)
                message.correspondent_account = String(object.correspondent_account);
            if (object.legal_address != null)
                message.legal_address = String(object.legal_address);
            if (object.bank_id != null)
                if ($util.Long)
                    (message.bank_id = $util.Long.fromValue(object.bank_id)).unsigned = true;
                else if (typeof object.bank_id === "string")
                    message.bank_id = parseInt(object.bank_id, 10);
                else if (typeof object.bank_id === "number")
                    message.bank_id = object.bank_id;
                else if (typeof object.bank_id === "object")
                    message.bank_id = new $util.LongBits(object.bank_id.low >>> 0, object.bank_id.high >>> 0).toNumber(true);
            return message;
        };

        get_or_add_bank_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.bic = "";
                object.swift = "";
                object.inn = "";
                object.kpp = "";
                object.correspondent_account = "";
                object.legal_address = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.bank_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.bank_id = options.longs === String ? "0" : 0;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.bic != null && message.hasOwnProperty("bic"))
                object.bic = message.bic;
            if (message.swift != null && message.hasOwnProperty("swift"))
                object.swift = message.swift;
            if (message.inn != null && message.hasOwnProperty("inn"))
                object.inn = message.inn;
            if (message.kpp != null && message.hasOwnProperty("kpp"))
                object.kpp = message.kpp;
            if (message.correspondent_account != null && message.hasOwnProperty("correspondent_account"))
                object.correspondent_account = message.correspondent_account;
            if (message.legal_address != null && message.hasOwnProperty("legal_address"))
                object.legal_address = message.legal_address;
            if (message.bank_id != null && message.hasOwnProperty("bank_id"))
                if (typeof message.bank_id === "number")
                    object.bank_id = options.longs === String ? String(message.bank_id) : message.bank_id;
                else
                    object.bank_id = options.longs === String ? $util.Long.prototype.toString.call(message.bank_id) : options.longs === Number ? new $util.LongBits(message.bank_id.low >>> 0, message.bank_id.high >>> 0).toNumber(true) : message.bank_id;
            return object;
        };

        get_or_add_bank_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        get_or_add_bank_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.get_or_add_bank_return_msg";
        };

        return get_or_add_bank_return_msg;
    })();

    object_msg.get_organization_suggestion_return_msg = (function() {

        function get_organization_suggestion_return_msg(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        get_organization_suggestion_return_msg.prototype.items = $util.emptyArray;

        get_organization_suggestion_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.object_msg.get_organization_suggestion_return_msg.Organization.encode(message.items[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        get_organization_suggestion_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_organization_suggestion_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.object_msg.get_organization_suggestion_return_msg.Organization.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        get_organization_suggestion_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.get_organization_suggestion_return_msg)
                return object;
            let message = new $root.object_msg.get_organization_suggestion_return_msg();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".object_msg.get_organization_suggestion_return_msg.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".object_msg.get_organization_suggestion_return_msg.items: object expected");
                    message.items[i] = $root.object_msg.get_organization_suggestion_return_msg.Organization.fromObject(object.items[i]);
                }
            }
            return message;
        };

        get_organization_suggestion_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.object_msg.get_organization_suggestion_return_msg.Organization.toObject(message.items[j], options);
            }
            return object;
        };

        get_organization_suggestion_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        get_organization_suggestion_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.get_organization_suggestion_return_msg";
        };

        get_organization_suggestion_return_msg.Organization = (function() {

            function Organization(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            Organization.prototype.name = "";
            Organization.prototype.inn = "";
            Organization.prototype.kpp = "";
            Organization.prototype.ogrn = "";
            Organization.prototype.legal_address = "";
            Organization.prototype.representative_name = "";
            Organization.prototype.representative_position = "";
            Organization.prototype.organization_type = "";
            Organization.prototype.legal_address_quality = 0;

            Organization.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(10).string(message.name);
                if (message.inn != null && Object.hasOwnProperty.call(message, "inn"))
                    writer.uint32(18).string(message.inn);
                if (message.kpp != null && Object.hasOwnProperty.call(message, "kpp"))
                    writer.uint32(26).string(message.kpp);
                if (message.ogrn != null && Object.hasOwnProperty.call(message, "ogrn"))
                    writer.uint32(34).string(message.ogrn);
                if (message.legal_address != null && Object.hasOwnProperty.call(message, "legal_address"))
                    writer.uint32(42).string(message.legal_address);
                if (message.representative_name != null && Object.hasOwnProperty.call(message, "representative_name"))
                    writer.uint32(50).string(message.representative_name);
                if (message.representative_position != null && Object.hasOwnProperty.call(message, "representative_position"))
                    writer.uint32(58).string(message.representative_position);
                if (message.organization_type != null && Object.hasOwnProperty.call(message, "organization_type"))
                    writer.uint32(66).string(message.organization_type);
                if (message.legal_address_quality != null && Object.hasOwnProperty.call(message, "legal_address_quality"))
                    writer.uint32(72).int32(message.legal_address_quality);
                return writer;
            };

            Organization.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_organization_suggestion_return_msg.Organization();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.inn = reader.string();
                            break;
                        }
                    case 3: {
                            message.kpp = reader.string();
                            break;
                        }
                    case 4: {
                            message.ogrn = reader.string();
                            break;
                        }
                    case 5: {
                            message.legal_address = reader.string();
                            break;
                        }
                    case 6: {
                            message.representative_name = reader.string();
                            break;
                        }
                    case 7: {
                            message.representative_position = reader.string();
                            break;
                        }
                    case 8: {
                            message.organization_type = reader.string();
                            break;
                        }
                    case 9: {
                            message.legal_address_quality = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            Organization.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.get_organization_suggestion_return_msg.Organization)
                    return object;
                let message = new $root.object_msg.get_organization_suggestion_return_msg.Organization();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.inn != null)
                    message.inn = String(object.inn);
                if (object.kpp != null)
                    message.kpp = String(object.kpp);
                if (object.ogrn != null)
                    message.ogrn = String(object.ogrn);
                if (object.legal_address != null)
                    message.legal_address = String(object.legal_address);
                if (object.representative_name != null)
                    message.representative_name = String(object.representative_name);
                if (object.representative_position != null)
                    message.representative_position = String(object.representative_position);
                if (object.organization_type != null)
                    message.organization_type = String(object.organization_type);
                if (object.legal_address_quality != null)
                    message.legal_address_quality = object.legal_address_quality | 0;
                return message;
            };

            Organization.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.inn = "";
                    object.kpp = "";
                    object.ogrn = "";
                    object.legal_address = "";
                    object.representative_name = "";
                    object.representative_position = "";
                    object.organization_type = "";
                    object.legal_address_quality = 0;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.inn != null && message.hasOwnProperty("inn"))
                    object.inn = message.inn;
                if (message.kpp != null && message.hasOwnProperty("kpp"))
                    object.kpp = message.kpp;
                if (message.ogrn != null && message.hasOwnProperty("ogrn"))
                    object.ogrn = message.ogrn;
                if (message.legal_address != null && message.hasOwnProperty("legal_address"))
                    object.legal_address = message.legal_address;
                if (message.representative_name != null && message.hasOwnProperty("representative_name"))
                    object.representative_name = message.representative_name;
                if (message.representative_position != null && message.hasOwnProperty("representative_position"))
                    object.representative_position = message.representative_position;
                if (message.organization_type != null && message.hasOwnProperty("organization_type"))
                    object.organization_type = message.organization_type;
                if (message.legal_address_quality != null && message.hasOwnProperty("legal_address_quality"))
                    object.legal_address_quality = message.legal_address_quality;
                return object;
            };

            Organization.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            Organization.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.get_organization_suggestion_return_msg.Organization";
            };

            return Organization;
        })();

        return get_organization_suggestion_return_msg;
    })();

    object_msg.search_organization_return_msg = (function() {

        function search_organization_return_msg(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        search_organization_return_msg.prototype.items = $util.emptyArray;

        search_organization_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.object_msg.search_organization_return_msg.ItemWithId.encode(message.items[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        search_organization_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_organization_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.object_msg.search_organization_return_msg.ItemWithId.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        search_organization_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.search_organization_return_msg)
                return object;
            let message = new $root.object_msg.search_organization_return_msg();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".object_msg.search_organization_return_msg.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".object_msg.search_organization_return_msg.items: object expected");
                    message.items[i] = $root.object_msg.search_organization_return_msg.ItemWithId.fromObject(object.items[i]);
                }
            }
            return message;
        };

        search_organization_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.object_msg.search_organization_return_msg.ItemWithId.toObject(message.items[j], options);
            }
            return object;
        };

        search_organization_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        search_organization_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.search_organization_return_msg";
        };

        search_organization_return_msg.ItemWithId = (function() {

            function ItemWithId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            ItemWithId.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            ItemWithId.prototype.name = "";
            ItemWithId.prototype.inn = "";
            ItemWithId.prototype.kpp = "";

            ItemWithId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(8).uint64(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(18).string(message.name);
                if (message.inn != null && Object.hasOwnProperty.call(message, "inn"))
                    writer.uint32(26).string(message.inn);
                if (message.kpp != null && Object.hasOwnProperty.call(message, "kpp"))
                    writer.uint32(34).string(message.kpp);
                return writer;
            };

            ItemWithId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_organization_return_msg.ItemWithId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    case 3: {
                            message.inn = reader.string();
                            break;
                        }
                    case 4: {
                            message.kpp = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            ItemWithId.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.search_organization_return_msg.ItemWithId)
                    return object;
                let message = new $root.object_msg.search_organization_return_msg.ItemWithId();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.inn != null)
                    message.inn = String(object.inn);
                if (object.kpp != null)
                    message.kpp = String(object.kpp);
                return message;
            };

            ItemWithId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.inn = "";
                    object.kpp = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.inn != null && message.hasOwnProperty("inn"))
                    object.inn = message.inn;
                if (message.kpp != null && message.hasOwnProperty("kpp"))
                    object.kpp = message.kpp;
                return object;
            };

            ItemWithId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            ItemWithId.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.search_organization_return_msg.ItemWithId";
            };

            return ItemWithId;
        })();

        return search_organization_return_msg;
    })();

    object_msg.search_user_organizations_return_msg = (function() {

        function search_user_organizations_return_msg(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        search_user_organizations_return_msg.prototype.items = $util.emptyArray;

        search_user_organizations_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.object_msg.search_user_organizations_return_msg.ItemWithId.encode(message.items[i], writer.uint32(10).fork()).ldelim();
            return writer;
        };

        search_user_organizations_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_user_organizations_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.object_msg.search_user_organizations_return_msg.ItemWithId.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        search_user_organizations_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.search_user_organizations_return_msg)
                return object;
            let message = new $root.object_msg.search_user_organizations_return_msg();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".object_msg.search_user_organizations_return_msg.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".object_msg.search_user_organizations_return_msg.items: object expected");
                    message.items[i] = $root.object_msg.search_user_organizations_return_msg.ItemWithId.fromObject(object.items[i]);
                }
            }
            return message;
        };

        search_user_organizations_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.object_msg.search_user_organizations_return_msg.ItemWithId.toObject(message.items[j], options);
            }
            return object;
        };

        search_user_organizations_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        search_user_organizations_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.search_user_organizations_return_msg";
        };

        search_user_organizations_return_msg.ItemWithId = (function() {

            function ItemWithId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            ItemWithId.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
            ItemWithId.prototype.item = "";

            ItemWithId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(8).uint64(message.id);
                if (message.item != null && Object.hasOwnProperty.call(message, "item"))
                    writer.uint32(18).string(message.item);
                return writer;
            };

            ItemWithId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.search_user_organizations_return_msg.ItemWithId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.uint64();
                            break;
                        }
                    case 2: {
                            message.item = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            ItemWithId.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.search_user_organizations_return_msg.ItemWithId)
                    return object;
                let message = new $root.object_msg.search_user_organizations_return_msg.ItemWithId();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.item != null)
                    message.item = String(object.item);
                return message;
            };

            ItemWithId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.item = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.item != null && message.hasOwnProperty("item"))
                    object.item = message.item;
                return object;
            };

            ItemWithId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            ItemWithId.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.search_user_organizations_return_msg.ItemWithId";
            };

            return ItemWithId;
        })();

        return search_user_organizations_return_msg;
    })();

    object_msg.ServiceApiRequest = (function() {

        function ServiceApiRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        ServiceApiRequest.prototype.get_logs_args = null;
        ServiceApiRequest.prototype.get_or_create_personal_manager_args = null;
        ServiceApiRequest.prototype.get_address_suggestion_args = null;
        ServiceApiRequest.prototype.get_user_profile_info_args = null;
        ServiceApiRequest.prototype.get_upload_url_args = null;
        ServiceApiRequest.prototype.get_download_url_args = null;

        let $oneOfFields;

        Object.defineProperty(ServiceApiRequest.prototype, "args", {
            get: $util.oneOfGetter($oneOfFields = ["get_logs_args", "get_or_create_personal_manager_args", "get_address_suggestion_args", "get_user_profile_info_args", "get_upload_url_args", "get_download_url_args"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        ServiceApiRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.get_logs_args != null && Object.hasOwnProperty.call(message, "get_logs_args"))
                $root.object_msg.ServiceApiRequest.get_logs.encode(message.get_logs_args, writer.uint32(10).fork()).ldelim();
            if (message.get_or_create_personal_manager_args != null && Object.hasOwnProperty.call(message, "get_or_create_personal_manager_args"))
                $root.object_msg.ServiceApiRequest.get_or_create_personal_manager.encode(message.get_or_create_personal_manager_args, writer.uint32(18).fork()).ldelim();
            if (message.get_address_suggestion_args != null && Object.hasOwnProperty.call(message, "get_address_suggestion_args"))
                $root.object_msg.ServiceApiRequest.get_address_suggestion.encode(message.get_address_suggestion_args, writer.uint32(26).fork()).ldelim();
            if (message.get_user_profile_info_args != null && Object.hasOwnProperty.call(message, "get_user_profile_info_args"))
                $root.object_msg.ServiceApiRequest.get_user_profile_info.encode(message.get_user_profile_info_args, writer.uint32(34).fork()).ldelim();
            if (message.get_upload_url_args != null && Object.hasOwnProperty.call(message, "get_upload_url_args"))
                $root.object_msg.ServiceApiRequest.get_upload_url.encode(message.get_upload_url_args, writer.uint32(42).fork()).ldelim();
            if (message.get_download_url_args != null && Object.hasOwnProperty.call(message, "get_download_url_args"))
                $root.object_msg.ServiceApiRequest.get_download_url.encode(message.get_download_url_args, writer.uint32(50).fork()).ldelim();
            return writer;
        };

        ServiceApiRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.ServiceApiRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.get_logs_args = $root.object_msg.ServiceApiRequest.get_logs.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.get_or_create_personal_manager_args = $root.object_msg.ServiceApiRequest.get_or_create_personal_manager.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.get_address_suggestion_args = $root.object_msg.ServiceApiRequest.get_address_suggestion.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.get_user_profile_info_args = $root.object_msg.ServiceApiRequest.get_user_profile_info.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.get_upload_url_args = $root.object_msg.ServiceApiRequest.get_upload_url.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.get_download_url_args = $root.object_msg.ServiceApiRequest.get_download_url.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        ServiceApiRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.ServiceApiRequest)
                return object;
            let message = new $root.object_msg.ServiceApiRequest();
            if (object.get_logs_args != null) {
                if (typeof object.get_logs_args !== "object")
                    throw TypeError(".object_msg.ServiceApiRequest.get_logs_args: object expected");
                message.get_logs_args = $root.object_msg.ServiceApiRequest.get_logs.fromObject(object.get_logs_args);
            }
            if (object.get_or_create_personal_manager_args != null) {
                if (typeof object.get_or_create_personal_manager_args !== "object")
                    throw TypeError(".object_msg.ServiceApiRequest.get_or_create_personal_manager_args: object expected");
                message.get_or_create_personal_manager_args = $root.object_msg.ServiceApiRequest.get_or_create_personal_manager.fromObject(object.get_or_create_personal_manager_args);
            }
            if (object.get_address_suggestion_args != null) {
                if (typeof object.get_address_suggestion_args !== "object")
                    throw TypeError(".object_msg.ServiceApiRequest.get_address_suggestion_args: object expected");
                message.get_address_suggestion_args = $root.object_msg.ServiceApiRequest.get_address_suggestion.fromObject(object.get_address_suggestion_args);
            }
            if (object.get_user_profile_info_args != null) {
                if (typeof object.get_user_profile_info_args !== "object")
                    throw TypeError(".object_msg.ServiceApiRequest.get_user_profile_info_args: object expected");
                message.get_user_profile_info_args = $root.object_msg.ServiceApiRequest.get_user_profile_info.fromObject(object.get_user_profile_info_args);
            }
            if (object.get_upload_url_args != null) {
                if (typeof object.get_upload_url_args !== "object")
                    throw TypeError(".object_msg.ServiceApiRequest.get_upload_url_args: object expected");
                message.get_upload_url_args = $root.object_msg.ServiceApiRequest.get_upload_url.fromObject(object.get_upload_url_args);
            }
            if (object.get_download_url_args != null) {
                if (typeof object.get_download_url_args !== "object")
                    throw TypeError(".object_msg.ServiceApiRequest.get_download_url_args: object expected");
                message.get_download_url_args = $root.object_msg.ServiceApiRequest.get_download_url.fromObject(object.get_download_url_args);
            }
            return message;
        };

        ServiceApiRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.get_logs_args != null && message.hasOwnProperty("get_logs_args")) {
                object.get_logs_args = $root.object_msg.ServiceApiRequest.get_logs.toObject(message.get_logs_args, options);
                if (options.oneofs)
                    object.args = "get_logs_args";
            }
            if (message.get_or_create_personal_manager_args != null && message.hasOwnProperty("get_or_create_personal_manager_args")) {
                object.get_or_create_personal_manager_args = $root.object_msg.ServiceApiRequest.get_or_create_personal_manager.toObject(message.get_or_create_personal_manager_args, options);
                if (options.oneofs)
                    object.args = "get_or_create_personal_manager_args";
            }
            if (message.get_address_suggestion_args != null && message.hasOwnProperty("get_address_suggestion_args")) {
                object.get_address_suggestion_args = $root.object_msg.ServiceApiRequest.get_address_suggestion.toObject(message.get_address_suggestion_args, options);
                if (options.oneofs)
                    object.args = "get_address_suggestion_args";
            }
            if (message.get_user_profile_info_args != null && message.hasOwnProperty("get_user_profile_info_args")) {
                object.get_user_profile_info_args = $root.object_msg.ServiceApiRequest.get_user_profile_info.toObject(message.get_user_profile_info_args, options);
                if (options.oneofs)
                    object.args = "get_user_profile_info_args";
            }
            if (message.get_upload_url_args != null && message.hasOwnProperty("get_upload_url_args")) {
                object.get_upload_url_args = $root.object_msg.ServiceApiRequest.get_upload_url.toObject(message.get_upload_url_args, options);
                if (options.oneofs)
                    object.args = "get_upload_url_args";
            }
            if (message.get_download_url_args != null && message.hasOwnProperty("get_download_url_args")) {
                object.get_download_url_args = $root.object_msg.ServiceApiRequest.get_download_url.toObject(message.get_download_url_args, options);
                if (options.oneofs)
                    object.args = "get_download_url_args";
            }
            return object;
        };

        ServiceApiRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ServiceApiRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.ServiceApiRequest";
        };

        ServiceApiRequest.Method = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "unknownMethod"] = 0;
            values[valuesById[1] = "getLogs"] = 1;
            values[valuesById[2] = "getOrCreatePersonalManager"] = 2;
            values[valuesById[3] = "getAddressSuggestion"] = 3;
            values[valuesById[4] = "getUserProfileInfo"] = 4;
            values[valuesById[5] = "getUploadUrl"] = 5;
            values[valuesById[6] = "getDownloadUrl"] = 6;
            return values;
        })();

        ServiceApiRequest.get_logs = (function() {

            function get_logs(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_logs.prototype.app = "";

            get_logs.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.app != null && Object.hasOwnProperty.call(message, "app"))
                    writer.uint32(10).string(message.app);
                return writer;
            };

            get_logs.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.ServiceApiRequest.get_logs();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.app = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_logs.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.ServiceApiRequest.get_logs)
                    return object;
                let message = new $root.object_msg.ServiceApiRequest.get_logs();
                if (object.app != null)
                    message.app = String(object.app);
                return message;
            };

            get_logs.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.app = "";
                if (message.app != null && message.hasOwnProperty("app"))
                    object.app = message.app;
                return object;
            };

            get_logs.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_logs.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.ServiceApiRequest.get_logs";
            };

            return get_logs;
        })();

        ServiceApiRequest.get_or_create_personal_manager = (function() {

            function get_or_create_personal_manager(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_or_create_personal_manager.prototype.dumb_arg = false;

            get_or_create_personal_manager.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.dumb_arg != null && Object.hasOwnProperty.call(message, "dumb_arg"))
                    writer.uint32(8).bool(message.dumb_arg);
                return writer;
            };

            get_or_create_personal_manager.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.ServiceApiRequest.get_or_create_personal_manager();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.dumb_arg = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_or_create_personal_manager.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.ServiceApiRequest.get_or_create_personal_manager)
                    return object;
                let message = new $root.object_msg.ServiceApiRequest.get_or_create_personal_manager();
                if (object.dumb_arg != null)
                    message.dumb_arg = Boolean(object.dumb_arg);
                return message;
            };

            get_or_create_personal_manager.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.dumb_arg = false;
                if (message.dumb_arg != null && message.hasOwnProperty("dumb_arg"))
                    object.dumb_arg = message.dumb_arg;
                return object;
            };

            get_or_create_personal_manager.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_or_create_personal_manager.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.ServiceApiRequest.get_or_create_personal_manager";
            };

            return get_or_create_personal_manager;
        })();

        ServiceApiRequest.get_address_suggestion = (function() {

            function get_address_suggestion(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_address_suggestion.prototype.input = "";

            get_address_suggestion.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.input != null && Object.hasOwnProperty.call(message, "input"))
                    writer.uint32(10).string(message.input);
                return writer;
            };

            get_address_suggestion.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.ServiceApiRequest.get_address_suggestion();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.input = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_address_suggestion.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.ServiceApiRequest.get_address_suggestion)
                    return object;
                let message = new $root.object_msg.ServiceApiRequest.get_address_suggestion();
                if (object.input != null)
                    message.input = String(object.input);
                return message;
            };

            get_address_suggestion.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.input = "";
                if (message.input != null && message.hasOwnProperty("input"))
                    object.input = message.input;
                return object;
            };

            get_address_suggestion.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_address_suggestion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.ServiceApiRequest.get_address_suggestion";
            };

            return get_address_suggestion;
        })();

        ServiceApiRequest.get_user_profile_info = (function() {

            function get_user_profile_info(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_user_profile_info.prototype.dull_arg = false;

            get_user_profile_info.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.dull_arg != null && Object.hasOwnProperty.call(message, "dull_arg"))
                    writer.uint32(8).bool(message.dull_arg);
                return writer;
            };

            get_user_profile_info.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.ServiceApiRequest.get_user_profile_info();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.dull_arg = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_user_profile_info.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.ServiceApiRequest.get_user_profile_info)
                    return object;
                let message = new $root.object_msg.ServiceApiRequest.get_user_profile_info();
                if (object.dull_arg != null)
                    message.dull_arg = Boolean(object.dull_arg);
                return message;
            };

            get_user_profile_info.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.dull_arg = false;
                if (message.dull_arg != null && message.hasOwnProperty("dull_arg"))
                    object.dull_arg = message.dull_arg;
                return object;
            };

            get_user_profile_info.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_user_profile_info.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.ServiceApiRequest.get_user_profile_info";
            };

            return get_user_profile_info;
        })();

        ServiceApiRequest.get_upload_url = (function() {

            function get_upload_url(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_upload_url.prototype.original_name = "";

            get_upload_url.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.original_name != null && Object.hasOwnProperty.call(message, "original_name"))
                    writer.uint32(10).string(message.original_name);
                return writer;
            };

            get_upload_url.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.ServiceApiRequest.get_upload_url();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.original_name = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_upload_url.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.ServiceApiRequest.get_upload_url)
                    return object;
                let message = new $root.object_msg.ServiceApiRequest.get_upload_url();
                if (object.original_name != null)
                    message.original_name = String(object.original_name);
                return message;
            };

            get_upload_url.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.original_name = "";
                if (message.original_name != null && message.hasOwnProperty("original_name"))
                    object.original_name = message.original_name;
                return object;
            };

            get_upload_url.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_upload_url.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.ServiceApiRequest.get_upload_url";
            };

            return get_upload_url;
        })();

        ServiceApiRequest.get_download_url = (function() {

            function get_download_url(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            get_download_url.prototype.file_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            get_download_url.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.file_id != null && Object.hasOwnProperty.call(message, "file_id"))
                    writer.uint32(8).uint64(message.file_id);
                return writer;
            };

            get_download_url.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.ServiceApiRequest.get_download_url();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.file_id = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            get_download_url.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.ServiceApiRequest.get_download_url)
                    return object;
                let message = new $root.object_msg.ServiceApiRequest.get_download_url();
                if (object.file_id != null)
                    if ($util.Long)
                        (message.file_id = $util.Long.fromValue(object.file_id)).unsigned = true;
                    else if (typeof object.file_id === "string")
                        message.file_id = parseInt(object.file_id, 10);
                    else if (typeof object.file_id === "number")
                        message.file_id = object.file_id;
                    else if (typeof object.file_id === "object")
                        message.file_id = new $util.LongBits(object.file_id.low >>> 0, object.file_id.high >>> 0).toNumber(true);
                return message;
            };

            get_download_url.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.file_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.file_id = options.longs === String ? "0" : 0;
                if (message.file_id != null && message.hasOwnProperty("file_id"))
                    if (typeof message.file_id === "number")
                        object.file_id = options.longs === String ? String(message.file_id) : message.file_id;
                    else
                        object.file_id = options.longs === String ? $util.Long.prototype.toString.call(message.file_id) : options.longs === Number ? new $util.LongBits(message.file_id.low >>> 0, message.file_id.high >>> 0).toNumber(true) : message.file_id;
                return object;
            };

            get_download_url.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            get_download_url.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.ServiceApiRequest.get_download_url";
            };

            return get_download_url;
        })();

        return ServiceApiRequest;
    })();

    object_msg.get_or_create_personal_manager_return_msg = (function() {

        function get_or_create_personal_manager_return_msg(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        get_or_create_personal_manager_return_msg.prototype.name = "";
        get_or_create_personal_manager_return_msg.prototype.phone = "";
        get_or_create_personal_manager_return_msg.prototype.email = "";

        get_or_create_personal_manager_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(10).string(message.name);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(18).string(message.phone);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(26).string(message.email);
            return writer;
        };

        get_or_create_personal_manager_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_or_create_personal_manager_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.phone = reader.string();
                        break;
                    }
                case 3: {
                        message.email = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        get_or_create_personal_manager_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.get_or_create_personal_manager_return_msg)
                return object;
            let message = new $root.object_msg.get_or_create_personal_manager_return_msg();
            if (object.name != null)
                message.name = String(object.name);
            if (object.phone != null)
                message.phone = String(object.phone);
            if (object.email != null)
                message.email = String(object.email);
            return message;
        };

        get_or_create_personal_manager_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.phone = "";
                object.email = "";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            return object;
        };

        get_or_create_personal_manager_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        get_or_create_personal_manager_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.get_or_create_personal_manager_return_msg";
        };

        return get_or_create_personal_manager_return_msg;
    })();

    object_msg.get_address_suggestion_return_msg = (function() {

        function get_address_suggestion_return_msg(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        get_address_suggestion_return_msg.prototype.items = $util.emptyArray;
        get_address_suggestion_return_msg.prototype.request_error = false;

        get_address_suggestion_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.object_msg.get_address_suggestion_return_msg.Address.encode(message.items[i], writer.uint32(10).fork()).ldelim();
            if (message.request_error != null && Object.hasOwnProperty.call(message, "request_error"))
                writer.uint32(16).bool(message.request_error);
            return writer;
        };

        get_address_suggestion_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_address_suggestion_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.object_msg.get_address_suggestion_return_msg.Address.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.request_error = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        get_address_suggestion_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.get_address_suggestion_return_msg)
                return object;
            let message = new $root.object_msg.get_address_suggestion_return_msg();
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".object_msg.get_address_suggestion_return_msg.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".object_msg.get_address_suggestion_return_msg.items: object expected");
                    message.items[i] = $root.object_msg.get_address_suggestion_return_msg.Address.fromObject(object.items[i]);
                }
            }
            if (object.request_error != null)
                message.request_error = Boolean(object.request_error);
            return message;
        };

        get_address_suggestion_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.request_error = false;
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.object_msg.get_address_suggestion_return_msg.Address.toObject(message.items[j], options);
            }
            if (message.request_error != null && message.hasOwnProperty("request_error"))
                object.request_error = message.request_error;
            return object;
        };

        get_address_suggestion_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        get_address_suggestion_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.get_address_suggestion_return_msg";
        };

        get_address_suggestion_return_msg.Address = (function() {

            function Address(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            Address.prototype.address = "";
            Address.prototype.town = "";
            Address.prototype.latitude = 0;
            Address.prototype.longitude = 0;
            Address.prototype.address_quality = 0;

            Address.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(10).string(message.address);
                if (message.town != null && Object.hasOwnProperty.call(message, "town"))
                    writer.uint32(18).string(message.town);
                if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
                    writer.uint32(29).float(message.latitude);
                if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                    writer.uint32(37).float(message.longitude);
                if (message.address_quality != null && Object.hasOwnProperty.call(message, "address_quality"))
                    writer.uint32(40).int32(message.address_quality);
                return writer;
            };

            Address.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_address_suggestion_return_msg.Address();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.address = reader.string();
                            break;
                        }
                    case 2: {
                            message.town = reader.string();
                            break;
                        }
                    case 3: {
                            message.latitude = reader.float();
                            break;
                        }
                    case 4: {
                            message.longitude = reader.float();
                            break;
                        }
                    case 5: {
                            message.address_quality = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            Address.fromObject = function fromObject(object) {
                if (object instanceof $root.object_msg.get_address_suggestion_return_msg.Address)
                    return object;
                let message = new $root.object_msg.get_address_suggestion_return_msg.Address();
                if (object.address != null)
                    message.address = String(object.address);
                if (object.town != null)
                    message.town = String(object.town);
                if (object.latitude != null)
                    message.latitude = Number(object.latitude);
                if (object.longitude != null)
                    message.longitude = Number(object.longitude);
                if (object.address_quality != null)
                    message.address_quality = object.address_quality | 0;
                return message;
            };

            Address.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.address = "";
                    object.town = "";
                    object.latitude = 0;
                    object.longitude = 0;
                    object.address_quality = 0;
                }
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                if (message.town != null && message.hasOwnProperty("town"))
                    object.town = message.town;
                if (message.latitude != null && message.hasOwnProperty("latitude"))
                    object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
                if (message.longitude != null && message.hasOwnProperty("longitude"))
                    object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
                if (message.address_quality != null && message.hasOwnProperty("address_quality"))
                    object.address_quality = message.address_quality;
                return object;
            };

            Address.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            Address.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/object_msg.get_address_suggestion_return_msg.Address";
            };

            return Address;
        })();

        return get_address_suggestion_return_msg;
    })();

    object_msg.get_user_profile_info_return_msg = (function() {

        function get_user_profile_info_return_msg(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        get_user_profile_info_return_msg.prototype.email = "";
        get_user_profile_info_return_msg.prototype.name = "";
        get_user_profile_info_return_msg.prototype.surname = "";
        get_user_profile_info_return_msg.prototype.patronymic = "";
        get_user_profile_info_return_msg.prototype.communication_type = 0;

        get_user_profile_info_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(10).string(message.email);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(18).string(message.name);
            if (message.surname != null && Object.hasOwnProperty.call(message, "surname"))
                writer.uint32(26).string(message.surname);
            if (message.patronymic != null && Object.hasOwnProperty.call(message, "patronymic"))
                writer.uint32(34).string(message.patronymic);
            if (message.communication_type != null && Object.hasOwnProperty.call(message, "communication_type"))
                writer.uint32(40).int32(message.communication_type);
            return writer;
        };

        get_user_profile_info_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_user_profile_info_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.email = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.surname = reader.string();
                        break;
                    }
                case 4: {
                        message.patronymic = reader.string();
                        break;
                    }
                case 5: {
                        message.communication_type = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        get_user_profile_info_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.get_user_profile_info_return_msg)
                return object;
            let message = new $root.object_msg.get_user_profile_info_return_msg();
            if (object.email != null)
                message.email = String(object.email);
            if (object.name != null)
                message.name = String(object.name);
            if (object.surname != null)
                message.surname = String(object.surname);
            if (object.patronymic != null)
                message.patronymic = String(object.patronymic);
            if (object.communication_type != null)
                message.communication_type = object.communication_type | 0;
            return message;
        };

        get_user_profile_info_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.email = "";
                object.name = "";
                object.surname = "";
                object.patronymic = "";
                object.communication_type = 0;
            }
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.surname != null && message.hasOwnProperty("surname"))
                object.surname = message.surname;
            if (message.patronymic != null && message.hasOwnProperty("patronymic"))
                object.patronymic = message.patronymic;
            if (message.communication_type != null && message.hasOwnProperty("communication_type"))
                object.communication_type = message.communication_type;
            return object;
        };

        get_user_profile_info_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        get_user_profile_info_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.get_user_profile_info_return_msg";
        };

        return get_user_profile_info_return_msg;
    })();

    object_msg.get_upload_url_return_msg = (function() {

        function get_upload_url_return_msg(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        get_upload_url_return_msg.prototype.url = "";
        get_upload_url_return_msg.prototype.file_id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        get_upload_url_return_msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(10).string(message.url);
            if (message.file_id != null && Object.hasOwnProperty.call(message, "file_id"))
                writer.uint32(16).uint64(message.file_id);
            return writer;
        };

        get_upload_url_return_msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.object_msg.get_upload_url_return_msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.url = reader.string();
                        break;
                    }
                case 2: {
                        message.file_id = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        get_upload_url_return_msg.fromObject = function fromObject(object) {
            if (object instanceof $root.object_msg.get_upload_url_return_msg)
                return object;
            let message = new $root.object_msg.get_upload_url_return_msg();
            if (object.url != null)
                message.url = String(object.url);
            if (object.file_id != null)
                if ($util.Long)
                    (message.file_id = $util.Long.fromValue(object.file_id)).unsigned = true;
                else if (typeof object.file_id === "string")
                    message.file_id = parseInt(object.file_id, 10);
                else if (typeof object.file_id === "number")
                    message.file_id = object.file_id;
                else if (typeof object.file_id === "object")
                    message.file_id = new $util.LongBits(object.file_id.low >>> 0, object.file_id.high >>> 0).toNumber(true);
            return message;
        };

        get_upload_url_return_msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.url = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.file_id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.file_id = options.longs === String ? "0" : 0;
            }
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.file_id != null && message.hasOwnProperty("file_id"))
                if (typeof message.file_id === "number")
                    object.file_id = options.longs === String ? String(message.file_id) : message.file_id;
                else
                    object.file_id = options.longs === String ? $util.Long.prototype.toString.call(message.file_id) : options.longs === Number ? new $util.LongBits(message.file_id.low >>> 0, message.file_id.high >>> 0).toNumber(true) : message.file_id;
            return object;
        };

        get_upload_url_return_msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        get_upload_url_return_msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/object_msg.get_upload_url_return_msg";
        };

        return get_upload_url_return_msg;
    })();

    return object_msg;
})();

export { $root as default };
