import { AbstractModel_Remote, AbstractObject_Remote, onRequestChanged, modelReloadFunctionsList } from '@/rpc_client/apiAbstract'
import { App } from 'vue'
import { WebSocketRpc } from '@/rpc_client/WebSocketRpc'
import { client_driven_msg, object_update_msg } from '@/rpc_client/messages'
import { object_msg } from './objectMsg'
import Long from 'long'

export const protocolVersion = 1737140896237

export enum ExposedObjectId {
  OrdersApi_Id,
  PlacesApi_Id,
  OrganizationsApi_Id,
  ServiceApi_Id,
  OrganizationOwnersModel_Id,
  OrganizationsRuModel_Id,
  OrganizationsRuInfoModel_Id,
  PlacesModel_Id,
  PlaceActionsModel_Id,
  PlacePhonesModel_Id,
  PlaceEmailsModel_Id,
  PlaceMachinesModel_Id,
  PlaceLabelsModel_Id,
  LaserCuttingPricesModel_Id,
  LaserEngravingPricesModel_Id,
  OrdersModel_Id,
  OrderStatusesModel_Id,
  OrderCommentsModel_Id,
  OrderCommentFilesModel_Id,
  OrderStagesModel_Id,
  OrderStageStatusesModel_Id,
  OrderStageDeliveryProfilesModel_Id,
  OrderStageCommentsModel_Id,
  OrderStageCommentFilesModel_Id,
  UploadedFilesModel_Id,
  UserDeliveryProfilesModel_Id,
  LaserCuttingStagesModel_Id,
  LaserEngravingStagesModel_Id,
  BanksRuModel_Id,
  ActionTypesModel_Id,
  ActionMaterialsModel_Id,
  MaterialTypesModel_Id,
  MachineTypesModel_Id,
  LabelTypesModel_Id,
  CitiesRuModel_Id,
  DepartmentsModel_Id,
  DepartmentActionsModel_Id,
  LaserCuttingMaterialThicknessesModel_Id,
  LocalLaserCuttingPricesModel_Id,
  LocalLaserEngravingPricesModel_Id,
  OrderServicesModel_Id,
  LogsModel_Id
}

// API objects
declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $OrdersApi: OrdersApi_Remote,
    $PlacesApi: PlacesApi_Remote,
    $OrganizationsApi: OrganizationsApi_Remote,
    $ServiceApi: ServiceApi_Remote
  }
}

// Allowed roles:
// User, Admin
export class OrdersApi_Remote extends AbstractObject_Remote {
  objectId = ExposedObjectId.OrdersApi_Id

  // Allowed roles: Admin, User
  async updateOrderByUser (orders: Uint8Array, orderServices: Uint8Array, orderComments: Uint8Array, orderCommentFiles: Uint8Array, orderStages: Uint8Array, orderStageComments: Uint8Array, orderStageCommentFiles: Uint8Array, laserCuttingStage: Uint8Array, laserEngravingStage: Uint8Array, orderId: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.updateOrderByUser,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ update_order_by_user_args: { orders: orders, order_services: orderServices, order_comments: orderComments, order_comment_files: orderCommentFiles, order_stages: orderStages, order_stage_comments: orderStageComments, order_stage_comment_files: orderStageCommentFiles, laser_cutting_stage: laserCuttingStage, laser_engraving_stage: laserEngravingStage, order_id: orderId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, User
  async updateDeliveryProfilesByUser (profiles: Uint8Array): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.updateDeliveryProfilesByUser,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ update_delivery_profiles_by_user_args: { profiles: profiles } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, User
  async reloadDeliveryProfiles (autoUpdate: boolean, unload: boolean): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.reloadDeliveryProfiles,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ reload_delivery_profiles_args: { auto_update: autoUpdate, unload: unload } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, User
  async selectOrder (orderId: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.selectOrder,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ select_order_args: { order_id: orderId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, User
  async selectOrderStage (orderId: Long, stageNumber: number): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.selectOrderStage,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ select_order_stage_args: { order_id: orderId, stage_number: stageNumber } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, User
  async searchOrders (dullarg: boolean): Promise<object_msg.search_orders_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.searchOrders,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ search_orders_args: { dullarg: dullarg } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.search_orders_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, User
  async getStageActionApproximatePrice (deliveryProfileId: Long, stageActionData: Uint8Array): Promise<object_msg.get_stage_action_approximate_price_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.getStageActionApproximatePrice,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ get_stage_action_approximate_price_args: { delivery_profile_id: deliveryProfileId, stage_action_data: stageActionData } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.get_stage_action_approximate_price_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, User
  async searchIncomingOrders (dullarg: boolean): Promise<object_msg.search_incoming_orders_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.searchIncomingOrders,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ search_incoming_orders_args: { dullarg: dullarg } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.search_incoming_orders_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, User
  async getLastOrganizationUsedForPayment (dullarg: boolean): Promise<Long> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrdersApiRequest.Method.getLastOrganizationUsedForPayment,
      object: this.objectId,
      custom_encoded: object_msg.OrdersApiRequest.encode(new object_msg.OrdersApiRequest({ get_last_organization_used_for_payment_args: { dullarg: dullarg } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.u!;
  }
}

// Allowed roles:
// User, Admin
export class PlacesApi_Remote extends AbstractObject_Remote {
  objectId = ExposedObjectId.PlacesApi_Id

  // Allowed roles: Admin, User
  async updatePlaceByUser (place: Uint8Array, placeActions: Uint8Array, laserCuttingPrices: Uint8Array, laserEngravingPrices: Uint8Array, placePhones: Uint8Array, placeEmails: Uint8Array, placeMachines: Uint8Array, placeLabels: Uint8Array, placeId: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.updatePlaceByUser,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ update_place_by_user_args: { place: place, place_actions: placeActions, laser_cutting_prices: laserCuttingPrices, laser_engraving_prices: laserEngravingPrices, place_phones: placePhones, place_emails: placeEmails, place_machines: placeMachines, place_labels: placeLabels, place_id: placeId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, User
  async searchUserPlaces (dullArg: boolean): Promise<object_msg.search_user_places_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.searchUserPlaces,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ search_user_places_args: { dull_arg: dullArg } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.search_user_places_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, User
  async selectPlace (placeId: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.selectPlace,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ select_place_args: { place_id: placeId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, User
  async reloadLaserCuttingMaterialThicknesses (material: string, autoUpdate: boolean, unloadModel: boolean): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.reloadLaserCuttingMaterialThicknesses,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ reload_laser_cutting_material_thicknesses_args: { material: material, auto_update: autoUpdate, unload_model: unloadModel } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, User
  async reloadActionMaterials (action: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.reloadActionMaterials,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ reload_action_materials_args: { action: action } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, User
  async doesUserHavePlace (name: string, address: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.PlacesApiRequest.Method.doesUserHavePlace,
      object: this.objectId,
      custom_encoded: object_msg.PlacesApiRequest.encode(new object_msg.PlacesApiRequest({ does_user_have_place_args: { name: name, address: address } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }
}

// Allowed roles:
// User, Admin
export class OrganizationsApi_Remote extends AbstractObject_Remote {
  objectId = ExposedObjectId.OrganizationsApi_Id

  // Allowed roles: Admin, User
  async updateOrganizationByUser (organizations: Uint8Array, orgIdForArchive: Long): Promise<object_msg.update_organization_by_user_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrganizationsApiRequest.Method.updateOrganizationByUser,
      object: this.objectId,
      custom_encoded: object_msg.OrganizationsApiRequest.encode(new object_msg.OrganizationsApiRequest({ update_organization_by_user_args: { organizations: organizations, org_id_for_archive: orgIdForArchive } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.update_organization_by_user_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, User
  async getOrAddBank (bic: string): Promise<object_msg.get_or_add_bank_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrganizationsApiRequest.Method.getOrAddBank,
      object: this.objectId,
      custom_encoded: object_msg.OrganizationsApiRequest.encode(new object_msg.OrganizationsApiRequest({ get_or_add_bank_args: { bic: bic } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.get_or_add_bank_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, User
  async getOrganizationSuggestion (input: string): Promise<object_msg.get_organization_suggestion_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrganizationsApiRequest.Method.getOrganizationSuggestion,
      object: this.objectId,
      custom_encoded: object_msg.OrganizationsApiRequest.encode(new object_msg.OrganizationsApiRequest({ get_organization_suggestion_args: { input: input } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.get_organization_suggestion_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, User
  async searchOrganization (organizationNameInn: string, organizationId: Long): Promise<object_msg.search_organization_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrganizationsApiRequest.Method.searchOrganization,
      object: this.objectId,
      custom_encoded: object_msg.OrganizationsApiRequest.encode(new object_msg.OrganizationsApiRequest({ search_organization_args: { organization_name_inn: organizationNameInn, organization_id: organizationId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.search_organization_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, User
  async selectOrganization (organizationId: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrganizationsApiRequest.Method.selectOrganization,
      object: this.objectId,
      custom_encoded: object_msg.OrganizationsApiRequest.encode(new object_msg.OrganizationsApiRequest({ select_organization_args: { organization_id: organizationId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, User
  async reloadUserOrganizationsInfo (dullArg: boolean): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrganizationsApiRequest.Method.reloadUserOrganizationsInfo,
      object: this.objectId,
      custom_encoded: object_msg.OrganizationsApiRequest.encode(new object_msg.OrganizationsApiRequest({ reload_user_organizations_info_args: { dull_arg: dullArg } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, User
  async searchUserOrganizations (dullArg: boolean): Promise<object_msg.search_user_organizations_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrganizationsApiRequest.Method.searchUserOrganizations,
      object: this.objectId,
      custom_encoded: object_msg.OrganizationsApiRequest.encode(new object_msg.OrganizationsApiRequest({ search_user_organizations_args: { dull_arg: dullArg } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.search_user_organizations_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, User
  async archiveUserOrganizaton (organizationId: Long): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrganizationsApiRequest.Method.archiveUserOrganizaton,
      object: this.objectId,
      custom_encoded: object_msg.OrganizationsApiRequest.encode(new object_msg.OrganizationsApiRequest({ archive_user_organizaton_args: { organization_id: organizationId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }

  // Allowed roles: Admin, User
  async doesUserHaveOrganization (inn: string, kpp: string): Promise<boolean> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.OrganizationsApiRequest.Method.doesUserHaveOrganization,
      object: this.objectId,
      custom_encoded: object_msg.OrganizationsApiRequest.encode(new object_msg.OrganizationsApiRequest({ does_user_have_organization_args: { inn: inn, kpp: kpp } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.b!;
  }
}

// Allowed roles:
// User, Admin
export class ServiceApi_Remote extends AbstractObject_Remote {
  objectId = ExposedObjectId.ServiceApi_Id

  // Allowed roles: Admin
  getLogs (app: string): void {
    const encodedMessage = object_msg.ServiceApiRequest.encode(new object_msg.ServiceApiRequest({ get_logs_args: { app: app } })).finish();
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.ServiceApiRequest.Method.getLogs,
      object: this.objectId,
      custom_encoded: encodedMessage
    });
    this.socket.sendPacked({ data_encoded: client_driven_msg.ClientRequest.encode(request).finish() });
    modelReloadFunctionsList.insert(request); // this method could reload client proxy model -> recording
  }

  // Allowed roles: User
  async getOrCreatePersonalManager (dumbArg: boolean): Promise<object_msg.get_or_create_personal_manager_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.ServiceApiRequest.Method.getOrCreatePersonalManager,
      object: this.objectId,
      custom_encoded: object_msg.ServiceApiRequest.encode(new object_msg.ServiceApiRequest({ get_or_create_personal_manager_args: { dumb_arg: dumbArg } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.get_or_create_personal_manager_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, User
  async getAddressSuggestion (input: string): Promise<object_msg.get_address_suggestion_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.ServiceApiRequest.Method.getAddressSuggestion,
      object: this.objectId,
      custom_encoded: object_msg.ServiceApiRequest.encode(new object_msg.ServiceApiRequest({ get_address_suggestion_args: { input: input } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.get_address_suggestion_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, User
  async getUserProfileInfo (dullArg: boolean): Promise<object_msg.get_user_profile_info_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.ServiceApiRequest.Method.getUserProfileInfo,
      object: this.objectId,
      custom_encoded: object_msg.ServiceApiRequest.encode(new object_msg.ServiceApiRequest({ get_user_profile_info_args: { dull_arg: dullArg } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.get_user_profile_info_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, User
  async getUploadUrl (originalName: string): Promise<object_msg.get_upload_url_return_msg> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.ServiceApiRequest.Method.getUploadUrl,
      object: this.objectId,
      custom_encoded: object_msg.ServiceApiRequest.encode(new object_msg.ServiceApiRequest({ get_upload_url_args: { original_name: originalName } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return object_msg.get_upload_url_return_msg.decode(decodedResult.byt!);
  }

  // Allowed roles: Admin, User
  async getDownloadUrl (fileId: Long): Promise<string> {
    const request = new client_driven_msg.ClientRequest({ 
      method: object_msg.ServiceApiRequest.Method.getDownloadUrl,
      object: this.objectId,
      custom_encoded: object_msg.ServiceApiRequest.encode(new object_msg.ServiceApiRequest({ get_download_url_args: { file_id: fileId } })).finish()
    });
    onRequestChanged.dispatch({ pending: true, error: false });
    const result = await this.socket.sendRequest(client_driven_msg.ClientRequest.encode(request).finish(), { timeout: 10000 }).catch((error) => { onRequestChanged.dispatch({ pending: false, error: true }); throw error; });
    onRequestChanged.dispatch({ pending: false, error: false });
    const decodedResult = object_update_msg.EntryField.decode(result.data_encoded);
    return decodedResult.s!;
  }
}

export function createGlobalPropsAndProvideApi (app: App, socket: WebSocketRpc): void {
  //
  const OrdersApi = new OrdersApi_Remote(socket);
  app.config.globalProperties.$OrdersApi = OrdersApi; app.provide('OrdersApi', OrdersApi);
  const PlacesApi = new PlacesApi_Remote(socket);
  app.config.globalProperties.$PlacesApi = PlacesApi; app.provide('PlacesApi', PlacesApi);
  const OrganizationsApi = new OrganizationsApi_Remote(socket);
  app.config.globalProperties.$OrganizationsApi = OrganizationsApi; app.provide('OrganizationsApi', OrganizationsApi);
  const ServiceApi = new ServiceApi_Remote(socket);
  app.config.globalProperties.$ServiceApi = ServiceApi; app.provide('ServiceApi', ServiceApi);
}

// API models

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class OrganizationOwnersModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrganizationOwnersModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class OrganizationsRuModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrganizationsRuModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class OrganizationsRuInfoModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrganizationsRuInfoModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class PlacesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.PlacesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class PlaceActionsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.PlaceActionsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class PlacePhonesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.PlacePhonesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class PlaceEmailsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.PlaceEmailsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class PlaceMachinesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.PlaceMachinesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class PlaceLabelsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.PlaceLabelsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class LaserCuttingPricesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.LaserCuttingPricesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class LaserEngravingPricesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.LaserEngravingPricesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class OrdersModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrdersModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class OrderStatusesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrderStatusesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class OrderCommentsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrderCommentsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class OrderCommentFilesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrderCommentFilesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class OrderStagesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrderStagesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class OrderStageStatusesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrderStageStatusesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class OrderStageDeliveryProfilesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrderStageDeliveryProfilesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class OrderStageCommentsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrderStageCommentsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class OrderStageCommentFilesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrderStageCommentFilesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class UploadedFilesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.UploadedFilesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class UserDeliveryProfilesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.UserDeliveryProfilesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class LaserCuttingStagesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.LaserCuttingStagesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class LaserEngravingStagesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.LaserEngravingStagesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class BanksRuModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.BanksRuModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class ActionTypesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.ActionTypesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class ActionMaterialsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.ActionMaterialsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class MaterialTypesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.MaterialTypesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class MachineTypesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.MachineTypesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class LabelTypesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.LabelTypesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class CitiesRuModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.CitiesRuModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class DepartmentsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.DepartmentsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class DepartmentActionsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.DepartmentActionsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class LaserCuttingMaterialThicknessesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.LaserCuttingMaterialThicknessesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class LocalLaserCuttingPricesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.LocalLaserCuttingPricesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class LocalLaserEngravingPricesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.LocalLaserEngravingPricesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// User, Admin
// Row restriction based on role and user name:
// 
export abstract class OrderServicesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrderServicesModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}

// Allowed roles:
// Admin
// Row restriction based on role and user name:
// 
export abstract class LogsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.LogsModel_Id

  abstract getState (): any
  abstract getInfoState (): any
  abstract getMutations (): any

  abstract addRow (row: Uint8Array): void
  abstract updateRow (payload: object_update_msg.IUpdate): void
  abstract removeRow (payload: object_update_msg.IRemove): void
  abstract setValid (valid: boolean): void
  abstract resetAll (modelData: Uint8Array): void
  abstract clearAll (): void
}
